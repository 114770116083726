import React, { useEffect, useState } from "react";
import styles from "./steps.module.scss";
import insiderIcon from "../../img/insiderIconColor.svg";
import checkRounded from "../../img/checkRounded.svg";

import { CaretDownFilled } from "@ant-design/icons";
import { DatePicker, Select } from "antd";

import phoneCode from "./PhoneCode.json";

const Step5 = ({
  setStep,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  countryCode,
  setCountryCode,
  phoneNo,
  setPhoneNo,
  birthDay,
  setBirthDay,
  birthMonth,
  setBirthMonth,
  birthYear,
  setBirthYear,
}) => {
  const handleNext = () => {
    console.log(
      firstName,
      lastName,
      countryCode,
      phoneNo,
      birthDay,
      birthMonth,
      birthYear
    );
    if (
      firstName &&
      lastName &&
      countryCode &&
      phoneNo &&
      birthDay &&
      birthMonth &&
      birthYear
    ) {
      setStep(7);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img src={insiderIcon} alt="" />
          <div className={styles.formContainer}>
            <div>
              <h1 className={styles.title}>Enter Personal Details</h1>
              <h4 className={styles.subTitle}>
                Tell us a little bit more about you
              </h4>
            </div>
            <div
              className={styles.form}
              style={{
                overflowY: "scroll",
                height: "64vh",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "35px",
                }}
              >
                <div>
                  <div className={styles.lable}>First Name</div>
                  <div className={styles.inputWrapper}>
                    <input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      type="text"
                      className={styles.inputStyle}
                      placeholder="Joe"
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.lable}>Last Name</div>
                  <div className={styles.inputWrapper}>
                    <input
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      type="text"
                      className={styles.inputStyle}
                      placeholder="Snow"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  margin: "6% 0px",
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1fr",
                  gap: "15px",
                }}
              >
                <div>
                  <div className={styles.lable}>Phone Number</div>
                  <div className={`${styles.inputWrapper} ${styles.phoneCode}`}>
                    {/* <div>IN</div> */}
                    {/* <div>+91 </div>
                    <CaretDownFilled /> */}
                    <Select
                      bordered={false}
                      style={{
                        width: "100%",
                        // marginLeft: "10px",
                        // border: "none",
                        // fontSize: "2vh",
                        // fontWeight: 700,
                        // outline: "none",
                      }}
                      showSearch
                      placeholder="+91 (IN)"
                      optionFilterProp="children"
                      // onChange={onChange}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={phoneCode.map((item) => ({
                        label: `${item.dial_code} (${item.code})`,
                        value: item.dial_code,
                      }))}
                      onChange={(value) => {
                        console.log(value, "kwjdgkwjd");
                        setCountryCode(value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.lable}>&nbsp;</div>
                  <div className={styles.inputWrapper}>
                    <input
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      type="number"
                      className={styles.inputStyle}
                      placeholder="00000 00000"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.lable}>Birthday</div>
              <div
                style={{
                  // margin: "6% 0px",
                  display: "grid",
                  gridTemplateColumns: "0.5fr 0.5fr 0.5fr",
                  gap: "15px",
                }}
              >
                <div className={`${styles.inputWrapper} ${styles.phoneCode}`}>
                  <Select
                    bordered={false}
                    style={{
                      width: "100%",
                      // marginLeft: "10px",
                      border: "none",
                      fontSize: "2vh",
                      fontWeight: 700,
                      outline: "none",
                    }}
                    showSearch
                    placeholder="Day"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={Array(31)
                      .fill("")
                      .map((item, index) => ({
                        label: `${index + 1}`,
                        value: index + 1,
                      }))}
                    onChange={(value) => {
                      console.log(value, "kwjdgkwjd");
                      setBirthDay(value);
                    }}
                  />
                </div>

                <div className={`${styles.inputWrapper} ${styles.phoneCode}`}>
                  <DatePicker
                    // disabledDate={disabledYear}
                    picker="month"
                    format={"MMM"}
                    bordered={false}
                    style={{
                      width: "100%", // marginLeft: "10px",
                      border: "none",
                      fontSize: "2vh",
                      fontWeight: 700,
                      outline: "none",
                    }}
                    onChange={(date, dateString) => {
                      console.log(dateString, "kwjdgkwjd");
                      setBirthMonth(dateString);
                    }}
                  />
                </div>

                <div className={`${styles.inputWrapper} ${styles.phoneCode}`}>
                  <DatePicker
                    picker="year"
                    bordered={false}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      console.log(dateString, "kwjdgkwjd");
                      setBirthYear(dateString);
                    }}
                  />
                </div>
              </div>
              <button
                className={styles.submitButton}
                style={{
                  marginTop: "7vh",
                  opacity:
                    firstName &&
                    lastName &&
                    countryCode &&
                    phoneNo &&
                    birthDay &&
                    birthMonth &&
                    birthYear
                      ? 1
                      : 0.5,
                }}
                onClick={handleNext}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.finishedPage}>
            <div>Who Invited You</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Country Of Residence</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Login Credentials</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Verify Email</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.currentPage}>Enter Personal Details</div>
          <div className={styles.otherPage}>Verify Phone Number</div>
          <div className={styles.otherPage}>Enter Address</div>
          <div className={styles.otherPage}>Type Of Investor</div>
          <div className={styles.otherPage}>Type of Insider</div>
        </div>
      </div>
    </>
  );
};

export default Step5;
