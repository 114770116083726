import React, { useEffect } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";

// import "antd/dist/antd.css";
import "antd/dist/reset.css";
import "./css/style.scss";
import "./App.scss";

import Middleware from "./pages/Middleware";
import Login from "./pages/Login";

import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import { QueryClient, QueryClientProvider } from "react-query";

const client = new ApolloClient({
  uri: "https://connectionsapi.globalxchange.io/graphql",
  // uri: "http://localhost:3997/graphql",
  request: (operation) => {
    // console.log(
    //   "mydata",
    //   localStorage.getItem("token"),
    //   localStorage.getItem("user_account")
    // );
    // const token = localStorage.getItem("access_token");
    operation.setContext({
      headers: {
        token: localStorage.getItem("token")
          ? localStorage.getItem("token")
          : "",
        email: localStorage.getItem("user_account"),
      },
    });
  },
});

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  });
  return (
    <>
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter basename="/">
            <Middleware />
          </BrowserRouter>
        </QueryClientProvider>
      </ApolloProvider>
    </>
  );
}

export default App;
