import {
  Avatar,
  Button,
  Col,
  Divider,
  Tabs,
  message,
  Input,
  Spin,
  Row,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useSubscription,
} from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import Axios from "axios";
import {
  Link,
  useNavigate,
  useLocation,
  Switch,
  Route,
  Outlet,
} from "react-router-dom";
import Streams from "../components/profileSections/Streams";
import Creator from "../components/profileTabs/Creator";
import Collector from "../components/profileTabs/Collector";

import AWS from "aws-sdk";
import CryptoJS from "crypto-js";

import { customAlphabet } from "nanoid";
import { MyContext } from "../Context";
import { CameraOutlined } from "@material-ui/icons";
import MenuSidebar from "../components/MenuSidebar";
import LoggedOutMenuSidebar from "../components/LoggedOutMenuSidebar";

const Profile = () => {
  const {
    editable,
    userInfo,
    coverImage,
    setCoverImage,
    coverImageS3url,
    setCoverImageS3url,
    loading,
    setLoading,
    uname,
    setUname,
    ubio,
    setUbio,
    profileImage,
    setProfileImage,
    profileImageS3url,
    setProfileImageS3url,
    prof,
    udob,
    setUdob,
    userEmail,
    setUserEmail,
    userData,
    setUserData,
    userPostData,
    setUserPostData,
    tokenValid,
    loadingProfile,
  } = useContext(MyContext);
  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedProfileMenu, setSelectedProfileMenu] = useState("posts");
  const [selectedProfileTab, setSelectedProfileTab] = useState("Creator");

  const [sidebarTabName, setSidebarTabName] = useState("");

  const [selectedUserId, setSelectedUserId] = useState("");
  const [readMore, setReadMore] = useState(false);

  const [foundUname, setFoundUname] = useState("");

  const nanoid = customAlphabet("23456789abcdefghijklmnopqrstuvwxyz", 15);
  const S3_BUCKET = "connections-app-dev";

  useEffect(() => {
    // if (selectedProfileTab === "Creator") {
    //   navigate(`${localStorage.getItem("actual_username")}/creator`);
    // } else if (selectedProfileTab === "Collector") {
    //   navigate(`/collector`);
    // }
    setFoundUname(window.location.pathname.replace(/^\/([^\/]*).*$/, "$1"));
    console.log(
      window.location.pathname.replace(/^\/([^\/]*).*$/, "$1"),
      "kjewbdkwe"
    );

    setSelectedProfileMenu(location.pathname);
  }, []);

  // useEffect(() => {
  //   if (!userData.name) {
  //     navigate("/login");
  //   }
  // }, [userData]);

  useEffect(() => {
    if (window.location.href.split("/").pop() === "posts") {
      setSelectedProfileMenu("posts");
    } else if (window.location.href.split("/").pop() === "streams") {
      setSelectedProfileMenu("streams");
    } else if (window.location.href.split("/").pop() === "gallery") {
      setSelectedProfileMenu("gallery");
    } else if (window.location.href.split("/").pop() === "memories") {
      setSelectedProfileMenu("memories");
    } else if (window.location.href.split("/").pop() === "discussions") {
      setSelectedProfileMenu("discussions");
    } else if (window.location.href.split("/").pop() === "interactions") {
      setSelectedProfileMenu("interactions");
    } else {
      setSelectedProfileMenu("posts");
    }
  }, [window.location.href.split("/").pop()]);

  const conditionalBio = () => {
    if (readMore) {
      if (userData) {
        if (userData.bio) {
          return userData.bio;
        }
      }
    } else {
      if (userData) {
        if (userData.bio !== undefined) {
          return <span>{userData.bio.substring(0, 150)}</span>;
        }
      }
    }
  };

  const conditionalReadMoreButton = () => {
    if (userData) {
      if (userData.bio !== undefined) {
        if (userData.bio.substring(0, 150).length === 150) {
          if (readMore) {
            return "... read less";
          } else {
            return "... read more";
          }
        } else {
        }
      }
    }
  };

  // useEffect(() => {
  //   Axios.post(
  //     `https://pulseapi.globalxchange.io/get_data`,
  //     {
  //       username: window.location.href.split("/").pop(),
  //     },
  //     {
  //       headers: {
  //         token: localStorage.getItem("token"),
  //         email: localStorage.getItem("user_account"),
  //       },
  //     }
  //   ).then((res) => {
  //     console.log("hdjwhedwemail", res.data);
  //     // var bytes = CryptoJS.Rabbit.decrypt(res.data, "apple");

  //     // var originalText = bytes.toString(CryptoJS.enc.Utf8);

  //     setUserEmail(res.data);
  //   });
  // }, [window.location.href.split("/").pop()]);

  // var userInfo = useQuery(USER_INFO, {
  //   variables: {
  //     email: userEmail ? userEmail : localStorage.getItem("user_profile_email"),
  //   },
  // });

  const myBucket = new AWS.S3({
    params: { Bucket: "connections-app-dev" },
    region: "ap-south-1",
  });

  return (
    <Row>
      <LoggedOutMenuSidebar />

      <Col
        span={14}
        style={{
          borderRight: "0.5px solid #EDEDED",
          overflowY: "scroll",
          height: "100vh",
        }}
      >
        {loadingProfile !== null && loadingProfile === false ? (
          <>
            <div
              style={{
                backgroundImage: `url(${
                  userData.cover_image ? userData.cover_image : coverImage
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "223px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: editable ? 0.6 : 1,
              }}
            >
              {editable ? (
                <label for="image">
                  <input
                    accept="image/*"
                    // value={coverImage}
                    // onChange={e =>
                    //   setCoverImage(URL.createObjectURL(e.target.files[0]))
                    // }
                    // onChange={onCoverImageChange}
                    type="file"
                    name="image"
                    id="image"
                    style={{ display: "none" }}
                  />
                  <Avatar
                    style={{
                      backgroundColor: "white",
                      zIndex: "0",
                      color: "black",
                      margin: "20px",
                      opacity: 1,
                      cursor: "pointer",
                      marginTop: "-30px",
                    }}
                    icon={<CameraOutlined />}
                    size={80}
                  />
                </label>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                borderRadius: "50%",
                borderColor: "gray",
                marginTop: "-65px",
                paddingLeft: "41px",
              }}
            >
              <img
                style={{
                  borderRadius: "50%",
                  width: "118px",
                  height: "118px",
                }}
                src={userData.profile_image ? userData.profile_image : prof}
                alt=""
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "41px",
                }}
              >
                <div>
                  <div
                    style={{
                      paddingTop: "26px",
                      fontWeight: "800",
                      color: "#5F6163",
                      fontSize: "24px",
                    }}
                  >
                    {userData ? userData.name : ""}
                    {/* {userInfo.data ? userInfo.data.get_user.name : ""} */}
                  </div>
                  <div className="profile-text ">
                    @{userData ? userData.username : ""}
                  </div>
                </div>
                <div style={{ textAlign: "right" }}>
                  <div
                    style={{
                      paddingTop: "26px",
                      fontWeight: "800",
                      color: "#5F6163",
                      fontSize: "24px",
                    }}
                  >
                    {userData
                      ? userData.user_type_id === "a65tgc27u6dr"
                        ? "Regular"
                        : "Influencer"
                      : ""}
                  </div>
                  <div className="profile-text ">Last Seen 1HR Ago</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "41px",
                }}
              >
                <div>
                  <div
                    style={{
                      paddingTop: "26px",
                      fontWeight: "800",
                      color: "#5F6163",
                      fontSize: "15px",
                    }}
                  >
                    Bio
                  </div>
                  <div
                    className="profile-text"
                    style={{ paddingRight: "150px" }}
                  >
                    <span>
                      {conditionalBio()}
                      <span
                        onClick={(e) => setReadMore(!readMore)}
                        style={{ color: "#009EFC", cursor: "pointer" }}
                      >
                        {conditionalReadMoreButton()}
                      </span>
                    </span>
                    {/* <span>
                      {readMore &&
                      userData.bio !== null &&
                      userData.bio !== undefined
                        ? userData.bio
                        : userData.bio !== null && userData.bio !== undefined
                        ? userData.bio.substring(0, 150)
                        : ""}
                      ...&nbsp;
                      <span
                        onClick={(e) => setReadMore(!readMore)}
                        style={{ color: "#009EFC", cursor: "pointer" }}
                      >
                        {readMore ? "read less" : "read more"}
                      </span>
                    </span> */}
                  </div>
                </div>
                {userData.city ? (
                  <div style={{ textAlign: "right" }}>
                    <div className="profile-text">
                      {userData ? (
                        userData.city ? (
                          <div style={{ textAlign: "right" }}>
                            <div
                              style={{
                                paddingTop: "26px",
                                fontWeight: "800",
                                color: "#5F6163",
                                fontSize: "15px",
                              }}
                            >
                              City
                            </div>
                            <div className="profile-text">
                              {userData.city ? userData.city : ""}
                              {/* {  userData.city ? (
                                <>
                                  <span>
                                    {userInfo.data.get_user.city
                                      ? userInfo.data.get_user.city
                                      : ""}
                                  </span>
                                </>:""
                          
                          } */}
                            </div>
                            <div className="profile-text"></div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              style={{
                borderTop: "0.5px solid #EDEDED",
                borderBottom: "0.5px solid #EDEDED",
                display: "flex",
                justifyContent: "space-between",
                padding: "20px 41px",
                marginTop: "46px",
              }}
            >
              <div
                className={
                  selectedProfileMenu === "posts"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                // onClick={(e) => setSelectedProfileMenu("Posts")}
                onClick={(e) => {
                  setSelectedProfileMenu("posts");
                  navigate(`posts`);
                }}
              >
                Posts
              </div>

              <div
                className={
                  selectedProfileMenu === "streams"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                onClick={(e) => {
                  setSelectedProfileMenu("streams");
                  navigate(`streams`);
                }}
              >
                Streams
              </div>

              <div
                className={
                  selectedProfileMenu === "gallery"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                onClick={(e) => {
                  setSelectedProfileMenu("gallery");
                  navigate(`gallery`);
                }}
              >
                Gallery
              </div>
              <div
                className={
                  selectedProfileMenu === "memories"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                onClick={(e) => {
                  setSelectedProfileMenu("memories");
                  navigate(`memories`);
                }}
              >
                Memories
              </div>
              <div
                className={
                  selectedProfileMenu === "discussions"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                onClick={(e) => {
                  setSelectedProfileMenu("discussions");
                  navigate(`discussions`);
                }}
              >
                Discussions
              </div>
              <div
                className={
                  selectedProfileMenu === "interactions"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                onClick={(e) => {
                  setSelectedProfileMenu("interactions");
                  navigate(`interactions`);
                }}
              >
                Interactions
              </div>
            </div>
            <Outlet />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin />
          </div>
        )}

        {/* <div className="links">
          <Link to={`${location.pathname}`} className="link">
            Profile
          </Link>
          <Link to={`${location.pathname}/comments`} className="link">
            Comments
          </Link>
          <Link to={`${location.pathname}/contact`} className="link">
            Contact
          </Link>
        </div>
        <div className="tabs">
          <Switch>
            <Route path={`${location.pathname}`} exact component={Profile} />
            <Route path={`${location.pathname}/comments`} component={Posts} />
            <Route path={`${location.pathname}/contact`} component={Streams} />
          </Switch>
        </div> */}
        {/* <div
          className="profile-text"
          style={{ padding: "20px 41px", marginTop: "46px" }}
        >
          This is {selectedProfileMenu}
        </div> */}
      </Col>
      <Col span={6} style={{ padding: "24px 0px" }} className="ant-tab-new">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 37px",
          }}
        >
          <div
            style={{ paddingBottom: "20px" }}
            className={
              selectedProfileTab === "Creator"
                ? "selected-profile-menu"
                : "unselected-profile-menu"
            }
            onClick={(e) => {
              setSelectedProfileTab("Creator");
              // navigate(`creator`);
            }}
          >
            Creator
          </div>

          <div
            style={{ paddingBottom: "20px" }}
            className={
              selectedProfileTab === "Collector"
                ? "selected-profile-menu"
                : "unselected-profile-menu"
            }
            onClick={(e) => {
              setSelectedProfileTab("Collector");
              // navigate(`collector`);
            }}
            // onClick={(e) => setSelectedProfileTab("Collector")}
          >
            Investor
          </div>
        </div>
        <div style={{ padding: "0px 20px" }}>
          {selectedProfileTab === "Creator" ? (
            <div
              style={{
                display: "flex",
                height: "2px",
                width: "100%",
              }}
            >
              <div style={{ width: "35%", background: "#009EFC" }}></div>
              <div style={{ width: "65%", background: "#EDEDED" }}></div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                height: "2px",
                width: "100%",
              }}
            >
              <div style={{ width: "65%", background: "#EDEDED" }}></div>
              <div style={{ width: "35%", background: "#009EFC" }}></div>
            </div>
          )}
        </div>

        {selectedProfileTab === "Creator" ? <Creator /> : <Collector />}

        {/* <Outlet /> */}
      </Col>
    </Row>
  );
};

export default Profile;
