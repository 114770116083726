import { CognitoUserPool } from "amazon-cognito-identity-js";

const userPool = new CognitoUserPool({
  UserPoolId: "us-east-2_F4SRmE4RG",
  region: "us-east-2",
  ClientId: "185hqs72lihdm5kfbafht6hbqp",
  Storage: window.sessionStorage || window.localStorage,
});

// const s3Config = {
//   bucketName: "gxsocialprofilecover",
//   dirName: "postimages",
//   region: "us-east-2",
//   accessKeyId: "AKIA4HKGLPLP7H73PB57",
//   secretAccessKey: "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",
// };

// const userPool = 9;

export default userPool;
