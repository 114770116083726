import React from "react";
import classNames from "../../styles/pages/institute.module.scss";
import searchIcon from "../../static/images/search.svg";
import testProfile from "./shoruptest.svg";
import { useContext } from "react";
import { PortfolioContext } from "../../context/PortfolioContext";
import { videosInPlaylist } from "../../APIS/educationapi";
import { useEffect } from "react";
import axios from "axios";
import { Col } from "antd";

const InstitutePage = () => {
  const {
    educationLeftSelected,
    seteducationLeftSelected,
    selectedPlaylistData,
    setSelectedPlaylistData,
    allPlaylistAvailable,
    setallPlaylistAvailable,
  } = useContext(PortfolioContext);

  return (
    <Col
      className={`${classNames.educationContainer} transaction-layout`}
      span={20}
    >
      <div className={classNames.leftContainer}>
        <div>
          <div>Playlists</div>
          <img src={searchIcon} alt="searchIcon" />
        </div>
        <div className={classNames.selectionBtns}>
          <div
            className={` ${
              educationLeftSelected == "Youtube"
                ? classNames.selectedBtn
                : classNames.notselectedBtn
            }`}
            onClick={(event) =>
              seteducationLeftSelected(event.target.innerText)
            }
          >
            Youtube
          </div>
          <div
            className={` ${
              educationLeftSelected == "Exclusive"
                ? classNames.selectedBtn
                : classNames.notselectedBtn
            }`}
            onClick={(event) =>
              seteducationLeftSelected(event.target.innerText)
            }
          >
            Exclusive
          </div>
        </div>
        <div>
          {allPlaylistAvailable &&
            allPlaylistAvailable.map((eachPlaylist) => {
              return <VideoContainer eachPlaylist={eachPlaylist} />;
            })}
        </div>
      </div>
      <div className={classNames.rightContainer}>
        <ExclusiveDiv selectedPlaylistData={selectedPlaylistData} />
      </div>
    </Col>
  );
};

export default InstitutePage;

const VideoContainer = ({ eachPlaylist }) => {
  const {
    setSelectedPlaylistData,
    educationSelectedPlaylist,
    seteducationSelectedPlaylist,
    youtubeApiPlaylistData,
    setYoutubeApiPlaylistData,
  } = useContext(PortfolioContext);

  const videosInPlaylistChanging = async (playListId) => {
    let Youtube_API = "AIzaSyBUKr_jOjgyi8yQ6ioy6-wFuJBxC3EbEvU";
    try {
      const data = await axios.get(
        `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&playlistId=${playListId}&key=${Youtube_API}`
      );
      setSelectedPlaylistData(data.data);
    } catch (error) {
      console.error(error, "videosInPlaylist");
    }
  };

  return (
    <div
      className={classNames.eachVideoContainer}
      onClick={() => videosInPlaylistChanging(eachPlaylist?.id)}
    >
      <div>
        <img
          src={eachPlaylist?.snippet?.thumbnails?.default?.url}
          alt="TempImage"
        />
      </div>
      <div className={classNames.contentDiv}>
        <div>{eachPlaylist?.snippet?.localized?.title}</div>
        <div>{eachPlaylist?.snippet?.localized?.description}</div>
      </div>
    </div>
  );
};

const ExclusiveDiv = ({ selectedPlaylistData }) => {
  return (
    <div className={classNames.exclusiveDiv}>
      {selectedPlaylistData?.items?.length > 0 &&
        selectedPlaylistData.items.map((eachdata, i) => {
          return <GeneralCard eachdata={eachdata} i={i} />;
        })}
      {/* <div
        className={classNames.ceoCard}
        style={{ display: selectedPlaylistData ? "" : "none" }}
      >
        <div>
          <img src={testProfile} alt="testProfile" />
        </div>
        <div className={classNames.detailsCard}>
          <div>Shorupan Pirakaspathy</div>
          <div>CEO of Retired App</div>
        </div>
      </div>
      <div
        className={classNames.relatedVideos}
        style={{ display: selectedPlaylistData ? "" : "none" }}
      >
        Related Videos
      </div> */}
    </div>
  );
};

const GeneralCard = ({ eachdata, i }) => {
  return (
    <div className={classNames.generalCard}>
      <iframe
        src={`https://www.youtube.com/embed/${eachdata?.snippet?.resourceId?.videoId}`}
        title={eachdata?.snippet?.title}
        width="100%"
        height="30%"
        key={"iframes" + i}
      ></iframe>
      <div className={classNames.contentDiv}>
        <div>{eachdata?.snippet?.title}</div>
        <div>
          {eachdata?.snippet?.description?.split("»")?.length > 0
            ? eachdata?.snippet?.description?.split("»")[0]
            : eachdata?.snippet?.description}
        </div>
      </div>
    </div>
  );
};

// import { Col } from "antd";
// import React, { useEffect, useState } from "react";
// import styles from "./institute.module.scss";

// import asset from "../../assets/images/asset.svg";
// import Axios from "axios";

// const InstitutePage = () => {
//   const videos = [
//     {
//       id: 1,
//       title: "I Am A Completely New User 1",
//       subtitle:
//         "Assets.io Is The Worlds First Wealth Transit Platform Where Platform . Assets.io Is The Worlds First Wealth",
//       image: asset,
//     },
//     {
//       id: 2,
//       title: "I Am A Completely New User 2",
//       subtitle:
//         "Assets.io Is The Worlds First Wealth Transit Platform Where Platform . Assets.io Is The Worlds First Wealth",
//       image: asset,
//     },
//     {
//       id: 3,
//       title: "I Am A Completely New User 3",
//       subtitle:
//         "Assets.io Is The Worlds First Wealth Transit Platform Where Platform . Assets.io Is The Worlds First Wealth",
//       image: asset,
//     },
//     {
//       id: 4,
//       title: "I Am A Completely New User 4",
//       subtitle:
//         "Assets.io Is The Worlds First Wealth Transit Platform Where Platform . Assets.io Is The Worlds First Wealth",
//       image: asset,
//     },
//     {
//       id: 5,
//       title: "I Am A Completely New User 5",
//       subtitle:
//         "Assets.io Is The Worlds First Wealth Transit Platform Where Platform . Assets.io Is The Worlds First Wealth",
//       image: asset,
//     },
//     {
//       id: 6,
//       title: "I Am A Completely New User 6",
//       subtitle:
//         "Assets.io Is The Worlds First Wealth Transit Platform Where Platform . Assets.io Is The Worlds First Wealth",
//       image: asset,
//     },
//     {
//       id: 7,
//       title: "I Am A Completely New User 7",
//       subtitle:
//         "Assets.io Is The Worlds First Wealth Transit Platform Where Platform . Assets.io Is The Worlds First Wealth",
//       image: asset,
//     },
//     {
//       id: 8,
//       title: "I Am A Completely New User 8",
//       subtitle:
//         "Assets.io Is The Worlds First Wealth Transit Platform Where Platform . Assets.io Is The Worlds First Wealth",
//       image: asset,
//     },
//     {
//       id: 9,
//       title: "I Am A Completely New User 9",

//       subtitle:
//         "Assets.io Is The Worlds First Wealth Transit Platform Where Platform . Assets.io Is The Worlds First Wealth",
//       image: asset,
//     },
//     {
//       id: 10,
//       title: "I Am A Completely New User 10",
//       subtitle:
//         "Assets.io Is The Worlds First Wealth Transit Platform Where Platform . Assets.io Is The Worlds First Wealth",
//       image: asset,
//     },
//   ];
//   const [allPlaylists, setAllPlaylists] = useState([]);
//   const [allVideos, setAllVideos] = useState([]);
//   const [selectedPlaylist, setSelectedPlaylist] = useState(videos[0]);

//   useEffect(() => {
//     let Youtube_API = "AIzaSyBUKr_jOjgyi8yQ6ioy6-wFuJBxC3EbEvU";
//     let channelId = "UChUozFUDbu90WmrA_0-4S1Q";

//     Axios.get(
//       `https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=UChUozFUDbu90WmrA_0-4S1Q&key=AIzaSyBUKr_jOjgyi8yQ6ioy6-wFuJBxC3EbEvU`
//     ).then(({ data }) => {
//       console.log(data?.items, "playlist");
//       setAllPlaylists(data.items);
//       setSelectedPlaylist(data.items[0]);
//     });
//   }, []);

//   useEffect(() => {
//     Axios.get(
//       `https://www.googleapis.com/youtube/v3/search?part=snippet&key=AIzaSyBUKr_jOjgyi8yQ6ioy6-wFuJBxC3EbEvU&channelId=${selectedPlaylist?.snippet?.channelId}`
//     ).then((res) => {
//       console.log(res.data.items, "videosss");
//       setAllVideos(res?.data?.items);
//     });
//   }, [selectedPlaylist]);

//   // useEffect(() => {
//   //   axios
//   //     .get(
//   //       "https://www.googleapis.com/youtube/v3/search?part=snippet&key=AIzaSyBUKr_jOjgyi8yQ6ioy6-wFuJBxC3EbEvU&channelId=UChUozFUDbu90WmrA_0-4S1Q"
//   //     )
//   //     .then((response) => {
//   //       let filteredVideos = response.data.items.filter((eachData) => {
//   //         return eachData.id.kind.includes("video");
//   //       });
//   //       setYoutubeApiData(response.data);
//   //       setYoutubeApiVideoData(filteredVideos);
//   //     })
//   //     .catch((error) => {
//   //       console.log(error, "youtube error");
//   //     });
//   //   axios
//   //     .get(
//   //       "https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=UChUozFUDbu90WmrA_0-4S1Q&key=AIzaSyBUKr_jOjgyi8yQ6ioy6-wFuJBxC3EbEvU"
//   //     )
//   //     .then(async (response) => {
//   //       if (response?.data?.items[0]?.id) {
//   //         playListidDefault = response?.data?.items[0]?.id;
//   //         let playlistData = await videosInPlaylist(
//   //           response?.data?.items[0]?.id
//   //         );
//   //         setSelectedPlaylistData(playlistData);
//   //       }
//   //       setYoutubeApiPlaylistData(response?.data?.items[0]);
//   //       setallPlaylistAvailable(response?.data?.items);
//   //     })
//   //     .catch((error) => {
//   //       console.log("playlist error", error);
//   //     });
//   // }, []);

//   return (
//     <Col span={20} className={styles.container}>
//       <div>
//         <h1 className={styles.header}>Playlists</h1>
//         <div
//           className={styles.playlist}
//           style={{ height: window.innerHeight - 200 }}
//         >
//           {allPlaylists.map((item, index) => {
//             return (
//               <div
//                 className={
//                   selectedPlaylist
//                     ? selectedPlaylist.id === item.id
//                       ? styles.selectedCard
//                       : styles.card
//                     : styles.card
//                 }
//                 onClick={() => setSelectedPlaylist(item)}
//               >
//                 <div>
//                   <img src={item?.snippet?.thumbnails?.default?.url} alt="" />
//                 </div>
//                 <div className={styles.cardContent}>
//                   <div className={styles.title}>{item?.snippet?.title}</div>
//                   <div className={styles.subtitle}>{item.subtitle}</div>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//       <div>
//         <div className={styles.player}>&nbsp;</div>
//         <div className={styles.dataContainer}>
//           <div>{selectedPlaylist?.title}</div>
//           <div>{selectedPlaylist?.subtitle}</div>
//         </div>
//         <div className={styles.creator}>
//           <div className={styles.creatorAvatar}>
//             <img
//               src={asset}
//               alt=""
//               width={"100%"}
//               style={{ borderRadius: "50%" }}
//             />
//           </div>
//           <div className={styles.creatorInfo}>
//             <div>Shorupan Pirakaspathy</div>
//             <div>CEO of Retired App</div>
//           </div>
//         </div>
//         <div className={styles.relatedVideosTitle}>Related Videos</div>
//       </div>
//     </Col>
//   );
// };

// export default InstitutePage;
