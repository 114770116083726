import { Col, Empty, Row, Spin } from "antd";
import React, { useState, useContext, useEffect } from "react";
import MenuSidebar from "../components/MenuSidebar";
import CreatePostComponent from "../components/CreatePostComponent";

import { MyContext } from "../Context";
import PostComponent from "../components/PostComponent";
import Axios from "axios";
import LoggedoutPostComponent from "../components/LoggedoutPostComponent";
import Nav from "../components/Nav";
import LoggedOutMenuSidebar from "../components/LoggedOutMenuSidebar";
import ExplorePost from "../components/Minicomponents/ExplorePost";

const LoggedoutHome = () => {
  const {
    newsFeed,
    trendClicked,
    getPostsOfTrend,
    resTrendPosts,
    userInfo,
    allPosts,
    allUserData,
    allPostTrends,
    showMenu,
  } = useContext(MyContext);

  const showConditionalPosts = () => {
    console.log("newsfeed", newsFeed.data);
    if (resTrendPosts.data && trendClicked) {
      // console.log("jhkjhkjhk", value.resTrendPosts.data);
      return resTrendPosts.data ? (
        resTrendPosts.data.user_choosen_trend
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => (
            <PostComponent
              item={item}
              index={index}
              userInfo={userInfo.data ? userInfo : ""}
            />
          ))
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <p>No Posts</p>
        </div>
      );
    } else if (newsFeed.data && !trendClicked) {
      if (newsFeed.data.get_my_home_posts.length > 0) {
        return newsFeed.data.get_my_home_posts
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => (
            <PostComponent
              item={item}
              index={index}
              userInfo={userInfo.data ? userInfo : ""}
            />
          ));
      } else {
        return (
          <div style={{ padding: "20px" }}>
            <Empty
              description={
                <span>
                  Either you are not subscribed to anyone <br />
                  or there are no posts to show
                </span>
              }
            />
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <Spin />
        </div>
      );
    }
  };

  return (
    <>
      <Row style={{ height: "calc(100% - 105px)" }}>
        {window.innerWidth > 900 ? <LoggedOutMenuSidebar /> : ""}
        {!showMenu ? (
          <Col
            xs={24}
            sm={20}
            style={{
              borderRight: "0.5px solid #EDEDED",
              // background: "#ECF0F1",
              background: "white",
              overflowY: "scroll",
              height: "98vh",
              padding: " 1px",
            }}
          >
            {/* <CreatePostComponent /> */}
            {allPosts ? (
              allPosts.length > 0 ? (
                <div style={{ padding: " 20px" }}>
                  <ExplorePost />
                  {allPosts ? (
                    allPosts
                      .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
                      .map((item, index) => {
                        return (
                          <>
                            {allPostTrends ? (
                              <LoggedoutPostComponent
                                item={item}
                                index={index}
                                userInfo={allUserData ? allUserData[0] : ""}
                                author={allUserData[item.author_id]}
                                trends={allPostTrends}
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                  ) : (
                    <Spin />
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "30vh",
                  }}
                >
                  <Spin />
                  <div style={{ paddingTop: "-10px" }}>
                    {" "}
                    &nbsp;&nbsp;Loading Posts...
                  </div>
                </div>
              )
            ) : (
              ""
            )}
          </Col>
        ) : (
          ""
        )}
        {/* <Col span={6} style={{ display: "flex", justifyContent: "center" }}>
          <Empty />
        </Col> */}
      </Row>
    </>
  );
};

export default LoggedoutHome;
