import portfolioIcon from '../../static/images/sidebar-icons/portfolio.svg';
import vaultsIcon from '../../static/images/sidebar-icons/vaults.svg';
import marketsIcon from '../../static/images/sidebar-icons/marketsIcon.svg';
import affiliateIcon from '../../static/images/sidebar-icons/affiliateIcon.svg';
import taxesIcon from '../../static/images/sidebarIcons/taxes.svg';
import supportIcon from '../../static/images/sidebar-icons/supportIcon.svg';
import sharesToken from '../../static/images/login/sharestoken.svg';
import vcfunnelIcon from '../../static/images/sidebar-icons/vcfunnel.svg';
import indianmarketIcon from '../../static/images/sidebar-icons/indianmarketIcon.svg';
import dealsIcon from '../../static/images/sidebar-icons/dealsIcon.svg';

import displayIcon from '../../static/images/settings/displayCurrencyIcon.svg';
import themeIcon from '../../static/images/settings/themeIcon.svg';
import securityIcon from '../../static/images/settings/securityIcon.svg';
import kycIcon from '../../static/images/settings/kycIcon.svg';
import bankingIcon from '../../static/images/settings/banking.svg';
import logoutIcon from '../../static/images/settings/logoutIcon.svg';

import qrAddress from '../../static/images/actionSidebar/qrAddress.svg';
import connectIcon from '../../static/images/vaultCpanelIcons/connect.svg';
import withdraw from '../../static/images/vaultCpanelIcons/withdraw.svg';
import arrowUp from '../../static/images/clipIcons/arrowUp.svg';
import arrowDown from '../../static/images/clipIcons/arrowDown.svg';

import reactIcon from '../../static/images/sidebar-icons/react.svg';

export const SIDEBAR_CONTENTS = [
  {
    id: 1,
    name: 'Portfolio.ai',
    icon: portfolioIcon,
  },
  {
    id: 2,
    name: 'Vaults',
    icon: vaultsIcon,
  },
  {
    id: 3,
    name: 'Offerings',
    icon: marketsIcon,
  },
  {
    id: 4,
    name: 'Deals',
    icon: dealsIcon,
  },
  // {
  //   id: 4,
  //   name: 'Partners',
  //   icon: affiliateIcon,
  // },
  // {
  //   id: 5,
  //   name: 'Taxes',
  //   icon: taxesIcon,
  // },
  {
    id: 6,
    name: 'Support',
    icon: supportIcon,
  },
  // {
  //   id: 7,
  //   name: 'ShareToken',
  //   icon: sharesToken,
  // },
  {
    id: 8,
    name: 'VCFunnel',
    icon: vcfunnelIcon,
  },
  {
    id: 9,
    name: 'IndianMarket',
    icon: indianmarketIcon,
  },
  {
    id: 10,
    name: 'React',
    icon: reactIcon,
    // subtitle: 'Our Community',
  },
];

export const SETTINGS_MENU = (
  onLogoutClick,
  onBankingClick,
  onTaxesClick,
  onSharetokenClick,
  onVaultClick
) => [
  {
    id: 1,
    icon: displayIcon,
    text: 'Display Currency',
    subText: 'Currently Indian Rupees',
  },
  {
    id: 2,
    icon: themeIcon,
    text: 'Theme',
    subText: 'Currently Light Mode',
  },
  {
    id: 3,
    icon: securityIcon,
    text: 'Security',
    subText: 'Enhance Your Security',
  },
  {
    id: 4,
    icon: kycIcon,
    text: 'KYC',
    subText: 'Currently Not Verified',
  },
  {
    id: 5,
    icon: bankingIcon,
    text: 'Banking',
    subText: 'Attach Your Bank Accounts',
    onClick: onBankingClick,
  },
  {
    id: 7,
    text: 'Taxes',
    icon: taxesIcon,
    onClick: onTaxesClick,
  },
  {
    id: 8,
    text: 'ShareToken',
    icon: sharesToken,
    onClick: onSharetokenClick,
  },
  {
    id: 2,
    text: 'Vaults',
    icon: vaultsIcon,
    onClick: onVaultClick,
  },
  {
    id: 6,
    icon: logoutIcon,
    text: 'Logout',
    subText: 'Hope To See You Again Soon',
    onClick: onLogoutClick,
  },
];

export const COIN_ACTIONS = (coinName, setCoinAction, isMarketsPage) => {
  const showBuyAndSellOnly = [
    {
      id: 5,
      icon: arrowUp,
      text: 'Buy',
      subText: `Buy ${coinName} With Another Currency`,
      action: () => setCoinAction(['Buy']),
    },
    {
      id: 6,
      icon: arrowDown,
      text: 'Sell',
      subText: `Buy ${coinName} For Another Currency`,
      action: () => setCoinAction(['Sell']),
    },
  ];

  const enableActionsByCondition = isMarketsPage
    ? showBuyAndSellOnly
    : [
        {
          id: 1,
          icon: qrAddress,
          text: 'Add',
          subText: `Add ${coinName} From External Wallet`,
          action: () => setCoinAction(['Add']),
        },
        {
          id: 2,
          icon: vaultsIcon,
          text: 'Transfer',
          subText: `Transfer ${coinName} Between Your Vaults`,
          action: () => setCoinAction(['Transfer']),
        },
        {
          id: 3,
          icon: connectIcon,
          text: 'Connect',
          subText: `Send ${coinName} To Your Friends`,
        },
        {
          id: 4,
          icon: withdraw,
          text: 'Withdraw',
          subText: `Send ${coinName} To An External Wallet`,
          action: () => setCoinAction(['Withdraw']),
        },
        ...showBuyAndSellOnly,
      ];
  return enableActionsByCondition;
};
