import { Empty } from "antd";
import React from "react";

const Videos = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "10vh",
        }}
      >
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={
            <span>
              No <a href="#API">Videos</a> Found
            </span>
          }
        />
      </div>
    </>
  );
};

export default Videos;
