import React from 'react';
import Skeleton from 'react-loading-skeleton';

function BondsItemLoading() {
  return (
    <div className="bondsItem">
      <div className="coinPrice">
        <div className="img">
          <Skeleton circle height={25} width={25} />
          <Skeleton width={240} />
        </div>
        <div className="title">
          <Skeleton width={180} />
        </div>
      </div>
      <div className="labels">
        <Skeleton width={180} />
        <Skeleton width={180} />
      </div>
      <div className="rates">
        <div className="ratesItem text-left">
          <div className="value">
            <Skeleton width={180} />
          </div>
          <div className="label">
            <Skeleton width={180} />
          </div>
        </div>
        <div className={`ratesItem text-center`}>
          <div className="value">
            <Skeleton width={180} />
          </div>
          <div className="label">
            <Skeleton width={180} />
          </div>
        </div>
        <div className={`ratesItem text-right`}>
          <div className="value">
            <Skeleton width={180} />
          </div>
          <div className="label">
            <Skeleton width={180} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BondsItemLoading;
