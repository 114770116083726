import React, { createContext, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ASSET_CLASSES } from '../config/constants';
import {
  useShareTokensVaultsList,
  useUserBondsList,
  useUserBondsTxns,
  useUserMoneMarketsList,
  useUserMoneyMarketsTxns,
  useUserVaults,
  useVaultTxns,
} from '../queryHooks';
import { useAppContextDetails } from './AppContext';
import { useCoinContextData } from './CoinContext';
import { BankContext } from './Context';

export const VaultPageContext = createContext();
function VaultPageContextProvider({ children }) {
  const { email } = useContext(BankContext);
  const [appSelected, setAppSelected] = useState();
  const [vaultSelected, setVaultSelected] = useState();
  const [assetClass, setAssetClass] = useState(ASSET_CLASSES[0]);
  const [cpanelOpen, setCpanelOpen] = useState(false);
  const { assetClassParam } = useParams();
  const { updateVaultData } = useCoinContextData();
  const { appCurrencyName } = useAppContextDetails();

  useEffect(() => {
    const assetCls = ASSET_CLASSES.filter(
      (assetClass) => assetClass.name === assetClassParam
    )[0];
    if (assetCls) setAssetClass(assetCls);
  }, [assetClassParam]);

  const {
    data: vaultsListFxCrypto = [],
    isLoading: vaultsListFxCryptoLoading,
    refetch: refetchVaultsListFxCrypto,
  } = useUserVaults(
    email,
    assetClass?.name,
    appSelected?.app_code,
    undefined,
    undefined,
    appCurrencyName
  );

  const {
    data: txnListFxCrypto,
    isLoading: txnListLoadingFxCrypto = true,
    refetch: refetchTxnListFxCrypto,
  } = useVaultTxns(
    email,
    appSelected?.app_code,
    vaultSelected?.coinSymbol,
    undefined,
    updateVaultData,
    appCurrencyName
  );

  const {
    data: vaultsListBonds,
    isLoading: vaultsListBondsLoading,
    refetch: refetchVaultsListBonds,
  } = useUserBondsList(email, appCurrencyName);

  const {
    data: txnListBonds,
    isLoading: txnListLoadingBonds = true,
    refetch: refetchTxnListBonds,
  } = useUserBondsTxns(email, vaultSelected?.coinSymbol);

  const {
    data: vaultsListMM,
    isLoading: vaultsListLoadingMM,
    refetch: refetchVaultsListMM,
  } = useUserMoneMarketsList(email, appSelected?.app_code, appCurrencyName);

  const {
    data: txnListMM,
    isLoading: txnListLoadingMM = true,
    refetch: refetchtxnListMM,
  } = useUserMoneyMarketsTxns(
    email,
    vaultSelected?.coinSymbol,
    appSelected?.app_code
  );

  const {
    data: vaultsListShares,
    isLoading: vaultsListLoadingShares,
    refetch: refetchVaultsListShares,
  } = useShareTokensVaultsList(email, appSelected?.app_code);

  const {
    data: txnListShares,
    isLoading: txnListLoadingShares = true,
    refetch: refetchtxnListShares,
  } = useVaultTxns(
    email,
    vaultSelected?.app_code,
    vaultSelected?.tokens &&
      vaultSelected?.tokens[0]?.token_profile_data?.coinSymbol,
    vaultSelected?.profile_id,
    updateVaultData,
    appCurrencyName
  );

  function refetchData() {
    refetchVaultsListFxCrypto();
    refetchTxnListFxCrypto();
    refetchVaultsListBonds();
    refetchTxnListBonds();
    refetchVaultsListMM();
    refetchtxnListMM();
    refetchVaultsListShares();
    refetchtxnListShares();
  }
  return (
    <VaultPageContext.Provider
      value={{
        assetClass,
        setAssetClass,
        appSelected,
        setAppSelected,
        vaultSelected,
        setVaultSelected,
        cpanelOpen,
        setCpanelOpen,
        vaultsListFxCrypto,
        vaultsListFxCryptoLoading,
        vaultsListBonds,
        vaultsListBondsLoading,
        vaultsListMM,
        vaultsListLoadingMM,
        txnListBonds,
        txnListLoadingBonds,
        txnListMM,
        txnListLoadingMM,
        txnListFxCrypto,
        txnListLoadingFxCrypto,
        vaultsListShares: vaultsListShares?.vaultsData,
        vaultsListLoadingShares,
        txnListShares,
        txnListLoadingShares,
        refetchData,
      }}
    >
      {children}
    </VaultPageContext.Provider>
  );
}

export default VaultPageContextProvider;
