import React, { useState, useRef, useEffect, useContext } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
// import S3 from "aws-s3";
import AWS from "aws-sdk";
// import S3 from "react-aws-s3";
import imgs from "../img/blurry.jpeg";
import { customAlphabet } from "nanoid";

import { MyContext } from "../Context";
import * as jose from "jose";

import {
  Input,
  Select,
  Button,
  Card,
  message,
  Divider,
  Dropdown,
  Menu,
  Icon,
  Avatar,
  Popover,
  Tooltip,
  Form,
} from "antd";
import OnlyTitle from "./OnlyTitle";
import TextArea from "antd/lib/input/TextArea";
import Axios from "axios";

import {
  BoldOutlined,
  UnderlineOutlined,
  ItalicOutlined,
  CameraOutlined,
  LoadingOutlined,
  SearchOutlined,
  PaperClipOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

const CREATE_POST = gql`
  mutation addPost(
    $user_id: ID!
    $title: String!
    $body: String!
    $trends: [String!]!
    $tier: ID!
    $is_this_non_course_video_post: Boolean
    $video_id: ID
    $thumbnail: String
    $video_title: String
    $video_subtitle: String
    $video_description: String
  ) {
    create_post(
      data: {
        user_id: $user_id
        title: $title
        body: $body
        trends: $trends
        post_tier_id: $tier
        is_this_non_course_video_post: $is_this_non_course_video_post
        video_id: $video_id
        thumbnail: $thumbnail
        video_title: $video_title
        video_subtitle: $video_subtitle
        video_description: $video_description
      }
    ) {
      title
      body
      timestamp
      id
    }
  }
`;

const CREATE_POST_NEW = gql`
  mutation addPostNew(
    $user_id: ID!
    $title: String!
    $body: String!
    $oldtrends: [String!]!
    $newtrends: [String!]!
    $tier: ID!
    $is_this_non_course_video_post: Boolean
    $video_id: ID
    $thumbnail: String
    $video_title: String
    $video_subtitle: String
    $video_description: String
  ) {
    create_post(
      data: {
        user_id: $user_id
        title: $title
        body: $body
        trends: $oldtrends
        post_tier_id: $tier
        is_this_non_course_video_post: $is_this_non_course_video_post
        video_id: $video_id
        thumbnail: $thumbnail
        video_title: $video_title
        video_subtitle: $video_subtitle
        video_description: $video_description
      }
      new_trend: { new_trend_names: $newtrends }
    ) {
      title
      body
      timestamp
      id
    }
  }
`;

const NEWS_FEED = gql`
  query ($user_id: ID!) {
    get_my_home_posts(data: { user_id: $user_id }) {
      id
      title
      body
      locked
      post_tier {
        id
        name
      }
      timestamp
      author {
        username
        id
        email
      }
      comments {
        text
        author {
          id
          username
        }
      }
      liked_users {
        id
        email
        username
      }
      comment_count
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const ALL_TRENDS = gql`
  {
    trends {
      id
      name
      post_count
    }
  }
`;

const GET_TIERS = gql`
  query getT($user_id: ID!) {
    get_my_tiers(data: { user_id: $user_id }) {
      id
      name
      description
      subscribed_user_count
      price
    }
  }
`;

const MY_POSTS = gql`
  query ($user_id: ID!) {
    get_my_posts(data: { user_id: $user_id }) {
      id
      title
      body
      timestamp
      like_count
      post_tier {
        id
        name
      }
      comments {
        text
      }
      author {
        id
        name
        username
        profile_image
        email
      }
      comment_count
      liked_users {
        id
        username
      }
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const { Option } = Select;

const CreatePostComponent = ({ setShowCreatePost }) => {
  const { userEmail } = useContext(MyContext);
  let inputRef = useRef();
  let quillRef = useRef();
  let bodyRef = useRef();
  var savedRange;
  const [createPost, createPostData] = useMutation(CREATE_POST);
  const [createPostNew, createPostDataNew] = useMutation(CREATE_POST_NEW);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTier, setSelectedTier] = useState("");
  const [selectedTrends, setSelectedTrends] = useState([]);
  const [selectedTrendsId, setSelectedTrendsId] = useState([]);
  const [selectedTrendsName, setSelectedTrendsName] = useState([]);
  const [cursorPos, setCursorPos] = useState(0);
  const [allTrendIds, setAllTrendIds] = useState([]);
  const [myVideos, setMyVideos] = useState([]);

  const [suggestions, setSuggestions] = useState([]);
  const [userName, setUserName] = useState({});
  const [isTypingName, setIsTypingName] = useState(false);
  const [text, setText] = useState("");

  const allTrends = useQuery(ALL_TRENDS);
  const userTiers = useQuery(GET_TIERS, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
    },
  });
  const [selectedVideo, setSelectedVideo] = useState("");
  const [query, setQuery] = useState("");

  const nanoid = customAlphabet("23456789abcdefghijklmnopqrstuvwxyz", 15);
  const S3_BUCKET = "connections-app-dev";

  const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
  const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

  var myPosts = useQuery(MY_POSTS, {
    variables: {
      user_id: localStorage.getItem("selected_user_id"),
      // email: localStorage.getItem("user_account"),
    },
    skip: userEmail
      ? userEmail !== localStorage.getItem("user_account")
      : localStorage.getItem("user_profile_email") !==
        localStorage.getItem("user_account"),
  });

  useEffect(() => {
    if (createPostData.data) {
      console.log(createPostData.data, "qjkwbdkjwbed");
      setTitle("");

      // inputRef.current.input.value = "";
      // inputRef.current.state.value = "";
      // bodyRef.current.state.value = "";
      bodyRef.current.innerHTML = "";
      setSelectedTrends(null);
      setSelectedTier(null);
      setShowCreatePost(false);
    }
  }, [createPostData]);

  useEffect(() => {
    Axios.post(
      "https://vod-backend.globalxchange.io/get_my_profiled_video_list",
      {},
      {
        headers: {
          email: localStorage.getItem("user_account"),
          token: localStorage.getItem("token"),
        },
      }
    ).then(({ data }) => {
      // let temp = res.data.payload;
      // temp.pop();
      // console.log("myvid", res.data.payload);
      if (data.status) {
        setMyVideos(data?.payload);
      }
    });
  }, []);

  let filteredVideos = myVideos?.filter((item) => {
    const lowquery = query.toLowerCase();
    return item.title.toLowerCase().indexOf(lowquery) >= 0;
  });
  const saveSelection = () => {
    if (window.getSelection) {
      //non IE Browsers
      savedRange = window.getSelection().getRangeAt(0);
    } else if (document.selection) {
      //IE
      savedRange = document.selection.createRange();
    }
  };

  const restoreSelection = () => {
    // isInFocus = true;
    // bodyRef.current.focus();
    if (savedRange != null) {
      if (window.getSelection) {
        //non IE and there is already a selection
        var s = window.getSelection();
        console.log("range is there", savedRange);
        if (s.rangeCount > 0) s.removeAllRanges();
        s.addRange(savedRange);
      } else if (document.createRange) {
        //non IE and no selection
        console.log("range is not there");
        window.getSelection().addRange(savedRange);
      } else if (document.selection) {
        //IE
        console.log("range is there IE");
        savedRange.select();
      }
    }
  };

  const videoPopover = filteredVideos.map((item, index) => {
    if (item.file_type === "video") {
      return (
        <div
          style={{ padding: "10px", cursor: "pointer" }}
          onClick={(e) => setSelectedVideo(item)}
        >
          <img
            //   style={{ marginTop: "-5px" }}
            src={require(`../img/video.png`)}
            alt=""
            width="20px"
          />
          &nbsp;&nbsp;{item.title.substring(0, 30)}...
        </div>
      );
    }
  });
  useEffect(() => {
    if (allTrends.data) {
      // console.log("alltrend", allTrends.data.trends);
      let tempArr = [];
      allTrends.data.trends.map((item, index) => {
        tempArr.push(item.id);
      });
      setAllTrendIds(tempArr);
    }
    // console.log("alltrend", allTrendIds);
  }, [allTrends.data]);

  // const s3Config = {
  //   bucketName: "connections-app-dev",
  //   dirName: "postimages",
  //   region: "ap-south-1",
  //   // accessKeyId: "AKIAUEO6Q6T7BHZWWS6F",
  //   // secretAccessKey: "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",
  //   accessKeyId: "AKIAUEO6Q6T7E5XJOAQR",
  //   secretAccessKey: "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",
  // };

  AWS.config.update({
    accessKeyId: "AKIAUEO6Q6T7E5XJOAQR",
    secretAccessKey: "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",
    //   accessKeyId: "AKIAUEO6Q6T7BHZWWS6F",
    //   secretAccessKey: "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",
  });

  const myBucket = new AWS.S3({
    params: { Bucket: "connections-app-dev" },
    region: "ap-south-1",
  });

  // const imageHandler = (image, callback) => {
  //   selectLocalImage();

  // };

  useEffect(() => {
    console.log(cursorPos);
  }, [cursorPos]);

  //   const handleChange = html => {
  //     setBody(html);
  //   };

  /**
   * Step1. select local image
   *
   */
  // const selectLocalImage = () => {
  //   const input = document.createElement("input");
  //   input.setAttribute("type", "file");
  //   input.click();

  //   // Listen upload local image and save to server
  //   input.onchange = () => {
  //     const file = input.files[0];

  //     // file type is only image.
  //     if (/^image\//.test(file.type)) {
  //       saveToServer(file);
  //     } else {
  //       console.warn("You could only upload images.");
  //     }
  //   };
  // };

  /*
    Step2. save to server   
  */

  // const saveToServer = async selectedImg => {
  //   const S3Client = new S3(s3Config);
  //   let uploaded = await S3Client.uploadFile(selectedImg);
  //   let uploaded_data;
  //   try {
  //     message.loading("uploading...");
  //     uploaded_data = await S3Client.uploadFile(selectedImg, selectedImg.name);
  //     // message.success("File Upload Success", 2);
  //     insertToEditor(uploaded_data.location);
  //     message.destroy();
  //   } catch (e) {
  //     console.log(e);
  //     message.destroy();
  //     message.error("File Upload Failed", 2);
  //   }
  // };

  /**
   * Step3. insert image url to rich editor.
   */
  // const insertToEditor = url => {
  //   // push image url to rich editor.
  //   const range = quillRef.editor.getSelection();
  //   quillRef.editor.insertEmbed(range.index, "image", `${url}`);
  // };

  const submitPost = () => {
    console.log("alltrend", allTrendIds);
    console.log(
      "IDs",
      selectedTrends.filter((value) => allTrendIds.includes(value))
    );
    console.log(
      "Names",
      selectedTrends.filter((value) => !allTrendIds.includes(value))
    );

    //conditional Create Post

    if (
      selectedTrends.filter((value) => !allTrendIds.includes(value)).length ===
      0
    ) {
      console.log("inside if", {
        user_id: localStorage.getItem("pulse_user_id"),
        email: localStorage.getItem("user_account"),
        title: title ? title : null,
        body: bodyRef.current.innerHTML
          ? JSON.stringify(bodyRef.current.innerHTML)
          : null,
        trends: selectedTrends,
        tier: selectedTier,
        is_this_non_course_video_post: selectedVideo ? true : null,
        video_id: selectedVideo ? selectedVideo.video_id : null,
        thumbnail: selectedVideo ? selectedVideo.thumbnail : null,
        video_title: selectedVideo ? selectedVideo.title : null,
        video_subtitle: selectedVideo ? selectedVideo.subtitle : null,
        video_description: selectedVideo ? selectedVideo.description : null,
      });
      createPost({
        variables: {
          user_id: localStorage.getItem("pulse_user_id"),
          // email: localStorage.getItem("user_account"),
          title: title ? title : null,
          body: bodyRef.current.innerHTML
            ? JSON.stringify(bodyRef.current.innerHTML)
            : null,
          trends: selectedTrends,
          tier: selectedTier,
          is_this_non_course_video_post: selectedVideo ? true : null,
          video_id: selectedVideo ? selectedVideo.video_id : null,
          thumbnail: selectedVideo ? selectedVideo.thumbnail : null,
          video_title: selectedVideo ? selectedVideo.title : null,
          video_subtitle: selectedVideo ? selectedVideo.subtitle : null,
          video_description: selectedVideo ? selectedVideo.description : null,
        },
        refetchQueries: () => [
          {
            query: MY_POSTS,
            variables: {
              user_id: localStorage.getItem("pulse_user_id"),
              // email: localStorage.getItem("user_account"),
            },
            skip: userEmail
              ? userEmail !== localStorage.getItem("user_account")
              : localStorage.getItem("user_profile_email") !==
                localStorage.getItem("user_account"),
          },
          {
            query: NEWS_FEED,
            variables: {
              user_id: localStorage.getItem("pulse_user_id"),
              // email: localStorage.getItem("user_account"),
            },
          },
        ],

        awaitRefetchQueries: true,
      });

      // inputRef.current.input.value = "";
      // inputRef.current.state.value = "";
      // bodyRef.current.state.value = "";
      // bodyRef.current.innerHTML = "";
      // setSelectedTrends([]);
      // setSelectedTier([]);
    } else if (
      selectedTrends.filter((value) => !allTrendIds.includes(value)).length > 0
    ) {
      console.log("inside else");
      createPostNew({
        variables: {
          user_id: localStorage.getItem("pulse_user_id"),
          // email: localStorage.getItem("user_account"),
          title: title ? title : null,
          body: bodyRef.current.innerHTML
            ? JSON.stringify(bodyRef.current.innerHTML)
            : null,
          oldtrends: selectedTrends.filter((value) =>
            allTrendIds.includes(value)
          ),
          tier: selectedTier,
          newtrends: selectedTrends.filter(
            (value) => !allTrendIds.includes(value)
          ),
          is_this_non_course_video_post: selectedVideo ? true : null,
          video_id: selectedVideo ? selectedVideo.video_id : null,
          thumbnail: selectedVideo ? selectedVideo.thumbnail : null,
          video_title: selectedVideo ? selectedVideo.title : null,
          video_subtitle: selectedVideo ? selectedVideo.subtitle : null,
          video_description: selectedVideo ? selectedVideo.description : null,
        },
        refetchQueries: () => [
          {
            query: MY_POSTS,
            variables: {
              user_id: localStorage.getItem("pulse_user_id"),
              // email: localStorage.getItem("user_account"),
            },
            skip: userEmail
              ? userEmail !== localStorage.getItem("user_account")
              : localStorage.getItem("user_profile_email") !==
                localStorage.getItem("user_account"),
          },
          {
            query: NEWS_FEED,
            variables: {
              user_id: localStorage.getItem("pulse_user_id"),
              // email: localStorage.getItem("user_account"),
            },
          },
        ],
        awaitRefetchQueries: true,
      });
      setTitle("");
      // inputRef.current.input.value = "";

      // bodyRef.current.state.value = "";
      bodyRef.current.innerHTML = "";
    }

    // quillRef.setContents([{ insert: "\n" }]);
  };

  // useEffect(() => {
  //   console.log(selectedTrendsId);
  //   console.log(selectedTrendsName);
  // });

  const getImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      insertMyImage(event.target.files[0]);
    }
    // insertMyImage(event.target.files[0]);
  };

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };
  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  const insertMyImage = async (file) => {
    setLoading(true);

    const fileName = `${new Date().getTime()}${file.name.substr(
      file.name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const newfile = renameFile(file, fileName);
    formData.append("files", newfile);
    const path_inside_brain = "root/";

    // const fileName = `${new Date().getTime()}${file.name.substr(
    //   file.name.lastIndexOf(".")
    // )}`;
    // const formData = new FormData();
    // formData.append("file", file);
    // const path_inside_brain = "root/";
    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, "lkjkswedcf");
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // setImage(data.payload.url);
    restoreSelection();

    document.execCommand("insertImage", false, data.payload.url);
    setLoading(false);
  };

  // const insertMyImage = async (file) => {
  //   setLoading(true);

  //   let fileExtention = file.name.split(".")[1];
  //   const params = {
  //     Body: file,
  //     Bucket: S3_BUCKET,
  //     Key: `test/${nanoid()}.${fileExtention}`,
  //     ContentType: `image/${fileExtention}`,
  //   };

  //   myBucket.upload(params, function (err, data) {
  //     if (!err) {
  //       //   console.log(err, data, "hdcjhwcjhwv");
  //       // console.log(`https://ik.imagekit.io/0tt94zrgb5v/dev/${data.key}`);
  //       message.success("File Upload Success", 2);
  //       let url = `https://ik.imagekit.io/0tt94zrgb5v/dev/${data.key}`;

  //       restoreSelection();

  //       document.execCommand("insertImage", false, url);
  //       //
  //       // insertToEditor(url);
  //       setLoading(false);
  //     } else {
  //       console.log(err);
  //       setLoading(false);
  //       message.destroy();
  //       message.error("File Upload Failed", 2);
  //     }
  //   });

  //   // const S3Client = new S3(s3Config);
  //   // let uploaded = await S3Client.uploadFile(selectedImg);
  //   // console.log(selectedImg, "khwedjchwed");
  //   // let uploaded_data;
  //   // try {
  //   //   uploaded_data = await S3Client.uploadFile(selectedImg, selectedImg.name);
  //   //   message.success("File Upload Success", 2);

  //   //   // let url =
  //   //   //   "https://djoplec97gdag.cloudfront.net" +
  //   //   //   uploaded_data.location.substring(55, uploaded_data.location.length);
  //   //   // let url =
  //   //   //   "https://ik.imagekit.io/0tt94zrgb5v/dev/" + selectedImg.name + ".png";
  //   //   // insertToEditor(uploaded_data.location);
  //   //   // console.log(bodyRef.current.getSelection());
  //   //   bodyRef.current.focus();
  //   //   // moveCaret(window, 20);
  //   //   restoreSelection();
  //   //   // document.execCommand("insertImage", false, url);
  //   //   setLoading(false);
  //   //   // uploaded = null;
  //   // } catch (e) {
  //   //   console.log(e);
  //   //   setLoading(false);
  //   //   message.destroy();
  //   //   message.error("File Upload Failed", 2);
  //   // }
  // };

  const blockSpecialChar = (e) => {
    var k;
    document.all ? (k = e.keyCode) : (k = e.which);
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <div
      // style={{
      //   padding: "20px",
      //   paddingBottom: "5px"
      // }}
      >
        <div style={{ padding: "20px" }}>
          <Input
            bordered={false}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            // ref={inputRef}
            size="large"
            type="text"
            placeholder="Title"
            style={{
              fontSize: "25px",
              fontWeight: "bold",
            }}
          />
        </div>

        <div
          onBlur={saveSelection}
          onMouseUp={saveSelection}
          className="editableDivStyle postbody"
          ref={bodyRef}
          value={body}
          onChange={(e) => {
            setBody(e.target.value);
          }}
          contentEditable="true"
          placeholder="Write Something..."
          style={{
            borderStyle: "none",
            padding: "0px 35px",
            paddingBottom: "30px",
            cursor: "text",
            maxHeight: "500px",
            overflowY: "auto",
          }}
        ></div>
        <Divider style={{ margin: "0px 0px", marginTop: "20px" }} />
        <div>
          <Button
            style={{ borderStyle: "none" }}
            onClick={(e) => document.execCommand("bold", false, null)}
          >
            <BoldOutlined style={{ fontSize: "15px", fontWeight: "bold" }} />
          </Button>
          <Button
            style={{ borderStyle: "none" }}
            onClick={(e) => document.execCommand("italic", false, null)}
          >
            <ItalicOutlined style={{ fontSize: "15px", fontWeight: "bold" }} />
          </Button>
          <Button
            style={{ borderStyle: "none" }}
            onClick={(e) => document.execCommand("underline", false, null)}
          >
            <UnderlineOutlined
              style={{ fontSize: "15px", fontWeight: "bold" }}
            />
          </Button>
          &nbsp;&nbsp;&nbsp;
          <label for="image">
            <input
              accept="image/*"
              onChange={getImage}
              type="file"
              name="image"
              id="image"
              style={{ display: "none" }}
            />
            {!loading ? (
              <CameraOutlined
                style={{ fontSize: "15px", fontWeight: "bold" }}
              />
            ) : (
              <>
                <LoadingOutlined
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                />{" "}
                Uploading
              </>
            )}
          </label>
          {/* <Testing /> */}
          &nbsp;&nbsp;&nbsp;
          <Popover
            placement="bottom"
            content={videoPopover}
            title={
              <Input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="search video..."
                suffix={<SearchOutlined />}
              />
            }
            trigger="click"
            // trigger="focus"
          >
            <Button
              style={{ borderStyle: "none" }}
              // onClick={e => document.execCommand("italic", false, null)}
            >
              <VideoCameraOutlined
                style={{ fontSize: "15px", fontWeight: "bold" }}
              />
            </Button>
            &nbsp;&nbsp;{" "}
            {selectedVideo ? (
              <Tooltip title={selectedVideo.name}>
                <PaperClipOutlined />
                &nbsp;
                {selectedVideo.title.substring(0, 30)}...
              </Tooltip>
            ) : (
              ""
            )}
          </Popover>
          {/* <Button
            onClick={e => console.log(bodyRef.current.innerHTML)}
            style={{ borderStyle: "none" }}
          >
            submit
          </Button> */}
        </div>
      </div>
      <Divider style={{ margin: "0px 0px" }} />
      <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
        <Select
          onKeyDown={(value) => {
            // console.log(value, "kwjbfkwjef");
            // setSelectedTrends(
            //   value.replace(/[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/, "")
            // )
          }}
          bordered={false}
          style={{ width: "100%" }}
          allowClear
          className="no-border"
          mode="tags"
          placeholder="Select Tags"
          onChange={(value) => {
            console.log(value, "kwjbfkwjef");
            setSelectedTrends(value);
          }}
        >
          {allTrends.data
            ? allTrends.data.trends.map((item, index) => {
                // setAllTrendIds([...allTrendIds, item.id]);
                return (
                  <Option key={index} value={item.id ? item.id : item.name}>
                    #{item.name}
                  </Option>
                );
              })
            : ""}
        </Select>
        <Select
          bordered={false}
          onChange={(value) => setSelectedTier(value)}
          placeholder="Select Tier"
          style={{ width: "40%", marginLeft: "10px" }}
        >
          {userTiers.data
            ? userTiers.data.get_my_tiers.map((item, index) => {
                return (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                );
              })
            : ""}
        </Select>
        &nbsp;
        <Button
          loading={
            createPostData.loading || createPostDataNew.loading ? true : false
          }
          type="primary"
          shape="round"
          // icon="form"
          size="large"
          onClick={(e) => {
            if (title !== "" && selectedTier !== "") {
              submitPost();
            }
            // e.preventDefault();
            // // console.log(inputRef.current.input.value, "kjbdkwed");
            // setTitle(inputRef.current.input.value);
          }}
          style={{
            display: selectedTier !== "" ? "block" : "none",
          }}
        >
          Post
        </Button>
      </div>
    </div>
  );
};

export default CreatePostComponent;
