import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import axios from 'axios';
import ioClient from 'socket.io-client';
import { BankContext } from './Context';
import {
  GX_API_ENDPOINT,
  NEW_CHAT_API,
  NEW_CHAT_SOCKET,
} from '../config/index';
import { useAppContextDetails } from './AppContext';

export const ChatsIoContext = createContext();
function ChatsIoContextProvider({ children }) {
  const { email, token, chatOn, setChatOn } = useContext(BankContext);
  const [optionType, setOptionType] = useState('action');
  const [page, setPage] = useState('');

  // mj
  const [appId, setAppId] = useState('');
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [allUsers, setAllUsers] = useState();
  const [recentList, setRecentList] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [currentTreadId, setCurrentTreadId] = useState();
  const [messageArray, setMessageArray] = useState();
  const [isUserTyping, setIsUserTyping] = useState(false);
  const { appCode } = useAppContextDetails();

  const userObjectRef = useRef();
  const socketRef = useRef();
  const userSocketRef = useRef();

  useEffect(() => {
    email &&
      token &&
      (async () => {
        axios
          .post(
            `${NEW_CHAT_API}/get_application`,
            { code: appCode },
            { headers: { email, token } }
          )
          .then(({ data }) => {
            // console.log('Data', data);
            setAppId(data?.payload?.id);
          })
          .catch((error) => {
            console.log('Error on getting application', error);
          });

        socketRef.current = ioClient(NEW_CHAT_SOCKET, {
          reconnection: false,
          query: {
            email,
            token,
            tokfortest: 'nvestisgx',
          },
        });
        userSocketRef.current = ioClient(`${NEW_CHAT_SOCKET}/user`, {
          reconnection: false,
          query: {
            email,
            token,
            tokfortest: 'nvestisgx',
          },
        });

        socketRef.current.on('connect', () => {
          setIsSocketConnected(true);
          // console.log('socket Connected.');
        });

        userSocketRef.current.on('connect', () => {
          setIsSocketConnected(true);
          // console.log('userSocket Connected.');
        });

        socketRef.current.on('connect_error', (err) => {
          console.log('Socket Connection Error', err);
        });
      })();
  }, [email, token]);

  useEffect(() => {
    if (appId && email && token) {
      getUserDetails();
    }
  }, [appId, email, token]);

  useEffect(() => {
    if (selectedUser) {
      getConversations();
    } else {
      setMessageArray();
      setCurrentTreadId();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (recentList && selectedUser && !selectedUser.thread_id) {
      const user = recentList.find((x) => x.username === selectedUser.username);

      if (user?.thread_id) {
        setCurrentTreadId(user.thread_id);
      }
    }
  }, [selectedUser, currentTreadId, recentList]);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on('someone_typing', (typingUser, treadId) => {
        setIsUserTyping(
          typingUser === selectedUser?.username && treadId === currentTreadId
        );

        setTimeout(() => {
          setIsUserTyping(false);
        }, 2000);
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.off('someone_typing');
      }
    };
  }, [currentTreadId, selectedUser]);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on('msg_notify', (data) => {
        if (data.thread_id === currentTreadId) {
          if (messageArray[messageArray.length - 1] !== data) {
            setMessageArray([...messageArray, data]);
          }
        }
      });
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.off('msg_notify');
      }
    };
  }, [currentTreadId, messageArray]);

  const getUserDetails = async () => {
    const postData = { email, app_id: appId };
    const headers = { email, token };

    axios
      .post(`${NEW_CHAT_API}/get_user`, postData, { headers })
      .then(({ data }) => {
        // console.log('User Data', data);

        if (data.status) {
          userObjectRef.current = data?.payload || '';
          getDataFromSocket();
        } else {
          registerUserInChat();
        }
      })
      .catch((error) => {
        console.log('Error on getting user details', error);
      });
  };

  const registerUserInChat = async () => {
    axios
      .get(`${GX_API_ENDPOINT}/user/details/get`, {
        params: { email },
      })
      .then((userResp) => {
        const registerData = {
          first_name:
            userResp.data?.user?.first_name || userResp.data?.user?.name,
          last_name: userResp.data?.user?.last_name || ' ',
          username: userResp.data?.user?.username,
          bio: userResp.data?.user?.bio || 'None',
          email,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          avatar: userResp.data?.user?.profile_img || ' ',
        };
        // console.log('registerData', registerData);
        axios
          .post(`${NEW_CHAT_API}/register_with_chatsio`, registerData)
          .then(({ data }) => {
            // console.log('Data', data);
            const postData = { email, app_id: appId };
            axios.post(`${NEW_CHAT_API}/register_user`, postData).then(() => {
              getUserDetails();
            });
          })
          .catch((error) => {
            console.log('Error on registering user', error);
          });
      })
      .catch((error) => {
        console.log('Error on getting user details', error);
      });
  };

  const getDataFromSocket = () => {
    if (socketRef.current) {
      socketRef.current.emit('get_all_user_list', appId, (response) => {
        const users = response || [];
        setAllUsers(
          users.filter(
            (user) => user.username !== userObjectRef.current.username
          )
        );
      });
      socketRef.current.emit(
        'get_user_interaction_list_read_unread_list',
        userObjectRef.current,
        appId,
        (response) => {
          // console.log('get_user_interaction_list_read_unread_list', response);

          const recentUsers = response?.interacting_list || [];

          setRecentList(
            recentUsers.filter(
              (user) => user.username !== userObjectRef.current.username
            )
          );
        }
      );
    }
  };

  const getConversations = () => {
    if (socketRef.current) {
      socketRef.current.emit(
        'check_user_interaction_list',
        userObjectRef.current,
        selectedUser.username,
        appId,
        (response) => {
          // console.log('Messages', response);
          if (Array.isArray(response)) {
            setMessageArray(response.reverse());
          } else {
            setCurrentTreadId(response);
            setMessageArray([]);
          }
        }
      );
    }
  };

  const sendMessage = (message) => {
    const messageData = {
      message,
      thread_id: currentTreadId,
      sender: userObjectRef.current,
      timestamp: Date.now(),
    };

    // console.log('messageData', messageData);

    if (socketRef.current) {
      socketRef.current.emit('new_message', messageData, appId, (response) => {
        // console.log('Response', response);

        if (response === 'success') {
          setMessageArray([...messageArray, messageData]);
        }
      });
    }
  };

  const notifyTyping = () => {
    if (socketRef.current) {
      socketRef.current.emit(
        'typing',
        userObjectRef.current.username,
        currentTreadId
      );
    }
  };

  return (
    <ChatsIoContext.Provider
      value={{
        chatOn,
        setChatOn,
        optionType,
        setOptionType,
        page,
        setPage,

        // mj
        chatSocket: socketRef.current,
        userSocket: userSocketRef.current,
        userObject: userObjectRef.current,
        appId,
        allUsers,
        isSocketConnected,
        selectedUser,
        setSelectedUser,
        messageArray,
        sendMessage,
        recentList,
        setCurrentTreadId,
        currentTreadId,
        notifyTyping,
        isUserTyping,
      }}
    >
      {children}
    </ChatsIoContext.Provider>
  );
}

export default ChatsIoContextProvider;
