import connectIcon from "../images/dropdown/connect.svg";
import ideasIcon from "../images/dropdown/ideas.svg";
import lockIcon from "../images/dropdown/lock.svg";
import portfolioIcon from "../images/dropdown/portfolio.svg";
import saveIcon from "../images/dropdown/save.svg";
import settingsIcon from "../images/dropdown/settings.svg";
import supportIcon from "../images/dropdown/support.svg";
import vaultsIcon from "../images/dropdown/vaults.svg";

export const dropdowntop = [
  { name: "Portfolio", icon: portfolioIcon },
  { name: "Vaults", icon: vaultsIcon },
  { name: "Saved", icon: saveIcon },
  { name: "Ideas", icon: ideasIcon },
  { name: "Connect", icon: connectIcon },
];

export const dropdownBottom = [
  { name: "Support", icon: supportIcon },
  { name: "Settings", icon: settingsIcon },
  { name: "Logout", icon: lockIcon },
];
