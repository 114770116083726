import { Empty, Spin } from "antd";
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { MyContext } from "../../Context";
import LoggedoutPostComponent from "../LoggedoutPostComponent";
import PostComponent from "../PostComponent";

const Posts = () => {
  const {
    myPosts,
    userPosts,
    myLikedPosts,
    userEmail,
    userData,
    setUserData,
    userPostData,
    setUserPostData,
    tokenValid,
  } = useContext(MyContext);

  const showUserPosts = () => {
    if (userPosts.data) {
      if (userPosts.data.get_user_posts.length > 0) {
        return userPosts.data.get_user_posts
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => {
            return (
              <PostComponent item={item} index={index} userObj={userData} />
            );
          });
      } else {
        return (
          <div style={{ padding: "20px" }}>
            <Empty />
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Spin />
        </div>
      );
    }
  };

  // const showUserPostsLoggedout = () => {
  //   if (userPostData) {
  //     if (userPostData.length > 0) {
  //       return userPostData
  //         .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
  //         .map((item, index) => {
  //           return <PostComponent item={item} index={index} />;
  //         });
  //     } else {
  //       return (
  //         <div style={{ padding: "20px" }}>
  //           <Empty />
  //         </div>
  //       );
  //     }
  //   } else {
  //     return (
  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           height: "200px",
  //         }}
  //       >
  //         <Spin />
  //       </div>
  //     );
  //   }
  // };

  const showUserPostsLoggedout = () => {
    if (userPostData) {
      return userPostData.length > 0 ? (
        userPostData
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => {
            return (
              <PostComponent item={item} index={index} userData={userData} />
            );
          })
      ) : (
        <Empty />
      );
    }
  };

  const showMyLikedPosts = () => {
    if (myLikedPosts.data) {
      if (myLikedPosts.data.get_my_liked_posts.length > 0) {
        return myLikedPosts.data.get_my_liked_posts
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => {
            return <PostComponent item={item} index={index} />;
          });
      } else {
        return (
          <div style={{ padding: "20px" }}>
            <Empty />
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Spin />
        </div>
      );
    }
  };

  const showMyPosts = () => {
    if (myPosts.data) {
      if (myPosts.data.get_my_posts.length > 0) {
        return myPosts.data.get_my_posts
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => {
            return <PostComponent item={item} index={index} />;
          });
      } else {
        return (
          <div style={{ padding: "20px" }}>
            <Empty />
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Spin />
        </div>
      );
    }
  };

  const conditionalPosts = () => {
    if (tokenValid) {
      if (userEmail !== localStorage.getItem("user_account")) {
        return (
          <div
            style={{
              padding: "30px",
              // background: "#ecf0f1"
            }}
          >
            {showUserPosts()}
          </div>
        );
      } else {
        return (
          <div
            style={{
              padding: "30px",
              //  background: "#ecf0f1"
            }}
          >
            {showMyPosts()}
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            padding: "30px",
            // background: "#ecf0f1"
          }}
        >
          {showUserPostsLoggedout()}
        </div>
      );
    }
  };

  return (
    <>
      <Outlet />
      {conditionalPosts()}
    </>
  );
};

export default Posts;
