import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import {
  Route,
  useNavigate,
  Switch,
  HashRouter,
  Link,
  Navigate,
  Routes,
  Outlet,
} from "react-router-dom";

import {
  Layout,
  Input,
  Avatar,
  Spin,
  Popover,
  Divider,
  Tag,
  Col,
  Row,
  Space,
  Modal,
  Button,
  message,
} from "antd";

// https://connectionsapi.globalxchange.io/

import useScrollPercentage from "../components/useScrollPercentage";

import Bookmarks from "./Bookmarks";
import Notifications from "./Notifications";
import Home from "./Home";
import Profile from "./Profile";
import ProfileLoggedout from "./ProfileLoggedout";
import Posta from "./Post";

import Register from "./Register";
import { MyContext } from "../Context";
import prof from "../img/user.svg";
import VideoPage from "./VideoPage";
import SingleVideo from "./SingleVideo";
import Login from "./Login";
import { renderIntoDocument } from "react-dom/test-utils";
import Nav from "../components/Nav";

import { ReactComponent as Home_img } from "../img/nav-icons/Home.svg";
import { ReactComponent as Saved_img } from "../img/nav-icons/Saved.svg";
import { ReactComponent as Chats_img } from "../img/nav-icons/Chats.svg";
import { ReactComponent as Conn_img } from "../img/nav-icons/Connections.svg";
import { ReactComponent as Profile_img } from "../img/nav-icons/Profile.svg";
import { ReactComponent as Subs_img } from "../img/nav-icons/Subscriptions.svg";
import { ReactComponent as Vaults_img } from "../img/nav-icons/Vaults.svg";
import { ReactComponent as Drops_img } from "../img/nav-icons/Drops.svg";

import {
  ArrowRightOutlined,
  BellOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import MenuSidebar from "../components/MenuSidebar";
import LoginMobile from "./LoginMobile";
import Streams from "../components/profileSections/Streams";
import Gallery from "../components/profileSections/Gallery";
import Posts from "../components/profileSections/Posts";
import Memories from "../components/profileSections/Memories";
import Discussions from "../components/profileSections/Discussions";
import Interactions from "../components/profileSections/Interactions";
import Creator from "../components/profileTabs/Creator";
import Collector from "../components/profileTabs/Collector";
import AlreadyRegistered from "./AlreadyRegistered";
// import NewRegistration from "../pages/NewRegistration";

import bgImg from "../img/cover.jpg";
import Axios from "axios";
import NotFound from "./NotFound";
import LoggedoutHome from "./LoggedoutHome";
import LoggedOutPost from "./LoggedOutPost";
import LoggedOutVideo from "./LoggedOutVideo";
import LandingPage from "./LandingPage";
import Brain from "./Brain";
import NavMobile from "../components/NavMobile";
import Toc from "./Toc";
import PrivacyPolicy from "./PrivacyPolicy";
import NewRegistration from "./NewRegistration";
import AppContextProvider from "../context/AppContext";
import StatusBar from "../components/StatusBar";
import Dropdown from "../components/Dropdown";
import MarketsPage from "./MarketsPage";
import { CoinContextProvider } from "../context/CoinContext";
import BankContextProvider from "../context/Context";
import { MarketContextProvider } from "../context/MarketContext";
import ChatContextProvider from "../context/ChatContext";
import ChatsIoContextProvider from "../context/ChatsIoContext";
import InvestmentVaultContextProvider from "../context/InvestmentVaultContext";
import NetWorthContextProvider from "../context/NetWorthContext";
import VaultContextProvider from "../context/VaultContext";
import VaultPageContextProvider from "../context/VaultPageContext";
import PortfolioContextProvider from "../context/PortfolioContext";
import PlanBContextProvider from "../context/PlanBContext";
import MarketPage from "./Market";
import { VaultDetailedSection } from "../components/vaults/PrivateEquity";
import InstitutePage from "./InstitutePage";
import NetWorthPage from "./NetWorthPage";
import Videos from "../components/profileSections/Videos";
import Signals from "../components/profileSections/Signals";
import Background from "../components/profileSections/Background";

const { Header, Content } = Layout;
const { Search } = Input;

const GET_USER = gql`
  query ($email: ID!, $uplineAppcode: String) {
    get_user(data: { email: $email, upline_appcode: $uplineAppcode }) {
      id
      username
      name
      bio
      email
      profile_image
      cover_image
      user_type_id
    }
  }
`;

const GET_MY_SUBSCRIPTIONS = gql`
  query getSubscriptions($user_id: ID!) {
    get_all_my_subscriptions(data: { user_id: $user_id }) {
      whom {
        id
        email
        profile_image
        name
        username
      }
      tier {
        id
        name
      }
    }
  }
`;

const USER_INFO = gql`
  query getUser($user_id: ID, $uplineAppcode: String) {
    get_user(data: { user_id: $user_id, upline_appcode: $uplineAppcode }) {
      id
      name
      username
      email
      bio
      date_of_birth
      profile_image
      cover_image
      following_count
      my_follower_count
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
      long_bio
      user_type_id
      city
      achievements {
        id
        title
        description
        endorsed_users
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updtUsr(
    $user_id: ID!
    $email: String!
    $name: String
    $bio: String
    $long_bio: String
    $dob: String
    $profile: String
    $cover: String
    $video_id: ID
    $thumbnail: String
    $video_title: String
    $video_subtitle: String
    $video_description: String
  ) {
    update_user(
      data: {
        user_id: $user_id
        email: $email
        name: $name
        bio: $bio
        long_bio: $long_bio
        date_of_birth: $dob
        profile_image: $profile
        cover_image: $cover
        video_id: $video_id
        thumbnail: $thumbnail
        video_title: $video_title
        video_subtitle: $video_subtitle
        video_description: $video_description
      }
    ) {
      id
      name
      username
      bio
      profile_image
      cover_image
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

// const GET_TIERS = gql`
//   query getT($user_id: ID!) {
//     get_my_tiers(data: { user_id: $user_id }) {
//       id
//       name
//       description
//       subscribed_user_count
//       price
//     }
//   }
// `;

const MY_POSTS = gql`
  query ($user_id: ID!) {
    get_my_posts(data: { user_id: $user_id }) {
      id
      title
      body
      timestamp
      like_count
      post_tier {
        id
        name
      }
      comments {
        text
      }
      author {
        id
        name
        username
        profile_image
        email
      }
      comment_count
      liked_users {
        id
        username
      }
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const NEWS_FEED = gql`
  query ($user_id: ID!) {
    get_my_home_posts(data: { user_id: $user_id }) {
      id
      title
      body
      locked
      post_tier {
        id
        name
      }
      timestamp
      author {
        name
        username
        id
        profile_image
        email
      }
      comments {
        text
        author {
          id
          name
          username
          profile_image
          email
        }
      }
      liked_users {
        id
        email
        username
      }
      comment_count
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const USER_POSTS = gql`
  query getPost(
    $current_user_id: ID!
    $requested_user_id: ID!
    $current_user_email: ID!
    $requested_user_email: ID!
  ) {
    get_user_posts(
      data: {
        current_user_id: $current_user_id
        requested_user_id: $requested_user_id
        current_user_email: $current_user_email
        requested_user_email: $requested_user_email
      }
    ) {
      id
      title
      body
      like_count
      locked
      timestamp
      post_tier {
        id
        name
      }
      author {
        id
        username
        profile_image
        email
      }
      liked_users {
        id
        username
        bio
      }
      comment_count
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const MY_LIKED_POSTS = gql`
  query mlp($email: ID!) {
    get_my_liked_posts(data: { email: $email }) {
      id
      title
      body
      like_count
      comment_count
      locked
      timestamp
      post_tier {
        id
        name
      }
      author {
        id
        username
        profile_image
        email
      }
      liked_users {
        id
        username
        bio
      }
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const GET_SUBSCRIBED_TIERS = gql`
  query getUser($user_id: ID!, $to_be_subscribed_user_id: ID!) {
    get_subscribed_tier_list(
      data: {
        user_id: $user_id
        to_be_subscribed_user_id: $to_be_subscribed_user_id
      }
    ) {
      tier_id
    }
  }
`;

const POSTS_OF_TREND = gql`
  query myTrend($id: ID!, $user_id: ID!) {
    user_choosen_trend(data: { trend_id: $id, user_id: $user_id }) {
      id
      title
      body
      locked
      post_tier {
        id
        name
      }
      timestamp
      author {
        username
        name
        id
        profile_image
        email
      }
      comments {
        text
        author {
          id
          username
          profile_image
          email
        }
      }
      liked_users {
        id
        email
        username
      }
      comment_count
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const MY_SAVED_POSTS = gql`
  query ($user_id: ID!, $uplineAppcode: String) {
    get_my_bookmarked_posts(
      data: { user_id: $user_id, upline_appcode: $uplineAppcode }
    ) {
      id
      title
      body
      timestamp
      like_count
      post_tier {
        id
        name
      }
      comments {
        text
      }
      author {
        id
        name
        username
        profile_image
        email
      }
      comment_count
      liked_users {
        id
        username
      }
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const Middleware = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [insiderReg, setInsiderReg] = useState(false);
  const [register, setRegister] = useState("");
  const [login, setLogin] = useState(false);
  // const [collapsed, setCollapsed] = useState(true);
  const [nav, setNav] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [refetchPosts, setRefetchPosts] = useState(false);
  const [title, setTitle] = useState("");
  const [query, setQuery] = useState("");
  const [trendClicked, setTrendClicked] = useState(false);
  const [selectedTrend, setSelectedTrend] = useState("");
  const [scrollRef, scrollPercentage] = useScrollPercentage();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [viewSubscribersModal, setViewSubscribersModal] = useState(false);
  const [viewSubscribtionModal, setViewSubscribtionModal] = useState(false);
  const [streamingUrl, setStreamingUrl] = useState(
    "https://vod-backend.globalxchange.io/"
    // "http://localhost:4197/"
  );
  const [appWidth, setAppWidth] = useState(0);

  const [coverImage, setCoverImage] = useState(bgImg);
  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [coverImageS3url, setCoverImageS3url] = useState("");
  const [profileImageS3url, setProfileImageS3url] = useState("");
  const [uname, setUname] = useState("");
  const [profileUsername, setProfileUsername] = useState("");
  const [ubio, setUbio] = useState("");
  const [udob, setUdob] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCity, setUserCity] = useState("");

  const [tokenValid, setTokenValid] = useState(null);

  const [userData, setUserData] = useState({});
  const [userPostData, setUserPostData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [showUnameChangeModal, setShowUnameChangeModal] = useState(false);

  const [allPosts, setAllPosts] = useState([]);
  const [allUserData, setAllUserData] = useState([]);
  const [allPostTrends, setAllPostTrends] = useState(null);

  const [loadingProfile, setLoadingProfile] = useState(null);
  const [creatorView, setCreatorView] = useState(null);

  const [showMenu, setShowMenu] = useState(false);
  const [showTrend, setShowTrend] = useState(false);

  const [navSearch, setNavSearch] = useState(false);

  const [showCreatePost, setShowCreatePost] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (tokenValid === false) {
      Axios.get(
        // `https://connectionsapi.globalxchange.io/all_user_posts`
        `https://connectionsapi.globalxchange.io/all_user_posts?upline_appcode=indianinvestor`
      ).then((res) => {
        if (res.data.status) {
          console.log(res.data, "kwebdkwjbed");
          setAllPosts(res.data.payload.posts_data);
          setAllUserData(res.data.payload.user_data);
          setAllPostTrends(res.data.payload.trends);
        }
      });
    }
  }, [tokenValid]);

  const [username, setUsername] = useState("");
  const [updateUser, updateUserData] = useMutation(UPDATE_USER);

  const getUser = useQuery(GET_USER, {
    variables: {
      email: localStorage.getItem("user_account"),
      uplineAppcode: "indianinvestor",
      //   email: "chiranjib@gmail.com"
    },
  });

  var userInfo = useQuery(USER_INFO, {
    variables: {
      user_id: localStorage.getItem("selected_user_id"),
      uplineAppcode: "indianinvestor",
    },
  });

  var mySavedPosts = useQuery(MY_SAVED_POSTS, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
      uplineAppcode: "indianinvestor",
    },
  });

  const newsFeed = useQuery(NEWS_FEED, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
      // uplineAppcode: "indianinvestor",
      // email: localStorage.getItem("user_account"),
    },
  });

  // const allTrends = useQuery(ALL_TRENDS);
  // const getAllUsers = useQuery(GET_ALL_USERS);
  var userPosts = useQuery(USER_POSTS, {
    variables: {
      current_user_id: localStorage.getItem("pulse_user_id"),
      requested_user_id: localStorage.getItem("selected_user_id"),
      current_user_email: localStorage.getItem("user_account"),
      requested_user_email: localStorage.getItem("selected_user_email"),
    },
    skip:
      localStorage.getItem("selected_user_email") ===
      localStorage.getItem("user_account"),
  });

  const getSubscribtions = useQuery(GET_MY_SUBSCRIPTIONS, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
    },
  });

  var myLikedPosts = useQuery(MY_LIKED_POSTS, {
    variables: {
      email: localStorage.getItem("user_account"),
    },
  });
  var myPosts = useQuery(MY_POSTS, {
    variables: {
      user_id: localStorage.getItem("selected_user_id"),
      // email: localStorage.getItem("user_account"),
    },
    skip: userEmail
      ? userEmail !== localStorage.getItem("user_account")
      : localStorage.getItem("user_profile_email") !==
        localStorage.getItem("user_account"),
  });

  const [getPostsOfTrend, resTrendPosts] = useLazyQuery(POSTS_OF_TREND);

  useEffect(() => {
    if (localStorage.getItem("user_account") && localStorage.getItem("token")) {
      Axios.post(`https://comms.globalxchange.io/coin/verifyToken`, {
        email: localStorage.getItem("user_account"),
        token: localStorage.getItem("token"),
      }).then((res) => {
        if (res.data.status) {
          setTokenValid(true);
        } else {
          setTokenValid(false);
        }
      });
    } else {
      setTokenValid(false);
    }
  }, []);

  const [editable, setEditable] = useState(false);
  const value = {
    step,
    setStep,
    insiderReg,
    setInsiderReg,
    nav,
    setNav,
    login,
    setLogin,
    allCategories,
    setAllCategories,
    register,
    setRegister,
    title,
    setTitle,
    refetchPosts,
    setRefetchPosts,
    trendClicked,
    setTrendClicked,
    selectedTrend,
    setSelectedTrend,
    scrollRef,
    scrollPercentage,
    viewSubscribersModal,
    setViewSubscribersModal,
    viewSubscribtionModal,
    setViewSubscribtionModal,
    streamingUrl,
    setStreamingUrl,
    appWidth,
    editable,
    setEditable,
    userInfo,
    coverImage,
    setCoverImage,
    coverImageS3url,
    setCoverImageS3url,
    profileImage,
    setProfileImage,
    profileImageS3url,
    setProfileImageS3url,
    loading,
    setLoading,
    uname,
    setUname,
    profileUsername,
    setProfileUsername,
    ubio,
    setUbio,
    bgImg,
    prof,
    udob,
    setUdob,
    myPosts,
    userPosts,
    myLikedPosts,
    userEmail,
    setUserEmail,
    tokenValid,
    userData,
    setUserData,
    userPostData,
    setUserPostData,
    selectedUserId,
    setSelectedUserId,
    newsFeed,
    getPostsOfTrend,
    resTrendPosts,
    mySavedPosts,
    getSubscribtions,
    userCity,
    setUserCity,
    allPosts,
    allUserData,
    allPostTrends,
    loadingProfile,
    setLoadingProfile,
    creatorView,
    setCreatorView,
    showMenu,
    setShowMenu,
    showTrend,
    setShowTrend,
    navSearch,
    setNavSearch,
    showCreatePost,
    setShowCreatePost,
    showDropdown,
    setShowDropdown,
  };

  // let filteredUsers = getAllUsers.data
  //   ? getAllUsers.data.get_all_users.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return (item.name + item.username).toLowerCase().indexOf(lowquery) >= 0;
  //     })
  //   : "";

  // let filteredTrends = allTrends.data
  //   ? allTrends.data.trends.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return item.name.toLowerCase().indexOf(lowquery) >= 0;
  //     })
  //   : "";

  useEffect(() => {
    // console.log(
    //   window.location.pathname.replace(/^\/([^\/]*).*$/, "$1"),
    //   "kwjbedkjwbe"
    // );
    if (tokenValid === false) {
      setLoadingProfile(true);
      Axios.get(
        `https://connectionsapi.globalxchange.io/user_public_data?username=${window.location.pathname.replace(
          /^\/([^\/]*).*$/,
          "$1"
        )}`
      ).then((res) => {
        if (res.data.user_data) {
          setLoadingProfile(false);
          console.log(res.data.posts_data, res.data.user_data, "lwjbcdkjwed");
          setUserData(res.data.user_data);
          setUserPostData([...res.data.posts_data]);

          localStorage.setItem("selected_user_id", res.data.user_data.id);
          localStorage.setItem("selected_user_email", res.data.user_data.email);
          setUserEmail(res.data.user_data.email);
          setSelectedUserId(res.data.user_data.id);
        }
      });
    }
  }, [window.location.pathname, tokenValid]);

  useEffect(() => {
    console.log("userdata", getUser.data);
    if (getUser.data) {
      if (getUser.data.get_user !== null) {
        setRegister("Success");
        localStorage.setItem("pulse_user_id", getUser?.data?.get_user?.id);
        localStorage.setItem(
          "actual_username",
          getUser.data?.get_user?.username
        );
      } else {
        setRegister("Failed");
      }
    } else {
      setRegister("not");
    }
  }, [getUser.data]);

  useEffect(() => {
    if (newsFeed.data) {
      console.log(newsFeed.data, "newsFeed.data");
      // setAllPosts(newsFeed.data.news_feed);
      // setAllUserData(newsFeed.data.user_data);
      // setAllPostTrends(newsFeed.data.trends);
    }
  }, [newsFeed.data]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setAppWidth(width);
  };

  useEffect(() => {}, [login]);

  useEffect(() => {
    var regex = /^[A-Za-z0-9]+$/;
    if (localStorage.getItem("actual_username")) {
      if (!regex.test(localStorage.getItem("actual_username"))) {
        console.log(
          localStorage.getItem("actual_username"),
          regex.test(localStorage.getItem("actual_username")),
          "kjdkjwekwjhed"
        );
        setShowUnameChangeModal(true);
      } else {
        setShowUnameChangeModal(false);
      }
    }
  }, [localStorage.getItem("actual_username")]);

  const conditionalNav = () => {
    if (window.location.href.split("/").pop() === "login") {
      return "";
    } else if (window.location.href.split("/").pop() === "preregistered") {
      return "";
    } else if (window.location.href.split("/").pop() === "affiliate") {
      return "";
    } else if (window.location.href.split("/").pop() === "getstarted") {
      return "";
    } else {
      return (
        <>
          <Nav />
          <StatusBar />
        </>
      );
    }
  };

  const conditionalView = () => {
    if (tokenValid !== null) {
      if (tokenValid) {
        return (
          <>
            <Layout
              className="scrollRef"
              style={{
                background: "white",
                height: "100%",
              }}
            >
              <Content
                style={{
                  height: "100%",
                  // minHeight: "98vh",
                  // backgroundColor: "#E9EBEE",
                }}
              >
                <MyContext.Provider value={value}>
                  <AppContextProvider>
                    <CoinContextProvider>
                      <BankContextProvider>
                        <MarketContextProvider>
                          <ChatContextProvider>
                            <ChatsIoContextProvider>
                              <InvestmentVaultContextProvider>
                                <NetWorthContextProvider>
                                  <VaultContextProvider>
                                    <VaultPageContextProvider>
                                      <PortfolioContextProvider>
                                        <PlanBContextProvider>
                                          {window.innerWidth > 900 ? (
                                            conditionalNav()
                                          ) : (
                                            <NavMobile />
                                          )}
                                          <Dropdown />
                                          <Row
                                            style={{
                                              height: "calc(100% - 105px)",
                                            }}
                                          >
                                            {window.innerWidth > 900 &&
                                            !showMenu ? (
                                              <MenuSidebar />
                                            ) : (
                                              ""
                                            )}

                                            <Routes>
                                              <Route
                                                path="/"
                                                element={
                                                  <Navigate
                                                    to="/home"
                                                    replace
                                                  />
                                                }
                                              />

                                              <Route
                                                path="/home"
                                                element={<Home />}
                                              />
                                              <Route
                                                path="/saved"
                                                element={<Bookmarks />}
                                              />
                                              <Route
                                                path="/brain"
                                                element={<Brain />}
                                              />
                                              <Route
                                                path="/posts/:id"
                                                element={<Posta />}
                                              />
                                              <Route
                                                path="/videos/:id"
                                                element={<SingleVideo />}
                                              />
                                              <Route
                                                path="/tos"
                                                element={<Toc />}
                                              />
                                              <Route
                                                path="/privacy"
                                                element={<PrivacyPolicy />}
                                              />
                                              {/* <Route path="/post" component={<Post />}>
                      <Route path="/:id" component={<Post />} />
                    </Route> */}
                                              {/* <Route index element={<Home />} /> */}
                                              <Route
                                                exact
                                                path="/listings/:assetClassName"
                                                element={<MarketsPage />}
                                              />
                                              <Route
                                                exact
                                                path="/listings/:assetClassName/:coin"
                                                element={<MarketsPage />}
                                              />
                                              <Route
                                                exact
                                                path="/listings"
                                                element={<MarketsPage />}
                                                // element={<div>market</div>}
                                              />
                                              <Route
                                                exact
                                                path="/vaults"
                                                element={
                                                  <VaultDetailedSection />
                                                }
                                                // element={<div>market</div>}
                                              />

                                              <Route
                                                exact
                                                path="/institute"
                                                element={<InstitutePage />}
                                                // element={<div>market</div>}
                                              />

                                              <Route
                                                exact
                                                path="/portfolio"
                                                element={<NetWorthPage />}
                                                // element={<div>market</div>}
                                              />

                                              <Route
                                                exact
                                                path={`/:id`}
                                                // path="profile"
                                                element={<Profile />}
                                              >
                                                <Route
                                                  path="posts"
                                                  element={<Posts />}
                                                >
                                                  {/* <Route path=":id" component={<Post />} /> */}
                                                </Route>

                                                {/* <Route path="post/:id" component={<Post />} /> */}
                                                <Route
                                                  path="streams"
                                                  element={<Streams />}
                                                />
                                                <Route
                                                  path="gallery"
                                                  element={<Gallery />}
                                                />
                                                <Route
                                                  path="memories"
                                                  element={<Memories />}
                                                />
                                                <Route
                                                  path="discussions"
                                                  element={<Discussions />}
                                                />
                                                <Route
                                                  path="interactions"
                                                  element={<Interactions />}
                                                />
                                                <Route
                                                  path="videos"
                                                  element={<Videos />}
                                                />
                                                <Route
                                                  path="signals"
                                                  element={<Signals />}
                                                />
                                                <Route
                                                  path="background"
                                                  element={<Background />}
                                                />
                                                {/* <Route path="creator" element={<Creator />} />
                      <Route path="collector" element={<Collector />} /> */}
                                                <Route
                                                  index
                                                  element={<Posts />}
                                                />
                                              </Route>
                                              {/* <Route element={<NotFound />} path="*" /> */}
                                            </Routes>
                                          </Row>
                                        </PlanBContextProvider>
                                      </PortfolioContextProvider>
                                    </VaultPageContextProvider>
                                  </VaultContextProvider>
                                </NetWorthContextProvider>
                              </InvestmentVaultContextProvider>
                            </ChatsIoContextProvider>
                          </ChatContextProvider>
                        </MarketContextProvider>
                      </BankContextProvider>
                    </CoinContextProvider>
                  </AppContextProvider>
                </MyContext.Provider>
              </Content>
            </Layout>
          </>
        );
      } else {
        return (
          <div
            style={{
              height: "100%",
            }}
          >
            <>
              <MyContext.Provider value={value}>
                <BankContextProvider>
                  {conditionalNav()}
                  <Dropdown />
                  <Routes>
                    <Route path="/tos" element={<Toc />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/" element={<Navigate to="/home" replace />} />
                    {/* <Route path="/" element={<LandingPage />} /> */}
                    <Route
                      path="/login"
                      element={
                        window.innerWidth > 900 ? <Login /> : <LoginMobile />
                      }
                    />
                    {/* <Route path="/home" element={<Navigate to="/" replace />} /> */}
                    <Route path="/home" element={<LoggedoutHome />} />
                    <Route path="/posts/:id" element={<LoggedOutPost />} />
                    <Route path="/videos/:id" element={<LoggedOutVideo />} />
                    <Route path="/getstarted" element={<NewRegistration />} />
                    <Route
                      path="/getstarted/preregistered"
                      element={<AlreadyRegistered />}
                    />
                    <Route
                      path="/:id"
                      // path="profile"
                      element={<ProfileLoggedout />}
                    >
                      <Route path="posts" element={<Posts />} />
                      <Route path="streams" element={<Streams />} />
                      <Route path="gallery" element={<Gallery />} />
                      <Route path="memories" element={<Memories />} />
                      <Route path="discussions" element={<Discussions />} />
                      <Route path="interactions" element={<Interactions />} />

                      <Route index element={<Posts />} />
                    </Route>
                    <Route element={<NotFound />} path="*" />
                    {/* <Route index element={<Home />} /> */}
                  </Routes>
                </BankContextProvider>
              </MyContext.Provider>
            </>
          </div>
        );
      }
    } else {
      return (
        <div className="full-loading-wrapper">
          <img
            src={require("../img/insiderLogo.svg")}
            alt=""
            className="full-loading-logo"
            style={{ width: "50%" }}
          />
        </div>
      );
    }
  };

  const handleUnameChange = () => {
    Axios.patch(
      `https://connectionsapi.globalxchange.io/update_username`,
      {
        user_id: localStorage.getItem("pulse_user_id"),
        username,
      },
      {
        headers: {
          email: localStorage.getItem("user_account"),
          token: localStorage.getItem("token"),
        },
      }
    ).then((res) => {
      console.log(res.data, username);
      if (res.data.status) {
        localStorage.setItem("actual_username", username);
        setShowUnameChangeModal(false);
        message.success("Username Updated Successfully", 3);
        window.location.reload();
      } else {
        message.error(res.data.message);
      }
    });

    // updateUser({
    //   variables: {
    //     user_id: localStorage.getItem("pulse_user_id"),
    //     email: localStorage.getItem("user_account"),
    //     name: userInfo.data ? userInfo.data.get_user.name : undefined,
    //     bio: userInfo.data ? userInfo.data.get_user.long_bio : undefined,
    //     dob: userInfo.data ? userInfo.data.get_user.dob : undefined,
    //     profile: userInfo.data
    //       ? userInfo.data.get_user.profile_image
    //       : undefined,
    //     cover: userInfo.data ? userInfo.data.get_user.cover_image : undefined,
    //   },
    // });
  };

  return (
    <>
      <div
        style={{
          overflowY: "hidden",
          height: "100vh",
        }}
        onClick={() => {
          if (navSearch) {
            setNavSearch(false);
          }
        }}
      >
        {conditionalView()}
      </div>
      <Modal
        closable={false}
        title="Please change your username."
        visible={showUnameChangeModal}
        // onOk={handleOk}
        // onCancel={e -=> }
        footer={null}
      >
        <Input
          style={{ padding: "0px 30px" }}
          className="login-input"
          placeholder="Type New Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {/* <Button
          loading={loading ? true : false}
          // type="submit"
          type="danger"
          className="login-button"
          onClick={(e) => setShowUnameChangeModal(false)}
        >
          Cancel
        </Button> */}
        <br /> <br />
        <Button
          loading={loading ? true : false}
          // type="submit"
          type="primary"
          className="login-button"
          style={{ height: "40px" }}
          onClick={handleUnameChange}
        >
          Submit
        </Button>
      </Modal>
    </>
  );
};

export default Middleware;
