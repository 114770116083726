import React, { useEffect } from "react";
import styles from "./steps.module.scss";
import insiderIcon from "../../img/insiderIconColor.svg";
import checkRounded from "../../img/checkRounded.svg";

import { CaretDownFilled } from "@ant-design/icons";
import stateNames from "./StateNames.json";
import { Select } from "antd";

const Step7 = ({
  setStep,
  state,
  setState,
  city,
  setCity,
  address,
  setAddress,
  address2,
  setAddress2,
  zipCode,
  setZipCode,
  landmark,
  setLandmark,
}) => {
  useEffect(() => {
    console.log("Step 1 mounted", window.location.href.split("/").pop());
  }, []);

  const handleNext = () => {
    if (state && city && address && address2 && zipCode && landmark) {
      setStep(8);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img src={insiderIcon} alt="" />
          <div className={styles.formContainer}>
            <div>
              <h1 className={styles.title}>Your Address</h1>
              <h4 className={styles.subTitle}>
                Where are you currently living?
              </h4>
            </div>
            <div
              className={styles.form}
              style={{ overflowY: "scroll", height: "auto", height: "65vh" }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "35px",
                }}
              >
                <div>
                  <div className={styles.lable}>State</div>
                  <div
                    className={styles.inputWrapper}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Select
                      bordered={false}
                      style={{
                        width: "100%",
                        // marginLeft: "10px",
                        border: "none",
                        fontSize: "2vh",
                        fontWeight: 700,
                        outline: "none",
                      }}
                      showSearch
                      placeholder="Select One"
                      optionFilterProp="children"
                      // onChange={onChange}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={stateNames.map((item, index) => ({
                        label: `${item.name}`,
                        value: item.name,
                      }))}
                      onChange={(value) => setState(value)}
                    />
                    {/* <CaretDownFilled /> */}
                  </div>
                </div>
                <div>
                  <div className={styles.lable}>Town/City</div>
                  <div className={styles.inputWrapper}>
                    <input
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      type="text"
                      className={styles.inputStyle}
                      placeholder="Ex. Hyderabad"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  margin: "6% 0px",
                }}
              >
                <div>
                  <div className={styles.lable}>
                    Flat, House no, Building, Apartment
                  </div>
                  <div className={styles.inputWrapper}>
                    <input
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      type="text"
                      className={styles.inputStyle}
                      placeholder="Address Line 1"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  margin: "6% 0px",
                }}
              >
                <div>
                  <div className={styles.lable}>
                    Area, Street, Sector, Village
                  </div>
                  <div className={styles.inputWrapper}>
                    <input
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                      type="text"
                      className={styles.inputStyle}
                      placeholder="Address Line 2"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "35px",
                }}
              >
                <div>
                  <div className={styles.lable}>Pincode</div>
                  <div className={styles.inputWrapper}>
                    <input
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                      type="number"
                      max="999999"
                      className={styles.inputStyle}
                      placeholder="6 digits"
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.lable}>Landmark</div>
                  <div className={styles.inputWrapper}>
                    <input
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                      type="text"
                      className={styles.inputStyle}
                      placeholder="Ex. Hyderabad"
                    />
                  </div>
                </div>
              </div>
              <button
                className={styles.submitButton}
                style={{
                  marginTop: "7vh",
                  opacity:
                    state && city && address && address2 && zipCode && landmark
                      ? 1
                      : 0.5,
                }}
                onClick={handleNext}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.finishedPage}>
            <div>Who Invited You</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Country Of Residence</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Login Credentials</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Verify Email</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Enter Personal Details</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Verify Phone Number</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.currentPage}>Enter Address</div>
          <div className={styles.otherPage}>Type Of Investor</div>
          <div className={styles.otherPage}>Type of Insider</div>
        </div>
      </div>
    </>
  );
};

export default Step7;
