import React, { useEffect } from "react";
import styles from "./steps.module.scss";
import insiderIcon from "../../img/insiderIconColor.svg";
import checkRounded from "../../img/checkRounded.svg";

const Step9 = ({
  setStep,
  userType,
  setUserType,
  handleInsiderRegistration,
}) => {
  const cards = [
    "Only Investor",
    "Promoter",
    "Analyst",
    "Educator",
    "Investment Banker",
    "Asset Manager",
    "Celebrity/Influencer",
  ];

  const handleNext = (item) => {
    handleInsiderRegistration(item);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img src={insiderIcon} alt="" />
          <div className={styles.formContainer}>
            <div>
              <h1 className={styles.title}>Type Of User?</h1>
              <h4 className={styles.subTitle}>
                How will you be using your Insider account?
              </h4>
            </div>
            <div
              className={styles.form}
              style={{ overflowY: "scroll", height: "70vh" }}
            >
              {cards.map((item) => {
                return (
                  <div
                    className={styles.optionCard}
                    onClick={(e) => {
                      setUserType(item);
                      handleNext(item);
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.finishedPage}>
            <div>Who Invited You</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Country Of Residence</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Login Credentials</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Verify Email</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Enter Personal Details</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Verify Phone Number</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Enter Address</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Type Of Investor</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.currentPage}>Type of Insider</div>
        </div>
      </div>
    </>
  );
};

export default Step9;
