import React, { useContext, useState, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { NetWorthContext } from '../../context/NetWorthContext';
import { BankContext } from '../../context/Context';
import ModalAlertUpdate from './ModalAlertUpdate';
import BondsItem from './BondsItem';
import BondsItemLoading from './BondsItemLoading';

function NetWorthCards({ segment, setSegment }) {
  const {
    cardList,
    loadingAppBalance,
    setAssetClass,
    setAssetCoin,
    setLiquidity,
    assetClass,
    assetCoin,
    liquidity,
  } = useContext(NetWorthContext);
  const { coinNameObject } = useContext(BankContext);

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return -1;
      } else if (a[prop] < b[prop]) {
        return 1;
      }
      return 0;
    };
  }

  const [title, setTitle] = useState('Networth By Asset Class');
  useEffect(() => {
    if (liquidity) {
      if (liquidity === 'Liquid') {
        setTitle(
          `Of Liquid ${
            coinNameObject &&
            coinNameObject[assetCoin] &&
            coinNameObject[assetCoin].coinSymbol
          }`
        );
      } else {
        setTitle(
          `Of ${
            coinNameObject &&
            coinNameObject[assetCoin] &&
            coinNameObject[assetCoin].coinSymbol
          } Bonds`
        );
      }
    } else if (assetCoin) {
      setTitle(
        `Of ${
          coinNameObject &&
          coinNameObject[assetCoin] &&
          coinNameObject[assetCoin].coinSymbol
        }  Holdings`
      );
    } else if (assetClass) {
      setTitle(
        `Of ${assetClass === 'Cryptocurrency' ? 'Crypto' : 'Fiat'} Holdings`
      );
    } else {
      setTitle('Of Net-Worth');
    }
  }, [assetClass, assetCoin, liquidity, coinNameObject]);

  const [modalAsset, setModalAsset] = useState(false);

  return (
    <Scrollbars
      autoHide
      className="netWorthCardsList"
      renderThumbHorizontal={() => <div />}
      renderThumbVertical={() => <div />}
      renderView={(props) => (
        <div {...props} className="netWorthCardsListView" />
      )}
    >
      <div className="stikyHeadMobile">Showing All Asset Types</div>
      {loadingAppBalance
        ? Array(5)
            .fill('')
            .map((_item, i) => (
              <div key={i} className="netWorthCard true">
                <div className="colorHighlight" />
                <BondsItemLoading />
              </div>
            ))
        : cardList
            .sort(GetSortOrder('value'))
            .sort((a, b) =>
              a.name === 'Indian Rupee' ? -1 : b.name === 'Indian Rupee' ? 1 : 0
            )
            .map((card, i) => (
              <div
                key={`${card.name}${i + assetClass + assetCoin + liquidity}`}
                className={`netWorthCard ${
                  (segment === null || segment === i) &&
                  card?.type !== 'coming_soon'
                }`}
                onMouseEnter={() => setSegment(i)}
                onMouseLeave={() => setSegment(null)}
                onClick={() => {
                  if (
                    !loadingAppBalance &&
                    (card.value || card.name === 'Bonds') &&
                    !card.disabled
                  ) {
                    if (card.type && card.type === 'asset_class') {
                      setAssetClass(card.name);
                    }
                    if (
                      card.type &&
                      (card.type === 'coin' || card.type === 'investmentCoin')
                    ) {
                      setAssetCoin(card.name);
                    }
                    if (
                      card.type &&
                      (card.type === 'liquidity' || card.type === 'subType')
                    ) {
                      setLiquidity(card.name);
                    }
                  }
                  if (card.type && card.type === 'coming_soon') {
                    setModalAsset(card.name);
                  }
                }}
              >
                <div
                  className="colorHighlight"
                  style={{ background: card.value && card.color }}
                />
                <BondsItem title={title} card={card} />
              </div>
            ))}
      {modalAsset ? (
        <ModalAlertUpdate
          setOpenModal={setModalAsset}
          assetClass={modalAsset}
        />
      ) : (
        ''
      )}
    </Scrollbars>
  );
}

export default NetWorthCards;
