import React, { useState, useEffect } from "react";
import styles from "./steps.module.scss";
import insiderIcon from "../../img/insiderIconColor.svg";
import checkRounded from "../../img/checkRounded.svg";
import Axios from "axios";

import { LoadingOutlined } from "@ant-design/icons";
import { message } from "antd";

const Step3 = ({
  setStep,
  email,
  setEmail,
  username,
  setUsername,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  loading,
  setLoading,
  gxSignupHandler,
}) => {
  const handleNext = () => {
    if (email && username && password && password === confirmPassword) {
      setLoading(true);
      gxSignupHandler();
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img src={insiderIcon} alt="" />
          <div className={styles.formContainer}>
            <div>
              <h1 className={styles.title}>Login Credentials</h1>
              <h4 className={styles.subTitle}>
                Create a unique username, email and password
              </h4>
            </div>
            <div
              className={styles.form}
              style={{
                overflowY: "scroll",
                // height: "auto",
                height: "60vh",
                paddingBottom: "30px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "35px",
                }}
              >
                <div>
                  <div className={styles.lable}>Email</div>
                  <div
                    className={styles.inputWrapper}
                    style={{
                      borderColor:
                        email.length > 3
                          ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/.test(
                              email
                            )
                            ? "green"
                            : "red"
                          : "",
                    }}
                  >
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      className={styles.inputStyle}
                      placeholder="friends@gmail.com...."
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.lable}>Username</div>
                  <div className={styles.inputWrapper}>
                    <input
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      type="text"
                      className={styles.inputStyle}
                      placeholder="@manisha..."
                    />
                  </div>
                </div>
              </div>
              <div style={{ margin: "6% 0px" }}>
                <div className={styles.lable}>Password</div>
                <div className={styles.inputWrapper}>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className={styles.inputStyle}
                    placeholder="*********************"
                  />
                </div>
              </div>
              <div>
                <div className={styles.lable}>Confirm Password</div>
                <div className={styles.inputWrapper}>
                  <input
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                    className={styles.inputStyle}
                    placeholder="*********************"
                  />
                </div>
              </div>
              <button
                className={styles.submitButton}
                style={{
                  marginTop: "7vh",
                  opacity:
                    email &&
                    username &&
                    password &&
                    password === confirmPassword
                      ? 1
                      : 0.5,
                }}
                onClick={handleNext}
              >
                {loading ? <LoadingOutlined /> : ""} Proceed
              </button>
            </div>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.finishedPage} onClick={(e) => setStep(1)}>
            <div>Who Invited You</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage} onClick={(e) => setStep(2)}>
            <div>Country Of Residence</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.currentPage}>Login Credentials</div>
          <div className={styles.otherPage}>Verify Email</div>
          <div className={styles.otherPage}>Enter Personal Details</div>
          <div className={styles.otherPage}>Verify Phone Number</div>
          <div className={styles.otherPage}>Enter Address</div>
          <div className={styles.otherPage}>Type Of Investor</div>
          <div className={styles.otherPage}>Type of Insider</div>
        </div>
      </div>
    </>
  );
};

export default Step3;
