import React, { useState, useContext } from "react";
import { Row, Col, Input, Button, Form, message, Icon, Modal } from "antd";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../config";
import { MyContext } from "../Context";
import isEmail from "validator/lib/isEmail";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const LoginMobile = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    if (isEmail(username) && password !== "") {
      // message.loading("Authenticating...");
      setStep(null);
      setLoading(true);
      let authenticationData = {
        Username: username,
        Password: password,
      };

      let authdet = new AuthenticationDetails(authenticationData);

      let userData = {
        Username: username,
        Pool: userPool,
      };

      let user = new CognitoUser(userData);

      user.authenticateUser(authdet, {
        onSuccess: function (result) {
          setLoading(false);
          //console.log(result);
          message.destroy();
          message.success("Login Successfull");
          let accessToken = result.accessToken.jwtToken;
          localStorage.setItem("access_token", accessToken);
          localStorage.setItem("user_account", username);
          localStorage.setItem("loginObj", JSON.stringify(result));
          localStorage.setItem(
            "actual_username",
            result.accessToken.payload.username
          );
          //   setTimeout(() => {
          //     value.setLogin(true);
          //   }, 1000);
          var idtoken = result.getIdToken().getJwtToken();
          //idtoken.client_id = client_id;
          localStorage.setItem("token", idtoken);
          navigate("/");
          window.location.reload();
        },

        onFailure: function (err) {
          localStorage.removeItem("token");
          console.log(err);
          //   setTimeout(() => {
          //     value.setLogin(false);
          //   }, 2000);
          message.destroy();
          message.error(err.message, 2);
          if (err.message === "User is not confirmed.") {
            // setVisibleVerify(true);
          }
        },
      });
    } else {
      message.destroy();
      message.error("Please enter Email & password");
    }
  };

  const conditionalStepRender = () => {
    if (step === 0) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "0px 50px",
          }}
        >
          <div style={{ paddingBottom: "95px" }}>
            <img
              src={require("../img/insider.svg")}
              alt=""
              style={{ width: "70vw", height: "100%" }}
            />
          </div>

          <Button
            className="mobile-login"
            onClick={(e) => setStep(1)}
            style={{
              background: "linear-gradient(270deg, #4CAF50 0%, #84E188 100%)",
              border: "none",
              marginBottom: "24px",
            }}
          >
            Login
          </Button>
          <Button
            className="mobile-register"
            onClick={(e) => navigate("/getstarted")}
          >
            Register
          </Button>
          <br />
          <br />
          <div className="already-registered" onClick={(e) => setStep(3)}>
            Click Here If You Were Pre-Registered
          </div>
        </div>
      );
    } else if (step === 1) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "0px 50px",
          }}
        >
          <div style={{ paddingBottom: "95px" }}>
            <img
              src={require("../img/insider.svg")}
              alt=""
              style={{ width: "70vw" }}
            />
          </div>
          <div style={{ paddingBottom: "95px", width: "100%" }}>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="login-mobile-input1"
              placeholder="Enter Your Email"
            />
          </div>

          <Button
            onClick={(e) => setStep(2)}
            type="primary"
            disabled={username.length ? false : true}
            className="mobile-login"
            style={{
              marginBottom: "24px",
              background:
                username.length > 0
                  ? "linear-gradient(270deg, #4CAF50 0%, #84E188 100%)"
                  : "rgba(76, 175, 80, 0.13)",
              border: "none",
              // border:
              //   username.length > 0 ? "" : "1px solid #BFE7FE",
              color: "white",
            }}
          >
            Next
          </Button>
        </div>
      );
    } else if (step === 2) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "0px 50px",
          }}
        >
          <div style={{ paddingBottom: "95px" }}>
            <img
              src={require("../img/insider.svg")}
              alt=""
              style={{ width: "70vw" }}
            />
          </div>
          <div style={{ paddingBottom: "95px", width: "100%" }}>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="login-mobile-input1"
              placeholder="Enter Password"
            />
          </div>

          <Button
            onClick={(e) => {
              setStep(null);
              handleLogin();
            }}
            type="primary"
            disabled={password.length ? false : true}
            className="mobile-login"
            style={{
              marginBottom: "24px",
              background:
                password.length > 0
                  ? "linear-gradient(270deg, #4CAF50 0%, #84E188 100%)"
                  : "rgba(76, 175, 80, 0.13)",
              border: "none",
              // border:
              //   password.length > 0 ? "1px solid #009efc" : "1px solid #BFE7FE",
              color: "white",
            }}
          >
            Login
          </Button>
        </div>
      );
    } else if (step === 3) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "0px 50px",
          }}
        >
          <div style={{ paddingBottom: "40px" }}>
            <img
              src={require("../img/Group4.png")}
              alt=""
              style={{ width: "100%" }}
            />
          </div>
          <div
            className="already-registered"
            style={{
              paddingBottom: "40px",
              textAlign: "center",
              // color: "#999999",
            }}
          >
            Enter Your Registered Email
          </div>
          <div style={{ paddingBottom: "80px", width: "100%" }}>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="login-mobile-input1"
              placeholder="Enter Your Email"
            />
          </div>

          <Button
            onClick={(e) => setStep(4)}
            type="primary"
            disabled={username.length ? false : true}
            className="mobile-login"
            style={{
              marginBottom: "24px",
              background:
                username.length > 0
                  ? "linear-gradient(270deg, #4CAF50 0%, #84E188 100%)"
                  : "rgba(76, 175, 80, 0.13)",
              border: "none",
              // border:
              //   username.length > 0 ? "1px solid #009efc" : "1px solid #BFE7FE",
              color: "white",
            }}
          >
            Next
          </Button>
          <Button
            onClick={(e) => window.navigate.back()}
            // type="ghost"
            disabled={false}
            className="mobile-register"
            style={{
              marginBottom: "24px",
              // background: username.length > 0 ? "#009efc" : "#BFE7FE",
              border:
                username.length > 0 ? "1px solid #009efc" : "1px solid #BFE7FE",
              // color: "white",
            }}
          >
            Back
          </Button>
        </div>
      );
    } else if (step === 4) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "0px 50px",
          }}
        >
          <div style={{ paddingBottom: "40px" }}>
            <img
              src={require("../img/insider.svg")}
              alt=""
              style={{ width: "70vw" }}
            />
          </div>
          <div
            className="already-registered"
            style={{
              paddingBottom: "40px",
              textAlign: "center",
              // color: "#999999",
            }}
          >
            Enter The Temporary password That You Have Received
          </div>
          <div style={{ paddingBottom: "80px", width: "100%" }}>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="login-mobile-input1"
              placeholder="Enter Your Email"
            />
          </div>

          <Button
            onClick={(e) => setStep(4)}
            type="primary"
            disabled={username.length ? false : true}
            className="mobile-login"
            style={{
              marginBottom: "24px",
              background:
                username.length > 0
                  ? "linear-gradient(270deg, #4CAF50 0%, #84E188 100%)"
                  : "rgba(76, 175, 80, 0.13)",
              border: "none",
              // border:
              //   username.length > 0 ? "1px solid #009efc" : "1px solid #BFE7FE",
              color: "white",
            }}
          >
            Next
          </Button>
          <Button
            onClick={(e) => window.navigate.back()}
            // type="ghost"
            disabled={username.length ? false : true}
            className="mobile-register"
            style={{
              marginBottom: "24px",
              // background: username.length > 0 ? "#009efc" : "#BFE7FE",
              border:
                username.length > 0 ? "1px solid #009efc" : "1px solid #BFE7FE",
              // color: "white",
            }}
          >
            Back
          </Button>
        </div>
      );
    } else if (step === 5) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "0px 50px",
          }}
        >
          <div style={{ paddingBottom: "40px" }}>
            <img
              src={require("../img/insider.svg")}
              alt=""
              style={{ width: "70vw" }}
            />
          </div>
          <div
            className="already-registered"
            style={{
              paddingBottom: "40px",
              textAlign: "center",
              // color: "#999999",
            }}
          >
            Please Enter You’re Friends NetworkSync Code
          </div>
          <div style={{ paddingBottom: "80px", width: "100%" }}>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="login-mobile-input1"
              placeholder="Enter Your Email"
            />
          </div>

          <Button
            onClick={(e) => setStep(4)}
            type="primary"
            disabled={username.length ? false : true}
            className="mobile-login"
            style={{
              marginBottom: "24px",
              background:
                username.length > 0
                  ? "linear-gradient(270deg, #4CAF50 0%, #84E188 100%)"
                  : "rgba(76, 175, 80, 0.13)",
              border: "none",
              // border:
              //   username.length > 0 ? "1px solid #009efc" : "1px solid #BFE7FE",
              color: "white",
            }}
          >
            Next
          </Button>
          <Button
            onClick={(e) => window.navigate.back()}
            // type="ghost"
            disabled={false}
            className="mobile-register"
            style={{
              marginBottom: "24px",
              // background: username.length > 0 ? "#009efc" : "#BFE7FE",
              border:
                username.length > 0 ? "1px solid #009efc" : "1px solid #BFE7FE",
              // color: "white",
            }}
          >
            Back
          </Button>
        </div>
      );
    }
  };
  return (
    <>
      {!loading ? (
        conditionalStepRender()
      ) : (
        <div className="full-loading-wrapper">
          <img
            src={require("../img/insider.svg")}
            alt=""
            className="full-loading-logo"
            style={{ width: "80%" }}
          />
        </div>
      )}
    </>
  );
};

export default LoginMobile;
