import React, { createContext, useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import { useQuery } from 'react-query';
import { BankContext } from './Context';
import { useAppContextDetails } from './AppContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';
// import { predefinedToast } from '../utils/toast';
import toast from 'react-hot-toast';

export const planBContext = createContext();

function PlanBContextProvider({ children }) {
  const [planBCreate, setplanBCreate] = useState(false);
  const [insideplanBCreate, setinsideplanBCreate] = useState('createplanb');
  const [allPlansList, setallPlansList] = useState([]);
  const [planBAccountName, setplanBAccountName] = useState('');
  const [planBAccountType, setplanBAccountType] = useState('');
  const [planBAccountTypeIcon, setplanBAccountTypeIcon] = useState('');
  const [planBAccountGoalId, setplanBAccountGoalId] = useState('');
  const [planBAccountDesc, setplanBAccountDesc] = useState('');
  const [planBAccountTargetDate, setplanBAccountTargetDate] = useState({
    date: '',
    month: '',
    year: '',
  });
  const [planBAccountContributor, setplanBAccountContributor] = useState('Me');
  const [planBAccountPic, setplanBAccountPic] = useState('');
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);

  //dropDown
  const [selectedDropDown, setSelectedDropDown] = useState('');

  //planCreation
  const [planBPlanPic, setplanBPlanPic] = useState('');
  const [planBPlanPicUploading, setplanBPlanPicUploading] = useState(false);
  const [planBCreateName, setplanBCreateName] = useState('');
  const [planBCreateDescription, setplanBCreateDescription] = useState('');
  const [creationLoading, setCreationLoading] = useState(false);
  const [deletePlanID, setdeletePlanID] = useState('');

  //configuration
  const [configurationValues, setConfigurationValues] = useState([]);
  const [configurationGoalValues, setConfigurationGoalValues] = useState('');
  const [configurationDateValues, setConfigurationDateValues] = useState('');

  const [editingConfigurationField, setEditingConfigurationField] =
    useState('');

  const [editingConfigurationInput, seteditingConfigurationInput] =
    useState('');
  const [editingConfigurationSearch, seteditingConfigurationSearch] =
    useState('');
  const [editingConfigurationLoading, seteditingConfigurationLoading] =
    useState(false);
  const [planTypeCreate, setplanTypeCreate] = useState(false);

  function editFuncApi(retiredid) {
    seteditingConfigurationLoading(true);

    let obj = {
      [editingConfigurationField.body]:
        editingConfigurationField?.type == 'input'
          ? editingConfigurationInput
          : editingConfigurationField?.type == 'search'
          ? editingConfigurationSearch
          : editingConfigurationField?.type == 'date'
          ? planBAccountTargetDate?.date &&
            planBAccountTargetDate?.month &&
            planBAccountTargetDate?.year
            ? planBAccountTargetDate?.date +
              '/' +
              planBAccountTargetDate?.month +
              '/' +
              planBAccountTargetDate?.year
            : ''
          : editingConfigurationField?.type == 'image'
          ? planBAccountPic
          : '',
    };
    // console.log(obj, 'posting obj');
    axios
      .put(
        `https://comms.globalxchange.io/retired/block/update/retirementplan?retirement_plan_id=${retiredid}`,
        obj
      )
      .then((response) => {
        // console.log(response, 'response edited');
        // predefinedToast(response?.data?.message);
        toast(response?.data?.message, {
          duration: 4000,
          position: 'top-left',
        });
        seteditingConfigurationLoading(false);
        setEditingConfigurationField('');
      })
      .catch((error) => {
        console.log(error, 'error');
        // predefinedToast('Network error try again..');
        toast('Network error try again..', {
          duration: 4000,
          position: 'top-left',
        });
        seteditingConfigurationLoading(false);
        setEditingConfigurationField('');
      });
  }

  useEffect(() => {
    seteditingConfigurationInput('');
    seteditingConfigurationSearch('');
    setplanBAccountTargetDate({
      date: '',
      month: '',
      year: '',
    });
  }, [editingConfigurationField]);

  //progress

  const [overallProgressBar, setoverallProgressBar] = useState('');
  const [blockData, setblockData] = useState('');
  const [timeProgressbar, settimeProgressbar] = useState('');

  return (
    <planBContext.Provider
      value={{
        planBCreate,
        setplanBCreate,
        planBAccountName,
        setplanBAccountName,
        allPlansList,
        setallPlansList,
        planBAccountType,
        setplanBAccountType,
        planBAccountDesc,
        setplanBAccountDesc,
        planBAccountTargetDate,
        setplanBAccountTargetDate,
        planBAccountContributor,
        setplanBAccountContributor,
        planBAccountPic,
        setplanBAccountPic,
        planBAccountPicUploading,
        setplanBAccountPicUploading,
        insideplanBCreate,
        setinsideplanBCreate,
        planBPlanPic,
        setplanBPlanPic,
        planBPlanPicUploading,
        setplanBPlanPicUploading,
        planBCreateName,
        setplanBCreateName,
        planBCreateDescription,
        setplanBCreateDescription,
        selectedDropDown,
        setSelectedDropDown,
        creationLoading,
        setCreationLoading,
        planBAccountGoalId,
        setplanBAccountGoalId,
        planBAccountTypeIcon,
        setplanBAccountTypeIcon,
        deletePlanID,
        setdeletePlanID,
        configurationValues,
        setConfigurationValues,
        configurationGoalValues,
        setConfigurationGoalValues,
        configurationDateValues,
        setConfigurationDateValues,
        editingConfigurationField,
        setEditingConfigurationField,
        planTypeCreate,
        setplanTypeCreate,
        //editing configuration
        editingConfigurationInput,
        seteditingConfigurationInput,
        editingConfigurationSearch,
        seteditingConfigurationSearch,
        //editfuncapi
        editFuncApi,
        editingConfigurationLoading,
        seteditingConfigurationLoading,
        overallProgressBar,
        setoverallProgressBar,
        blockData,
        setblockData,
        timeProgressbar,
        settimeProgressbar,
      }}
    >
      {children}
    </planBContext.Provider>
  );
}

export default PlanBContextProvider;
