import React, { useState } from "react";
import { Form, Input, Row, Col, Button, message } from "antd";
import bg from "../img/register.svg";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useEffect } from "react";

const { TextArea } = Input;

const REGISTER = gql`
  mutation reg($name: String!, $username: String!, $email: String!) {
    register_user(data: { name: $name, username: $username, email: $email })
  }
`;

const Register = () => {
  const [pulseName, setPulseName] = useState("");
  const [pulseUsername, setPulseUsername] = useState("");
  //   const [bio, setBio] = useState("");

  const [regUser, regUserData] = useMutation(REGISTER);

  useEffect(() => {
    if (regUserData.data) {
      if (regUserData.data.register_user) {
        message.success("Registration Successful");
        window.location.reload();
      } else {
        message.warning("This username is taken. Please choose something else");
      }
    }
  }, [regUserData]);

  return (
    <>
      <Row style={{ display: "flex", alignItems: "center" }}>
        <Col
          xs={24}
          sm={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div style={{ padding: "0px 50px", textAlign: "center" }}>
            <img
              src={require("../img/pulse.png")}
              alt=""
              style={{ width: "90px" }}
            />
            <h1 style={{ fontWeight: "bold", color: "#166BB5" }}>GX-Pulse</h1>
            <Form style={{ paddingTop: "30px" }}>
              <Input
                style={{ marginBottom: "10px", borderRadius: "20px" }}
                type="text"
                size="large"
                placeholder="Name"
                value={pulseName}
                onChange={(e) => setPulseName(e.target.value)}
              />
              <Input
                style={{ marginBottom: "20px", borderRadius: "20px" }}
                type="text"
                size="large"
                placeholder="Username"
                value={pulseUsername}
                onChange={(e) =>
                  setPulseUsername(
                    e.target.value.toLowerCase().replace(/[^A-Z0-9]+/gi, "")
                  )
                }
              />

              {/* <TextArea
                // style={{ borderRadius: "40px" }}
                rows={4}
                size="large"
                placeholder="Enter Bio"
                value={bio}
                onChange={e => setBio(e.target.value)}
              /> */}
              <Button
                block
                // type="primary"
                size="large"
                style={{
                  borderRadius: "20px",
                  backgroundColor: "#166BB5",
                  color: "white",
                }}
                onClick={(e) => {
                  if (pulseName !== "" && pulseUsername !== "") {
                    regUser({
                      variables: {
                        name: pulseName,
                        username: pulseUsername,
                        email: localStorage.getItem("user_account"),
                      },
                    });
                  } else {
                    message.warning("Please enter both Name & Username");
                  }
                }}
              >
                SUBMIT
              </Button>
            </Form>
          </div>
        </Col>
        <Col
          s
          xs={24}
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          &nbsp;
          {/* <div style={{ marginRight: "10px" }}>
            <img src={require("../img/register.svg")} alt="" width="90%" />
          </div> */}
        </Col>
      </Row>
    </>
  );
};

export default Register;
