import React, { useEffect, useState, useContext } from "react";
import {
  Layout,
  Input,
  Avatar,
  Spin,
  Popover,
  Divider,
  Tag,
  Col,
  Row,
  Space,
  Button,
} from "antd";

import {
  Link,
  useNavigate,
  useLocation,
  Switch,
  Route,
  Outlet,
} from "react-router-dom";

import { MyContext } from "../Context";

import { ReactComponent as Home_img } from "../img/nav-icons/Home.svg";
import { ReactComponent as Saved_img } from "../img/nav-icons/Saved.svg";
import { ReactComponent as Chats_img } from "../img/nav-icons/Chats.svg";
import { ReactComponent as Conn_img } from "../img/nav-icons/Connections.svg";
import { ReactComponent as Profile_img } from "../img/nav-icons/Profile.svg";
import { ReactComponent as Subs_img } from "../img/nav-icons/Subscriptions.svg";
import { ReactComponent as Vaults_img } from "../img/nav-icons/Vaults.svg";
import { ReactComponent as Drops_img } from "../img/nav-icons/Drops.svg";
import { ReactComponent as Interactions_img } from "../img/nav-icons/Interactions.svg";

//assests
import { ReactComponent as Markets_img } from "../assets/images/sidebar/markets.svg";
import { ReactComponent as Listings_img } from "../assets/images/sidebar/listings.svg";
import { ReactComponent as Deals_img } from "../assets/images/sidebar/deals.svg";
import { ReactComponent as Signals_img } from "../assets/images/sidebar/signals.svg";
import { ReactComponent as Communities_img } from "../assets/images/sidebar/communities.svg";
import { ReactComponent as Institute_img } from "../assets/images/sidebar/institute.svg";
import { ReactComponent as Stream_img } from "../assets/images/sidebar/stream.svg";
import { ReactComponent as Capital_img } from "../assets/images/sidebar/capital.svg";

const LoggedOutMenuSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const { userEmail } = useContext(MyContext);

  useEffect(() => {
    setSelectedMenu(window.location.href.split("/").pop());
  }, [window.location.href.split("/").pop()]);

  return (
    <Col
      span={4}
      style={{
        // background: "#E5E5E5",
        padding: "10px",
        paddingTop: "20px",
        // height: "90vh",
        height: "100%",
        borderRight: "0.5px solid #EDEDED",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        // border: "2px solid red",
      }}
    >
      <div className="leftBottomBtns">
        <Link className="nav-button1" to="/home">
          Create
        </Link>
        {/* <Link className="nav-button2" to="#">
          Affiliates
        </Link> */}
      </div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
          height: "calc(100% - 79px)",
          overflowY: "scroll",
        }}
      >
        <Link to="/home">
          <div
            onMouseOver={() => setSelectedMenu("home")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== ""
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === ""
                  ? "rgba(76, 175, 80, 0.13)"
                  : selectedMenu === "home"
                  ? "rgba(76, 175, 80, 0.13)"
                  : "",
            }}
          >
            <Home_img
              fill={
                window.location.href.split("/").pop() === ""
                  ? "#4CAF50"
                  : selectedMenu === "home"
                  ? "#4CAF50"
                  : "#999999"
              }
              stroke="none"
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === ""
                    ? "#4CAF50"
                    : selectedMenu === "home"
                    ? "#4CAF50"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Arena
            </div>
          </div>
        </Link>
        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("markets")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "markets"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "markets"
                  ? "rgba(76, 175, 80, 0.13)"
                  : selectedMenu === "markets"
                  ? "rgba(76, 175, 80, 0.13)"
                  : "",
            }}
          >
            <Markets_img
              fill={selectedMenu === "markets" ? "#4CAF50" : "#999999"}
              // stroke={
              //   window.location.href.split("/").pop() === "markets"
              //     ? "#4CAF50"
              //     : selectedMenu === "markets"
              //     ? "#4CAF50"
              //     : "#999999"
              // }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "markets"
                    ? "#4CAF50"
                    : selectedMenu === "markets"
                    ? "#4CAF50"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Discover
            </div>
          </div>
        </Link>
        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("listings")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "listings"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "listings"
                  ? "rgba(76, 175, 80, 0.13)"
                  : selectedMenu === "listings"
                  ? "rgba(76, 175, 80, 0.13)"
                  : "",
            }}
          >
            <Listings_img
              fill={selectedMenu === "listings" ? "#4CAF50" : "#999999"}
              // stroke={
              //   window.location.href.split("/").pop() === "listings"
              //     ? "#4CAF50"
              //     : selectedMenu === "listings"
              //     ? "#4CAF50"
              //     : "#999999"
              // }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "listings"
                    ? "#4CAF50"
                    : selectedMenu === "listings"
                    ? "#4CAF50"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Listings
            </div>
          </div>
        </Link>

        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("signals")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "signals"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "signals"
                  ? "rgba(76, 175, 80, 0.13)"
                  : selectedMenu === "signals"
                  ? "rgba(76, 175, 80, 0.13)"
                  : "",
            }}
          >
            <Signals_img
              fill={selectedMenu === "signals" ? "#4CAF50" : "#999999"}
              // stroke={
              //   window.location.href.split("/").pop() === "signals"
              //     ? "#4CAF50"
              //     : selectedMenu === "signals"
              //     ? "#4CAF50"
              //     : "#999999"
              // }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "signals"
                    ? "#4CAF50"
                    : selectedMenu === "signals"
                    ? "#4CAF50"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Signals
            </div>
          </div>
        </Link>
        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("stream")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "stream"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "stream"
                  ? "rgba(76, 175, 80, 0.13)"
                  : selectedMenu === "stream"
                  ? "rgba(76, 175, 80, 0.13)"
                  : "",
            }}
          >
            <Stream_img
              fill={selectedMenu === "stream" ? "#4CAF50" : "#999999"}
              // stroke={
              //   window.location.href.split("/").pop() === "stream"
              //     ? "#4CAF50"
              //     : selectedMenu === "stream"
              //     ? "#4CAF50"
              //     : "#999999"
              // }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "stream"
                    ? "#4CAF50"
                    : selectedMenu === "stream"
                    ? "#4CAF50"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Stream
            </div>
          </div>
        </Link>

        <hr style={{ border: "1px solid #EDEDED" }} />

        <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("chats")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background:
              selectedMenu === "chats" ? "rgba(76, 175, 80, 0.13)" : "",
          }}
        >
          <Chats_img
            fill={selectedMenu === "chats" ? "#4CAF50" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "chats" ? "#4CAF50" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Chats
          </div>
        </div>

        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("communities")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "communities"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "communities"
                  ? "rgba(76, 175, 80, 0.13)"
                  : selectedMenu === "communities"
                  ? "rgba(76, 175, 80, 0.13)"
                  : "",
            }}
          >
            <Communities_img
              fill={selectedMenu === "communities" ? "#4CAF50" : "#999999"}
              // stroke={
              //   window.location.href.split("/").pop() === "communities"
              //     ? "#4CAF50"
              //     : selectedMenu === "communities"
              //     ? "#4CAF50"
              //     : "#999999"
              // }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "communities"
                    ? "#4CAF50"
                    : selectedMenu === "communities"
                    ? "#4CAF50"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Communities
            </div>
          </div>
        </Link>
        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("institute")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "institute"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "institute"
                  ? "rgba(76, 175, 80, 0.13)"
                  : selectedMenu === "institute"
                  ? "rgba(76, 175, 80, 0.13)"
                  : "",
            }}
          >
            <Institute_img
              fill={selectedMenu === "institute" ? "#4CAF50" : "#999999"}
              // stroke={
              //   window.location.href.split("/").pop() === "institute"
              //     ? "#4CAF50"
              //     : selectedMenu === "institute"
              //     ? "#4CAF50"
              //     : "#999999"
              // }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "institute"
                    ? "#4CAF50"
                    : selectedMenu === "institute"
                    ? "#4CAF50"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Institute
            </div>
          </div>
        </Link>

        <hr style={{ border: "1px solid #EDEDED" }} />

        {/* <Link to="/login"> */}
        <div
          onClick={(e) =>
            window.open("http://business.indianinvestor.com", "_blank")
          }
          onMouseOver={() => setSelectedMenu("capital")}
          onMouseOut={() =>
            window.location.href.split("/").pop() !== "capital"
              ? setSelectedMenu(null)
              : ""
          }
          className="nav-item"
          style={{
            background:
              window.location.href.split("/").pop() === "capital"
                ? "rgba(76, 175, 80, 0.13)"
                : selectedMenu === "capital"
                ? "rgba(76, 175, 80, 0.13)"
                : "",
          }}
        >
          <Capital_img
            fill={selectedMenu === "capital" ? "#4CAF50" : "#999999"}
            // stroke={
            //   window.location.href.split("/").pop() === "deals"
            //     ? "#4CAF50"
            //     : selectedMenu === "deals"
            //     ? "#4CAF50"
            //     : "#999999"
            // }
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color:
                window.location.href.split("/").pop() === "capital"
                  ? "#4CAF50"
                  : selectedMenu === "capital"
                  ? "#4CAF50"
                  : "#999999",
              paddingLeft: "10px",
            }}
          >
            Raise Capital
          </div>
        </div>
        {/* </Link> */}
        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("deals")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "deals"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "deals"
                  ? "rgba(76, 175, 80, 0.13)"
                  : selectedMenu === "deals"
                  ? "rgba(76, 175, 80, 0.13)"
                  : "",
            }}
          >
            <Deals_img
              fill={selectedMenu === "deals" ? "#4CAF50" : "#999999"}
              // stroke={
              //   window.location.href.split("/").pop() === "deals"
              //     ? "#4CAF50"
              //     : selectedMenu === "deals"
              //     ? "#4CAF50"
              //     : "#999999"
              // }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "deals"
                    ? "#4CAF50"
                    : selectedMenu === "deals"
                    ? "#4CAF50"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Make A Deal
            </div>
          </div>
        </Link>
        {/* <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("saved")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "saved"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "saved"
                  ? "rgba(76, 175, 80, 0.13)"
                  : selectedMenu === "saved"
                  ? "rgba(76, 175, 80, 0.13)"
                  : "",
            }}
          >
            <Saved_img
              // fill={selectedMenu === "saved" ? "#4CAF50" : "#999999"}
              stroke={
                window.location.href.split("/").pop() === "saved"
                  ? "#4CAF50"
                  : selectedMenu === "saved"
                  ? "#4CAF50"
                  : "#999999"
              }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "saved"
                    ? "#4CAF50"
                    : selectedMenu === "saved"
                    ? "#4CAF50"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Saved
            </div>
          </div>
        </Link>
        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("brain")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "brain"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "brain"
                  ? "rgba(76, 175, 80, 0.13)"
                  : selectedMenu === "brain"
                  ? "rgba(76, 175, 80, 0.13)"
                  : "",
            }}
          >
            <Saved_img
              // fill={selectedMenu === "saved" ? "#4CAF50" : "#999999"}
              stroke={
                window.location.href.split("/").pop() === "brain"
                  ? "#4CAF50"
                  : selectedMenu === "brain"
                  ? "#4CAF50"
                  : "#999999"
              }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "brain"
                    ? "#4CAF50"
                    : selectedMenu === "brain"
                    ? "#4CAF50"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Brain
            </div>
          </div>
        </Link> */}

        {/* <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("conn")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background:
              selectedMenu === "conn" ? "rgba(76, 175, 80, 0.13)" : "",
          }}
        >
          <Conn_img
            fill={selectedMenu === "conn" ? "#4CAF50" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "conn" ? "#4CAF50" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Connections
          </div>
        </div> */}
        {/* <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("profile")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "profile"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background: location.pathname.includes(
                localStorage.getItem("actual_username")
              )
                ? ""
                : localStorage.getItem("selected_user_email") === userEmail
                ? ""
                : "",
            }}
          >
            <Profile_img
              fill={selectedMenu === "profile" ? "#4CAF50" : "#999999"}
              stroke="none"
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color: selectedMenu === "profile" ? "#4CAF50" : "#999999",
                paddingLeft: "10px",
              }}
            >
              Profile
            </div>
          </div>
        </Link> */}
        {/* <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("subs")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background:
              selectedMenu === "subs" ? "rgba(76, 175, 80, 0.13)" : "",
          }}
        >
          <Subs_img
            fill={selectedMenu === "subs" ? "#4CAF50" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "subs" ? "#4CAF50" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Subscriptions
          </div>
        </div> */}

        {/* <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("vaults")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background:
              selectedMenu === "vaults" ? "rgba(76, 175, 80, 0.13)" : "",
          }}
        >
          <Vaults_img
            fill={selectedMenu === "vaults" ? "#4CAF50" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "vaults" ? "#4CAF50" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Vaults
          </div>
        </div> */}

        {/* <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("drops")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background:
              selectedMenu === "drops" ? "rgba(76, 175, 80, 0.13)" : "",
          }}
        >
          <Drops_img
            fill={selectedMenu === "drops" ? "#4CAF50" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "drops" ? "#4CAF50" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Drops
          </div>
        </div> */}

        {/* <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("interactions")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background:
              selectedMenu === "interactions" ? "rgba(76, 175, 80, 0.13)" : "",
          }}
        >
          <Interactions_img
            fill={selectedMenu === "interactions" ? "#4CAF50" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "interactions" ? "#4CAF50" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Interactions
          </div>
        </div> */}
      </Space>
    </Col>
  );
};

export default LoggedOutMenuSidebar;
