import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import isEmail from "validator/lib/isEmail";

import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../config";

const REGISTER = gql`
  mutation reg($name: String!, $username: String!, $email: String!) {
    register_user(data: { name: $name, username: $username, email: $email })
  }
`;

const AlreadyRegistered = () => {
  const [regUser, regUserData] = useMutation(REGISTER);
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [tempPassword, setTempPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);

  const [password, setPassword] = useState("");

  const [rUsername, setRusername] = useState("");
  const [rEmail, setRemail] = useState("");
  const [rPassword, setRpassword] = useState("");

  const [rPassword1, setRpassword1] = useState("");
  const [visibleVerify, setVisibleVerify] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  const [resetSteps, setResetSteps] = useState(0);

  const [receivedSession, setReceivedSession] = useState("");
  const [receivedChallengeName, setReceivedChallengeName] = useState("");
  const [receivedUsername, setReceivedUsername] = useState("");
  const [receivedEmail, setReceivedEmail] = useState("");
  const [authChallengeId, setAuthChallengeId] = useState("");
  const [fullPageLoading, setFullPageLoading] = useState(false);

  useEffect(() => {
    if (regUserData.data) {
      if (regUserData.data.register_user) {
        message.success("Registration Successful");
        window.location.reload();
      } else {
        message.warning("This username is taken. Please choose something else");
      }
    }
  }, [regUserData]);

  const conditionalBannerText = () => {
    if (resetSteps === 0) {
      return (
        <>
          <div className="side-text">That's Great.</div>
          <div className="side-text">Your Already</div>
          <div className="side-text">50% Done</div>
        </>
      );
    } else if (resetSteps === 1) {
      return (
        <>
          <div className="side-text">That's Great.</div>
          <div className="side-text">Your Already</div>
          <div className="side-text">50% Done</div>
        </>
      );
    } else if (resetSteps === 2) {
      return (
        <>
          <div className="side-text">Now You</div>
          <div className="side-text">Can Make</div>
          <div className="side-text">Your Own</div>
          <div className="side-text">Password</div>
        </>
      );
    } else {
      return (
        <>
          <div className="side-text">One More</div>
          <div className="side-text">Time</div>
        </>
      );
    }
  };

  const conditionalPages = () => {
    if (resetSteps === 0) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <img
            src={require("../img/insiderLogo.svg")}
            alt=""
            style={{ width: "100%" }}
          />
          <br />
          <br />
          <div className="sub-heading" style={{ textAlign: "center" }}>
            Enter The Temporary Credentials You Have Received
          </div>
          <br />
          <form
            onSubmit={handleRegistration}
            style={{ width: "100%", padding: "70px 0px" }}
          >
            <Input
              style={{ padding: "0px 30px" }}
              className="login-input"
              placeholder="Email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <br /> <br />
            <Input
              style={{ padding: "0px 30px" }}
              className="login-input"
              placeholder="Temp Password"
              value={tempPassword}
              onChange={(e) => setTempPassword(e.target.value)}
            />
          </form>
          <div
            style={{
              display: "flex",
              // flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              //   paddingBottom: "30px",
              width: "100%",
            }}
          >
            <Button
              onClick={(e) => window.navigate.back()}
              // loading={loading ? true : false}
              // type="submit"
              type="ghost"
              className="small-ghost-button"
            >
              Go Back
            </Button>

            <div style={{ width: "20px" }}>&nbsp;</div>
            <Button
              loading={loading ? true : false}
              // type="submit"
              // type="primary"
              className="small-primary-button"
              onClick={handleCheckEmail}
            >
              Proceed
            </Button>
          </div>
        </div>
      );
    } else if (resetSteps === 1) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <img
            src={require("../img/Group4.svg")}
            alt=""
            style={{ width: "100%" }}
          />
          <br />
          <br />
          <div className="sub-heading" style={{ textAlign: "center" }}>
            Create A New Password
          </div>
          <br />
          <form
            onSubmit={handleRegistration}
            style={{ width: "100%", padding: "70px 0px" }}
          >
            <Input
              type="password"
              style={{ padding: "0px 30px" }}
              className="login-input"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </form>
          <div
            style={{
              display: "flex",
              // flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              //   paddingBottom: "30px",
              width: "100%",
            }}
          >
            <Button
              onClick={(e) => window.navigate.back()}
              loading={loading ? true : false}
              // type="submit"
              type="ghost"
              className="small-ghost-button"
            >
              Go Back
            </Button>

            <div style={{ width: "20px" }}>&nbsp;</div>
            <Button
              loading={loading ? true : false}
              // type="submit"
              type="primary"
              className="small-primary-button"
              onClick={(e) => setResetSteps(2)}
            >
              Proceed
            </Button>
          </div>
        </div>
      );
    } else if (resetSteps === 2) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <img
            src={require("../img/Group4.svg")}
            alt=""
            style={{ width: "100%" }}
          />
          <br />
          <br />
          <div className="sub-heading" style={{ textAlign: "center" }}>
            Confirm New Password
          </div>
          <br />
          <form
            onSubmit={handleRegistration}
            style={{ width: "100%", padding: "70px 0px" }}
          >
            <Input
              type="password"
              style={{ padding: "0px 30px" }}
              className="login-input"
              placeholder="New Password"
              value={verifyPassword}
              onChange={(e) => setVerifyPassword(e.target.value)}
            />
          </form>
          <div
            style={{
              display: "flex",
              // flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              //   paddingBottom: "30px",
              width: "100%",
            }}
          >
            <Button
              onClick={(e) => window.navigate.back()}
              loading={loading ? true : false}
              // type="submit"
              type="ghost"
              className="small-ghost-button"
            >
              Go Back
            </Button>

            <div style={{ width: "20px" }}>&nbsp;</div>
            <Button
              loading={loading ? true : false}
              // type="submit"
              type="primary"
              className="small-primary-button"
              onClick={handleRegistration}
            >
              Proceed
            </Button>
          </div>
        </div>
      );
    }
  };

  const handleCheckEmail = () => {
    setLoading(true);
    Axios.post(`https://gxauth.apimachine.com/gx/user/auth/login`, {
      email: username,
      password: tempPassword,
    }).then((res) => {
      if (res.data.message === "Reset User Password!") {
        setLoading(false);
        console.log(res.data, "nbckcwq");
        setResetSteps(1);
        setAuthChallengeId(res.data.authChallenge_id);
        setReceivedEmail(res.data.email);
        setReceivedUsername(res.data.username);
        setReceivedChallengeName(res.data.challengeName);
        setReceivedSession(res.data.session);
      }
    });
  };

  const handleLogin = (username, password) => {
    // message.loading("Authenticating...");

    let authenticationData = {
      Username: username,
      Password: password,
    };

    let authdet = new AuthenticationDetails(authenticationData);

    let userData = {
      Username: username,
      Pool: userPool,
    };

    let user = new CognitoUser(userData);

    user.authenticateUser(authdet, {
      onSuccess: function (result) {
        setLoading(false);
        setFullPageLoading(false);
        // console.log(result, "jhvdjqddqwdqd");
        message.destroy();
        message.success("Login Successfull");
        let accessToken = result.accessToken.jwtToken;
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("user_account", username);
        localStorage.setItem("loginObj", JSON.stringify(result));

        //   setTimeout(() => {
        //     value.setLogin(true);
        //   }, 1000);
        var idtoken = result.getIdToken().getJwtToken();
        //idtoken.client_id = client_id;
        localStorage.setItem("token", idtoken);
        navigate("/");

        window.location.reload();
      },

      onFailure: function (err) {
        localStorage.removeItem("token");
        console.log(err);
        //   setTimeout(() => {
        //     value.setLogin(false);
        //   }, 2000);
        message.destroy();
        message.error(err.message, 2);
        if (err.message === "User is not confirmed.") {
          setVisibleVerify(true);
        }
      },
    });
  };

  const handleRegistration = () => {
    setLoading(true);
    setFullPageLoading(true);
    Axios.post(`https://gxauth.apimachine.com/gx/user/auth/login/challenge`, {
      authChallenge_id: authChallengeId,
      email: receivedEmail,
      username: receivedUsername,
      challengeName: receivedChallengeName,
      session: receivedSession,
      newPassword: verifyPassword,
    }).then((res) => {
      if (res.data) {
        Axios.post(`https://connectionsapi.globalxchange.io/register_user`, {
          name: receivedUsername,
          username: receivedUsername,
          bio: "",
          date_of_birth: "",
          profile_image: "",
          cover_image: "",
          email: receivedEmail,
        }).then((res) => {
          if (res.data.status) {
            console.log(res.data, "nbckcwq");
            handleLogin(receivedUsername, verifyPassword);
          } else {
            message.error("Registration Failed");
          }
        });
      }
      // console.log(res.data, "nbckcwq");
      // navigate("/");
    });
  };

  return (
    <>
      {!fullPageLoading ? (
        <Row>
          <Col
            span={8}
            style={{
              background: "#84E188",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {conditionalBannerText()}
          </Col>
          <Col
            span={16}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100vh",
              padding: "200px",
            }}
          >
            {conditionalPages()}
          </Col>
        </Row>
      ) : (
        <div className="full-loading-wrapper">
          <img
            src={require("../img/Group4.svg")}
            alt=""
            className="full-loading-logo"
            style={{ width: "60%" }}
          />
        </div>
      )}
    </>
  );
};

export default AlreadyRegistered;
