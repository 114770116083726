import { message } from "antd";
import isEmail from "validator/lib/isEmail";

import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../../config";

import Axios from "axios";
import React, { useContext, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";
import { useLocation, useNavigate } from "react-router-dom";
import { MyContext } from "../../Context";
import { BankContext } from "../../context/Context";

const NewRegistration = () => {
  const { step, setStep, insiderReg, setInsiderReg } = useContext(MyContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [affEmail, setAffEmail] = useState("");
  const [affCode, setAffCode] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [dob, setDob] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [investorType, setInvestorType] = useState("");
  const [userType, setUserType] = useState("");
  const [countryCode, setCountryCode] = useState("91");

  const { loginEmail, loginPassword } = useLocation();
  // const { loginUsername, loginPassword } = loginData;

  const conditionalStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            setStep={setStep}
            affEmail={affEmail}
            setAffEmail={setAffEmail}
            affCode={affCode}
            setAffCode={setAffCode}
          />
        );
      case 2:
        return <Step2 setStep={setStep} setCountry={setCountry} />;
      case 3:
        return (
          <Step3
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            gxSignupHandler={gxSignupHandler}
            loading={loading}
            setLoading={setLoading}
          />
        );
      case 4:
        return (
          <Step4
            setStep={setStep}
            emailOtp={emailOtp}
            setEmailOtp={setEmailOtp}
            emailVerification={emailVerification}
          />
        );
      case 5:
        return (
          <Step5
            setStep={setStep}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            phoneNo={phoneNo}
            setPhoneNo={setPhoneNo}
            birthDay={birthDay}
            setBirthDay={setBirthDay}
            birthMonth={birthMonth}
            setBirthMonth={setBirthMonth}
            birthYear={birthYear}
            setBirthYear={setBirthYear}
          />
        );
      case 6:
        return <Step6 setStep={setStep} />;
      case 7:
        return (
          <Step7
            setStep={setStep}
            state={state}
            setState={setState}
            city={city}
            setCity={setCity}
            address={address}
            setAddress={setAddress}
            address2={address2}
            setAddress2={setAddress2}
            zipCode={zipCode}
            setZipCode={setZipCode}
            landmark={landmark}
            setLandmark={setLandmark}
          />
        );
      case 8:
        return <Step8 setStep={setStep} setInvestorType={setInvestorType} />;
      case 9:
        return (
          <Step9
            setStep={setStep}
            userType={userType}
            setUserType={setUserType}
            handleInsiderRegistration={handleInsiderRegistration}
          />
        );
      default:
        break;
    }
  };

  const gxSignupHandler = () => {
    setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   setStep(4);
    // }, 2000);
    Axios.post(`https://gxauth.apimachine.com/gx/user/signup`, {
      username: username,
      email: email,
      password: password,
      ref_affiliate: affCode ? affCode : "1",
      account_type: "Personal",
      signedup_app: "IndianInvestor",
    }).then(({ data }) => {
      console.log(data, "datasdddd");
      if (data.status === true) {
        setStep(4);
        setLoading(false);
      } else {
        message.destroy();
        message.error(data.message);
        setLoading(false);
      }
    });
  };

  const emailVerification = () => {
    Axios.post(`https://gxauth.apimachine.com/gx/user/confirm`, {
      email: email,
      code: emailOtp,
    }).then(({ data }) => {
      if (data.status) {
        setStep(5);
      } else {
        message.destroy();
        message.error(data.message);
      }
    });
  };

  const handleLogin = () => {
    // setEmail(loginUsername);
    if (isEmail(loginEmail) && password !== "") {
      // message.loading("Authenticating...");
      setLoading(true);

      Axios.post(`https://gxauth.apimachine.com/gx/user/auth/login`, {
        email: loginEmail,
        password: loginPassword,
      })
        .then(({ data }) => {
          // console.log(data?.user?.loginEmail, "dataaaa");
          if (data.status) {
            // console.log("inside 1st api true");
            Axios.get(
              `https://connectionsapi.globalxchange.io/user_upline_appcode?email=${loginEmail}&upline_appcode=indianinvestor`
            ).then((res) => {
              if (res.data.status) {
                // console.log("inside 2nd api true");
                setLoading(false);
                message.destroy();
                message.success("Login Successfull");
                localStorage.setItem("access_token", data.accessToken);
                localStorage.setItem("user_account", loginEmail);
                localStorage.setItem("loginObj", JSON.stringify(data));
                localStorage.setItem("token", data.idToken);

                navigate("/");
                window.location.reload();
              } else {
                // localStorage.setItem("userId", data?.user?.loginEmail);
                setInsiderReg(true);
                setStep(5);
                navigate("/getstarted");
              }
            });
          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
          console.log(err);
          message.destroy();
          message.error(err.message, 2);
          setLoading(false);
          // if (err.message === "User is not confirmed.") {
          //   setVisibleVerify(true);
          // }
        });
    } else {
      message.destroy();
      message.error("Please enter Email & password");
    }
  };

  const handleInsiderRegistration = (item) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    let data = {
      name: insiderReg ? userData?.name : firstName + " " + lastName,
      email: insiderReg ? localStorage.getItem("nvestBankLoginAccount") : email,
      phone: insiderReg ? "" : countryCode + phoneNo,
      date_of_birth: insiderReg
        ? ""
        : birthDay + "-" + birthMonth + "-" + birthYear,
      country: country ? country : "",
      state: state ? state : "",
      city: city ? city : "",
      address: insiderReg ? "" : address + " " + address2 + " " + landmark,
      zip_code: insiderReg ? "" : zipCode,
      investor_type: investorType,
      user_type: userType ? userType : item,
      bio: "",
      profile_image: insiderReg ? userData?.profile_img : "",
      cover_image: "",
      username: username
        ? username
        : insiderReg
        ? userData?.name?.split(" ").join("") +
          Math.floor(Math.random() * (+99999 - 1)) +
          1
        : firstName + Math.floor(Math.random() * (+99999 - 1)) + 1,
      upline_appcode: "indianinvestor",
    };

    console.log(data, "data");

    Axios.post(`https://connectionsapi.globalxchange.io/register_user`, {
      ...data,
    }).then((res) => {
      if (res.data.status) {
        console.log(res.data, "nbckcwq");
        // handleLogin(receivedUsername, verifyPassword);
        navigate("/login");
        // handleLogin();
        message.success("Registration Successful. Please Login Again.");
      } else {
        message.error("Registration Failed");
      }
    });
  };

  return <>{conditionalStep()}</>;
};

export default NewRegistration;
