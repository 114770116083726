import React, { useState, useEffect } from "react";
import styles from "./steps.module.scss";
import insiderIcon from "../../img/insiderIconColor.svg";
import { message } from "antd";
import Axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";

const Step1 = ({ setStep, affEmail, setAffEmail, affCode, setAffCode }) => {
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    setLoading(true);
    if (affEmail) {
      getAffDataByEmail();
    } else if (affCode) {
      getAffDataByCode();
    }
  };

  const getAffDataByEmail = () => {
    Axios.get(`https://comms.globalxchange.io/user/profile/data/get`, {
      params: {
        email: affEmail,
      },
    }).then(({ data }) => {
      if (data.count > 0) {
        setStep(2);
        setLoading(false);
      } else {
        setLoading(false);
        message.error("Invalid Affiliate Email");
      }
    });
  };

  const getAffDataByCode = () => {
    Axios.get(`https://comms.globalxchange.io/user/profile/data/get`, {
      params: {
        username: affCode,
      },
    }).then(({ data }) => {
      if (data.status) {
        setAffEmail(data.usersData[0].email);
        setStep(2);
        setLoading(false);
      } else {
        setLoading(false);
        message.error("Invalid Affiliate Code");
      }
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img src={insiderIcon} alt="" />
          <div className={styles.formContainer}>
            <div>
              <h1 className={styles.title}>Who Invited You</h1>
              <h4 className={styles.subTitle}>
                IndianInvestor is only accessible via invitation
              </h4>
            </div>
            <div className={styles.form}>
              <div className={styles.lable}>Email</div>
              <div
                className={styles.inputWrapper}
                style={{
                  borderColor:
                    affEmail.length > 3
                      ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/.test(
                          affEmail
                        )
                        ? "green"
                        : "red"
                      : "",
                }}
              >
                <input
                  value={affEmail}
                  onChange={(e) => {
                    setAffCode("");
                    setAffEmail(e.target.value);
                  }}
                  type="text"
                  className={styles.inputStyle}
                  placeholder="friends@gmail.com...."
                />
              </div>
              <div className={styles.separator}>OR</div>
              <div className={styles.lable}>Affiliate Code</div>
              <div className={styles.inputWrapper}>
                <input
                  value={affCode}
                  onChange={(e) => {
                    setAffEmail("");
                    setAffCode(e.target.value);
                  }}
                  type="text"
                  className={styles.inputStyle}
                  placeholder="manisha..."
                />
              </div>
            </div>
            <button
              className={styles.submitButton}
              style={{
                opacity:
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/.test(
                    affEmail
                  ) || affCode
                    ? 1
                    : 0.5,
              }}
              onClick={handleNext}
            >
              {loading ? <LoadingOutlined /> : ""} Proceed
            </button>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.currentPage}>Who Invited You</div>
          <div className={styles.otherPage}>Country Of Residence</div>
          <div className={styles.otherPage}>Login Credentials</div>
          <div className={styles.otherPage}>Verify Email</div>
          <div className={styles.otherPage}>Enter Personal Details</div>
          <div className={styles.otherPage}>Verify Phone Number</div>
          <div className={styles.otherPage}>Enter Address</div>
          <div className={styles.otherPage}>Type Of Investor</div>
          <div className={styles.otherPage}>Type of Insider</div>
        </div>
      </div>
    </>
  );
};

export default Step1;
