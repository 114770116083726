import React, { createContext, useContext, useState } from 'react';

export const MarketContext = createContext({});

export const MarketContextProvider = ({ children }) => {
  const [streamOpen, setStreamOpen] = useState(false);
  return (
    <MarketContext.Provider value={{ streamOpen, setStreamOpen }}>
      {children}
    </MarketContext.Provider>
  );
};

export const useMarketContext = () => useContext(MarketContext);
