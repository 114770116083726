import React, { useContext } from "react";
import classNames from "../../styles/components/privateequity.module.scss";
import Skeleton from "react-loading-skeleton";

//images
import vaultFullLogo from "../../static/images/vaults/vaultFullLogo.svg";
import walletIcon from "../../static/images/vaults/wallet.svg";
import { RiArrowDropDownLine } from "react-icons/ri";
import { switchItems } from "../../static/data/mapdata";
import { BsThreeDotsVertical } from "react-icons/bs";

import dummyIcon from "../../static/dummyIcon.svg";
import { useEffect } from "react";
import axios from "axios";
import { BankContext } from "../../context/Context";
import { useState } from "react";

export const VaultDetailedSection = () => {
  const { email } = useContext(BankContext);
  const [apiDataLoadingShareValue, setApiDataLoadingShareValue] =
    useState(false);
  const [sharetokenBalance, setsharetokenBalance] = useState([]);
  const [privateEquityHoldingValue, setprivateEquityHoldingValue] =
    useState("");
  const [
    privateEquityHoldingValueLoading,
    setprivateEquityHoldingValueLoading,
  ] = useState(false);

  useEffect(() => {
    shareTokenBalance();
    shareTokenNewWorth();
  }, []);

  function shareTokenBalance() {
    // console.log("email token balance", email);
    setApiDataLoadingShareValue(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/user/vault/balances/get?email=${email}&investmentType=EQT`
      )
      .then((response) => {
        // console.log(response?.data, "response sharetoken");
        setsharetokenBalance(response?.data?.vaultsData);
        setApiDataLoadingShareValue(false);
      })
      .catch((error) => {
        setApiDataLoadingShareValue(false);
      });
  }

  function shareTokenNewWorth() {
    // console.log("email share token", email);
    setprivateEquityHoldingValueLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/user/total/funds/data/get?email=${email}&displayCurrency=INR`
      )
      .then((response) => {
        // console.log(response, "share token networth");
        if (response?.data?.investmentTokensData?.pathTypes?.length > 0) {
          //   console.log(
          //     response?.data?.investmentTokensData?.pathTypes,
          //     'net worth'
          //   );
          let item = response?.data?.investmentTokensData?.pathTypes.filter(
            (eachItem) => {
              return eachItem.investmentType === "EQT";
            }
          );
          //   console.log(item, 'item');
          if (item?.length > 0) {
            setprivateEquityHoldingValue(
              item[0]?.investmentType_data?.stats?.type_totalHoldings_dc
            );
            // console.log(
            //   item[0]?.investmentType_data?.stats?.type_totalHoldings_dc,
            //   "type data"
            // );
          }
        }
        // console.log(response, 'net worth full');

        setprivateEquityHoldingValueLoading(false);
      })
      .catch((error) => {
        console.log(error, "networth error");

        setprivateEquityHoldingValueLoading(false);
      });
  }
  return (
    <div
      style={{
        flexGrow: "1",
        display: "flex",
        overflow: "scroll",
      }}
    >
      <div className={classNames.vaultDetailedSection}>
        <div className={classNames.holdingValue}>
          <div>
            {privateEquityHoldingValueLoading ? (
              <Skeleton width={150} height={15} />
            ) : privateEquityHoldingValue ? (
              "₹" + privateEquityHoldingValue?.toFixed(2)
            ) : (
              "₹0"
            )}
          </div>
          <div>Private Equity Holdings Valuation</div>
        </div>
        <div className={classNames.contentDiv}>
          <div className={classNames.tokenSwitch}>
            <div>ShareTokens</div>
            <div>SafeTokens</div>
            <div>CSOPTokens</div>
            <div>ESOPTokens</div>
          </div>
          <div className={classNames.cardsContainer}>
            {apiDataLoadingShareValue ? (
              <>
                <SingleCardLoading />
                <SingleCardLoading />
                <SingleCardLoading />
                <SingleCardLoading />
                <SingleCardLoading />
              </>
            ) : sharetokenBalance?.length > 0 ? (
              sharetokenBalance.map((eachitem, i) => {
                return (
                  <SingleCard
                    eachitem={eachitem}
                    key={eachitem?.tokens[0]?.token_profile_data?.coinName + i}
                  />
                  // <div>data came</div>
                );
              })
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SingleCard = ({ eachitem }) => {
  //   console.log(eachitem, 'eactitem');
  return (
    <div
      className={classNames.singleCard}
      style={{
        // height: "10rem",
        width: "17vw",
      }}
    >
      <div className={classNames.topDiv}>
        <div className={classNames.itemDiv}>
          <img
            src={eachitem?.tokens[0]?.token_profile_data?.coinImage}
            alt="dummyIcon"
          />
          <div>{eachitem?.tokens[0]?.token_profile_data?.coinName}</div>
        </div>
        <div className={classNames.dotsIcon}>
          <BsThreeDotsVertical />
        </div>
      </div>
      <div className={classNames.bottomDiv}>
        <div>
          {eachitem?.tokens[0]?.value + " " + eachitem?.tokens[0]?.token}
        </div>
        <div>
          (
          {eachitem?.tokens[0]?.asset +
            eachitem?.tokens[0]?.value_in_asset?.toFixed(2)}
          )
        </div>
      </div>
    </div>
  );
};

const SingleCardLoading = ({ eachitem }) => {
  //   console.log(eachitem, 'eactitem');
  return (
    <div className={classNames.singleCard}>
      <div className={classNames.topDiv}>
        <div className={classNames.itemDiv}>
          <Skeleton width={120} height={120} circle />
          <Skeleton width={100} height={15} />
        </div>
        <div className={classNames.dotsIcon}>
          <BsThreeDotsVertical />
        </div>
      </div>
      <div className={classNames.bottomDiv}>
        <div>
          <Skeleton width={100} height={15} />
        </div>
        <div>
          <Skeleton width={100} height={15} />
        </div>
      </div>
    </div>
  );
};
