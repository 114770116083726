import React, { useEffect } from "react";
import styles from "./steps.module.scss";

import OtpInput from "react-otp-input";

import insiderIcon from "../../img/insiderIconColor.svg";
import checkRounded from "../../img/checkRounded.svg";

const Step6 = ({ setStep, otp, setOtp }) => {
  useEffect(() => {
    console.log("Step 1 mounted", window.location.href.split("/").pop());
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img src={insiderIcon} alt="" />
          <div className={styles.formContainer}>
            <div>
              <h1 className={styles.title}>Verify Your Number</h1>
              <h4 className={styles.subTitle}>
                Please enter the six digit code that we just sent to *********
              </h4>
            </div>
            <div className={styles.form}>
              <div className={styles.otpView}>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                  shouldAutoFocus
                  containerStyle={styles.otpInputWrapper}
                  inputStyle={styles.otpInput}
                />
              </div>
              <div style={{ fontSize: "1.7vh" }}>
                Resend Code In{" "}
                <span style={{ fontWeight: "bold" }}>30 Seconds</span>
              </div>
            </div>
            <button
              className={styles.submitButton}
              style={{ marginTop: "7vh" }}
              onClick={(e) => setStep(7)}
            >
              Proceed
            </button>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.finishedPage}>
            <div>Who Invited You</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Country Of Residence</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Login Credentials</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Verify Email</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Enter Personal Details</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.currentPage}>Verify Phone Number</div>
          <div className={styles.otherPage}>Enter Address</div>
          <div className={styles.otherPage}>Type Of Investor</div>
          <div className={styles.otherPage}>Type of Insider</div>
        </div>
      </div>
    </>
  );
};

export default Step6;
