import React, { useState } from "react";

//assets
import leftArrowIcon from "../../assets/images/dropdownleftarrow.svg";
import rightArrowIcon from "../../assets/images/dropdownrightarrow.svg";

const ExplorePost = () => {
  const [explorePost, setExplorePost] = useState([
    "Your Subscriptions",
    "Financial Media",
    "Your Investments",
  ]);
  const [currentExplore, setCurrentExplore] = useState(0);

  function changeExplore(condition) {
    if (condition === "add") {
      if (currentExplore === 2) {
        setCurrentExplore(0);
      } else {
        setCurrentExplore((props) => props + 1);
      }
    } else {
      if (currentExplore === 0) {
        setCurrentExplore(2);
      } else {
        setCurrentExplore((props) => props - 1);
      }
    }
  }

  return (
    <div className="explorepost">
      <div>Explore Posts From</div>
      <div className="changePostType">
        <img
          src={leftArrowIcon}
          alt="leftArrowIcon"
          onClick={() => changeExplore("sub")}
        />
        <span>{explorePost[currentExplore]}</span>
        <img
          src={rightArrowIcon}
          alt="rightArrowIcon"
          onClick={() => changeExplore("add")}
        />
      </div>
    </div>
  );
};

export default ExplorePost;
