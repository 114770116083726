/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { BankContext } from "../context/Context";
import NetWorthChart from "../components/NetWorthPage/NetWorthChart";
import NetWorthCards from "../components/NetWorthPage/NetWorthCards";
import IceSidebar from "../components/IceSidebar/IceSidebar";
import "../styles/pages/netWorthPage.scss";

function NetWorthPage({ match }) {
  const [segment, setSegment] = useState(null);

  const { email, iceSidebarOpen } = useContext(BankContext);

  const [netWorthMobileOpen, setNetWorthMobileOpen] = useState(false);

  return (
    <div
      className="vault-content"
      style={{
        flexGrow: "1",
        display: "flex",
        overflow: "scroll",
      }}
    >
      <div
        className={`netWorthLayout ${netWorthMobileOpen} transaction-layout`}
        // style={{ zoom: "0.8" }}
      >
        <div className={`netWorthContent ${iceSidebarOpen}`}>
          <NetWorthChart
            setNetWorthMobileOpen={setNetWorthMobileOpen}
            match={match}
            segment={segment}
            setSegment={setSegment}
          />
          <NetWorthCards segment={segment} setSegment={setSegment} />
        </div>
        {iceSidebarOpen ? <IceSidebar /> : ""}
      </div>
    </div>
  );
}

export default NetWorthPage;
