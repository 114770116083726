import React from "react";
import classNames from "../../styles/components/statusbar.module.scss";

const StatusBar = () => {
  return (
    <div className={classNames.statusBar}>
      <div>Net-worth: $0.00</div>
      <div>Liquid: $0.00</div>
      <div>Invested: $0.00</div>
      <div>Net P&L: $0.00</div>
      <div>Equity: $0.00</div>
    </div>
  );
};

export default StatusBar;
