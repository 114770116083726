import React, { useContext } from "react";
import classNames from "../../styles/components/dropdown.module.scss";

//assets
import rightArrow from "../../assets/images/dropdownrightarrow.svg";
import { dropdownBottom, dropdowntop } from "../../assets/data/dropdown";
import { MyContext } from "../../Context";
import { Link } from "react-router-dom";

const Dropdown = () => {
  const { showDropdown } = useContext(MyContext);

  return (
    <div
      className={classNames.dropdown}
      style={{ display: showDropdown ? "" : "none" }}
    >
      <div>
        {dropdowntop.map((eachItem) => {
          return <DropdownElement {...eachItem} />;
        })}
      </div>
      <div>
        {dropdownBottom.map((eachItem) => {
          return <DropdownElement {...eachItem} />;
        })}
      </div>
    </div>
  );
};

export default Dropdown;

const DropdownElement = ({ name, icon }) => {
  const { setShowDropdown } = useContext(MyContext);

  function logout() {
    localStorage.clear();
    window.location.reload();
  }
  function closeDropdown() {
    setShowDropdown(false);
  }

  return (
    <Link
      className={classNames.eachDropdownElement}
      onClick={
        name === "Logout"
          ? logout
          : name === "Saved" ||
            name === "Ideas" ||
            name === "Vaults" ||
            name === "Portfolio"
          ? closeDropdown
          : ""
      }
      to={
        name === "Saved"
          ? "/saved"
          : name === "Ideas"
          ? "/brain"
          : name === "Vaults"
          ? "/vaults"
          : name === "Portfolio"
          ? "/portfolio"
          : "#"
      }
    >
      <div>
        <img src={icon} alt={name} />
        <span>{name}</span>
      </div>
      <div>
        <img src={rightArrow} alt="rightArrow" />
      </div>
    </Link>
  );
};
