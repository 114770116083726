import React, { useContext, useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { BankContext } from "../../context/Context";
import { useAppContextDetails } from "../../context/AppContext";
import { useCoinContextData } from "../../context/CoinContext";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { SelectFooters } from "./SelectFooter";
import { SideBarMenu } from "./SideBarMenu";

function LayoutSidebar({
  active,
  chatOn,
  setChatOn,
  adminSidebar,
  setAdminSidebar,
}) {
  const navigate = useNavigate();
  const { email, sidebarCollapse, setSidebarCollapse, closeSidebars, admin } =
    useContext(BankContext);
  const { coinActionEnabled, coinAction, addBankingEnabled } =
    useCoinContextData();

  const [openSettings, toggleSettings] = useState(false);
  const [toast, setToast] = useState(false);

  const { appName, appCode, appLogo, appFullLogo, appColorCode } =
    useAppContextDetails();

  const menuEndRef = useRef(null);

  const scrollToBottom = () => {
    if (menuEndRef && menuEndRef.current)
      menuEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (active.includes("transactions")) {
      scrollToBottom();
    }
  }, [active]);

  const togglingIcon = sidebarCollapse ? faAngleRight : faAngleLeft;

  useEffect(() => {
    if (toast) setTimeout(() => setToast(false), 2000);
  }, [toast]);

  const isSecondaryMenu =
    openSettings || coinActionEnabled || addBankingEnabled;

  return (
    <div
      className={`side-bar d-flex flex-column ${
        sidebarCollapse ? "collapse" : "expand"
      }`}
    >
      {email ? (
        <div className={`profile d-flex ${!adminSidebar && "clickable"}`}>
          <img
            className="clickable"
            src={sidebarCollapse ? appLogo : appFullLogo}
            onClick={() => {
              if (admin) {
                setAdminSidebar(true);
                setChatOn(false);
              } else {
                navigate("/vault");
              }
            }}
          />
          {coinAction[0] === "Menu" && !addBankingEnabled && (
            <div
              className="toggle"
              style={{
                background: appColorCode,
              }}
              onClick={() => setSidebarCollapse(!sidebarCollapse)}
            >
              <FontAwesomeIcon icon={togglingIcon} />
            </div>
          )}
        </div>
      ) : (
        <div className="profile d-flex">
          <img
            className="clickable"
            src={appLogo}
            alt="Icon Logo"
            onClick={() => {
              closeSidebars();
              navigate(`/${appCode}`);
            }}
          />
          <div className="col my-auto">
            <h5>{appName}&nbsp;</h5>
            <div
              className="getStartedBtn"
              onClick={() => {
                navigate("/signup");
              }}
            >
              Get Started
            </div>
          </div>
          <div
            className="toggle"
            onClick={() => setSidebarCollapse(!sidebarCollapse)}
          >
            <FontAwesomeIcon icon={togglingIcon} />
          </div>
        </div>
      )}
      <Scrollbars
        className="menu-scrl"
        autoHide
        renderView={(props) => (
          <div {...props} className="menu-side" id="preventScroll" />
        )}
      >
        <div className="spacer" />
        <SideBarMenu
          openSettings={openSettings}
          active={active}
          chatOn={chatOn}
          setChatOn={setChatOn}
        />
        <div ref={menuEndRef} className="spacer" />
      </Scrollbars>
      {toast && (
        <ToastMessage
          textOne="Copied the Coin Address"
          onClose={() => setToast(false)}
        />
      )}
      <SelectFooters
        toggleSettings={toggleSettings}
        setToast={setToast}
        isSecondaryMenu={isSecondaryMenu}
        openSettings={openSettings}
        setAdminSidebar={setAdminSidebar}
      />
    </div>
  );
}

export default LayoutSidebar;
