import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import classNames from "../marketsPage.module.scss";
import angleDown from "../../../static/images/angleDown.svg";
import { FormatCurrency, FormatNumber } from "../../../utils/FunctionTools";
import { useAppContextDetails } from "../../../context/AppContext";
import clock from "../../../static/images/marketsSidebar/clock.svg";
import { useMMList } from "../../../queryHooks";

function MarketTableMoneyMarkets({
  streamOpen,
  setCoinSelected,
  assetClass,
  active,
  search,
}) {
  const { data: mmList = [], isLoading } = useMMList();
  const navigate = useNavigate();
  const [filterOpen, setFilterOpen] = useState(false);
  const { appCurrencySymbol } = useAppContextDetails();

  return (
    <div className={classNames.marketTable}>
      <div
        className={`${classNames.header} ${classNames[!streamOpen]} ${
          classNames[active ? "active" : ""]
        }`}
      >
        <div className={classNames.assets}>Asset</div>
        <div className={classNames.price}>Price</div>
        <div className={classNames.volume}>Daily Rate</div>
        <div className={classNames.volume}>Monthly Rate</div>
        <div className={classNames.volume}>Annual Rate</div>
        <div className={classNames.roiPercent}>24 Hr Change</div>
        <img
          className={`${classNames.btnFilter} ${
            classNames[filterOpen.toString()]
          }`}
          src={angleDown}
          alt=""
          onClick={() => setFilterOpen(!filterOpen)}
        />
      </div>
      {filterOpen && (
        <div className={classNames.filtersWrap}>
          <div className={classNames.filters}>
            <div className={classNames.filter}>
              <img src={clock} alt="" />
              <span>24 Hrs</span>
            </div>
            <div className={classNames.filter}>
              <img
                src={
                  "https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/rupee.png"
                }
                alt=""
              />
              <span>{appCurrencySymbol}</span>
            </div>
            <div className={classNames.filter}>
              + <span>Add Filter</span>
            </div>
          </div>
        </div>
      )}
      <div className={classNames.marketsList}>
        {isLoading
          ? Array(8)
              .fill("")
              .map((_, i) => (
                <div
                  className={`${classNames.marketItem}  ${
                    classNames[!streamOpen]
                  }`}
                  key={i}
                >
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                </div>
              ))
          : mmList
              ?.filter(
                (coin) =>
                  coin?.coin_metdata?.coinName
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  coin?.coin_metdata?.coinSymbol
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )
              ?.map((coin, i) => (
                <div
                  className={`${classNames.marketItem}  ${
                    classNames[!streamOpen]
                  }`}
                  key={coin._id}
                  onClick={() => {
                    setCoinSelected(coin?.coin_metdata);
                    navigate(
                      `/listings/${assetClass.name}/${coin?.coin_metdata?.coinSymbol}`
                    );
                  }}
                >
                  {/* Asset */}
                  <div className={classNames.assets}>
                    <img src={coin?.coin_metdata?.coinImage} alt="" />
                    <span className={classNames.name}>
                      {coin?.coin_metdata?.coinName}
                    </span>
                  </div>
                  {/* Price */}
                  <div className={classNames.price}>
                    {appCurrencySymbol}&nbsp;
                    {FormatCurrency(coin?.app_price_dc, appCurrencySymbol)}
                  </div>
                  {/* Daily Rate */}
                  <div className={classNames.volume}>
                    {FormatNumber(coin?.interest_rate, 4)}%
                  </div>
                  {/* Monthly Rate */}
                  <div className={classNames.volume}>
                    {FormatNumber(coin?.monthly_interest_rate, 4)}%
                  </div>
                  {/* Annual Rate */}
                  <div className={classNames.volume}>
                    {FormatNumber(coin?.yearly_interest_rate, 4)}%
                  </div>
                  {/* 24 Hr Rate Change */}
                  <div
                    className={`${classNames.roiPercent} ${
                      classNames[coin?.changeData?.interestRate?._24hr < 0]
                    }`}
                  >
                    {FormatNumber(coin?.changeData?.interestRate?._24hr, 2)}%
                  </div>
                </div>
              ))}
      </div>
    </div>
  );
}

export default MarketTableMoneyMarkets;
