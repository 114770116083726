import {
  Row,
  Col,
  Input,
  Space,
  Button,
  Menu,
  Dropdown,
  Avatar,
  Popover,
  Divider,
  Tag,
} from "antd";
import React, { useEffect, useState, useRef, useContext } from "react";
import {
  SearchOutlined,
  UserOutlined,
  CaretDownOutlined,
  DownOutlined,
  BellOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import {
  Route,
  useNavigate,
  Switch,
  HashRouter,
  Link,
  useLocation,
} from "react-router-dom";
import { gql } from "apollo-boost";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import prof from "../img/user.svg";
import { MyContext } from "../Context";
import Axios from "axios";
import classNames from "../styles/components/navbar.module.scss";

//assets
import { RxTriangleUp } from "react-icons/rx";
import notificationIcon from "../assets/images/notification.svg";
import downArrowIcon from "../assets/images/downarrow.svg";
import searchIcon from "../assets/images/search.svg";
import { MARKET_ASSET_CLASSES } from "../config/constants";
import { GetSortOrder } from "../utils/FunctionTools";

const USER_INFO = gql`
  query getUser($user_id: ID, $uplineAppcode: String) {
    get_user(data: { user_id: $user_id, upline_appcode: $uplineAppcode }) {
      id
      name
      username
      email
      bio
      date_of_birth
      profile_image
      cover_image
      following_count
      my_follower_count
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
      long_bio
      user_type_id
      city
      achievements {
        id
        title
        description
        endorsed_users
      }
    }
  }
`;

// const ALL_TRENDS = gql`
//   {
//     trends {
//       id
//       name
//       post_count
//     }
//   }
// `;

const Nav = () => {
  const {
    tokenValid,
    setTrendClicked,
    navSearch,
    setNavSearch,
    setShowDropdown,
  } = useContext(MyContext);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [showDropdownL, setShowDropdownL] = useState(false);
  const [query, setQuery] = useState("");

  const [count, setCount] = useState(0);
  const handleMenuClick = () => {};
  const navigate = useNavigate();

  const [getAllUsersApi, setGetAllUsersApi] = useState([]);
  const [allUsername, setAllUsername] = useState([]);

  useEffect(() => {
    Axios.get(`https://connectionsapi.globalxchange.io/get_all_users_api`).then(
      (res) => {
        setGetAllUsersApi([...res.data.names]);
        setAllUsername([...res.data.usernames]);
        // setAllUserData()
      }
    );
  }, []);

  var userInfo = useQuery(USER_INFO, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
      uplineAppcode: "indianinvestor",
    },
  });

  // let filteredUsers = getAllUsers.data
  //   ? getAllUsers.data.get_all_users.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return (item.name + item.username).toLowerCase().indexOf(lowquery) >= 0;
  //     })
  //   : "";

  getAllUsersApi.filter((query) => allUsername.includes(query));

  let filteredUsersApi = getAllUsersApi
    ? getAllUsersApi.filter((item) => {
        const lowquery = query.toLowerCase();
        return item?.toLowerCase()?.indexOf(query) >= 0;
      })
    : "";

  // let filteredUsernames = allUsername
  //   ? allUsername.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return item.toLowerCase().indexOf(query) >= 0;
  //     })
  //   : "";

  //this is new
  // let filteredTrends = allTrends.data
  //   ? allTrends.data.trends.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return item.name.toLowerCase().indexOf(lowquery) >= 0;
  //     })
  //   : "";

  // let filteredTrendsApi = allTrends.data
  //   ? allTrends.data.trends.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return item.name.toLowerCase().indexOf(lowquery) >= 0;
  //     })
  //   : "";

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        console.log("Close logout");
        setShowDropdownL(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //   alert("You clicked outside of me!");
          setShowDropdownL(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  //   useEffect(() => {
  //     console.log("kjwekjfbwkefbw", showDropdown, count);

  //     window.addEventListener("click", () => {
  //       if (count < 2) {
  //         setCount(count + 1);
  //       } else {
  //         setCount(0);
  //       }
  //     });
  //   });

  //   useEffect(() => {
  //     if (showDropdown) {
  //       if (count === 2) {
  //         setShowDropdown(false);
  //         setCount(0);
  //       }
  //     }
  //   }, [count]);

  const conditionalNav = () => {
    if (window.location.href.split("/").pop() !== "login") {
      return (
        <>
          <Row
            style={{
              padding: "15px 20px",
              borderBottom: "0.5px solid #EDEDED",
              justifyContent: "space-between",
              height: "65px",
            }}
          >
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "12.5%",
                maxWidth: "12.5%",
              }}
            >
              <Space>
                <div
                  style={{ width: "139px" }}
                  onClick={(e) => {
                    navigate("/home");
                    setTrendClicked(false);
                    setShowDropdown(false);
                  }}
                >
                  <img
                    className="hoverstyle1"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      // marginTop: "-10px",
                    }}
                    src={require("../img/insiderLogo.svg")}
                    alt=""

                    // onClick={(e) => navigate("/home")}
                  />
                </div>
                {/* &nbsp;&nbsp;
                {window.innerWidth > 900 ? (
                  <Popover
                    // placement="bottom"
                    placement="bottomLeft"
                    content={
                      <div style={{ width: "500px" }}>
                        {query.length > 0 ? (
                          <>
                            {filteredUsersApi.map((item, index) => {
                              if (index < 14) {
                                return (
                                  <>
                                    <Link
                                      to={`/${
                                        allUsername[
                                          getAllUsersApi.indexOf(item)
                                        ]
                                      }`}
                                      style={{ color: "#000000a6" }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <Link
                                          to={`/${
                                            allUsername[
                                              getAllUsersApi.indexOf(item)
                                            ]
                                          }`}
                                          style={{ color: "#000000a6" }}
                                        >
                                          <div
                                            onClick={(e) => {
                                              localStorage.setItem(
                                                "user_profile_email",
                                                item.email
                                              );
                                            }}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>
                                              {item.profile_image ? (
                                                <Avatar
                                                  className="shadow-down"
                                                  src={item.profile_image}
                                                  size={38}
                                                />
                                              ) : (
                                                <Avatar src={prof} size={38} />
                                              )}
                                            </div>
                                            &nbsp;&nbsp;
                                            <div
                                              onClick={(e) => {
                                                localStorage.setItem(
                                                  "user_profile_email",
                                                  item.email
                                                );
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                {item}
                                              </span>
                                              <br />
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    </Link>
                                  </>
                                );
                              }
                            })}
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100px",
                            }}
                          >
                            Please type something ...
                          </div>
                        )}
                      </div>
                    }
                    trigger="focus"
                  >
                    {getAllUsersApi ? (
                      getAllUsersApi.length > 0 ? (
                        <Input
                          type="text"
                          value={query}
                          className="nav-search"
                          placeholder="Search Users or Trends"
                          suffix={<SearchOutlined />}
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Popover>
                ) : (
                  ""
                )} */}
              </Space>
            </Col>
            {/* <Col span={window.innerWidth > 900 ? 12 : 10}>

            </Col> */}
            {window.innerWidth > 900 ? (
              <SearchNav
                filteredUsersApi={filteredUsersApi}
                navSearch={navSearch}
                setNavSearch={setNavSearch}
                allUsername={allUsername}
                getAllUsersApi={getAllUsersApi}
                setQuery={setQuery}
                query={query}
              />
            ) : (
              ""
            )}

            {tokenValid ? (
              // <Col span={8} style={{ textAlign: "right" }}>
              //   <Space align="center" size="middle" className="spaceitem">
              //     {/* <Button
              //       className="nav-button1"
              //       onClick={(e) => navigate("/home")}
              //     >
              //       <span className="button1text">Create</span>
              //     </Button>
              //     <Button className="nav-button2">
              //       <span className="button2text">Refer</span>
              //     </Button> */}

              //     {/* <div
              //       ref={wrapperRef}
              //       className="nav-userbutton"
              //       onClick={(e) => setShowDropdown(!showDropdown)}
              //       style={{
              //         zIndex: 999,
              //         border: showDropdown ? "none" : "",
              //       }}
              //     >
              //       <div style={{ display: "flex", alignItems: "center" }}>
              //         <img
              //           style={{
              //             borderRadius: "50%",

              //             width: "35px",
              //             height: "35px",
              //           }}
              //           src={
              //             userInfo.data
              //               ? userInfo.data.get_user.profile_image
              //                 ? userInfo.data.get_user.profile_image
              //                 : prof
              //               : prof
              //           }
              //           alt=""
              //         />
              //         &nbsp;&nbsp;
              //         <div className="nav-profile-button">
              //           {userInfo.data
              //             ? userInfo.data.get_user.name.length > 10
              //               ? `${userInfo.data.get_user.name.substring(
              //                   0,
              //                   10
              //                 )}..`
              //               : userInfo.data.get_user.name
              //             : ""}
              //         </div>
              //       </div>

              //       <div onClick={(e) => e.preventDefault()}>
              //         <CaretDownOutlined style={{ color: "#999999" }} />
              //       </div>
              //     </div> */}

              //     {/* <BellOutlined style={{ fontSize: "20px", color: "#999999" }} /> */}
              //   </Space>
              // </Col>
              <NavRightElement userInfo={userInfo} />
            ) : (
              <Col
                span={8}
                style={{
                  minWidth: "12.5%",
                  maxWidth: "12.5%",
                  textAlign: "right",
                }}
              >
                <Space align="center" size="middle" className="spaceitem">
                  <Link to="/login">
                    <Button className="nav-button1">
                      <span className="button1text">Login / Register</span>
                    </Button>
                  </Link>
                  {/* <BellOutlined style={{ fontSize: "20px", color: "#999999" }} /> */}
                </Space>
              </Col>
            )}
          </Row>
          {showDropdownL ? (
            <Row
              style={{
                padding: "0px 20px",
                position: "absolute",
                right: "142px",
                top: "14px",
                zIndex: 1,
              }}
            >
              <Col span={16} style={{ textAlign: "right" }}></Col>
              <Col span={8} style={{ textAlign: "right" }}>
                {tokenValid ? (
                  <Space align="center" size="middle">
                    <Button className="nav-button1" style={{ display: "none" }}>
                      <span className="button1text">Create</span>
                    </Button>
                    <Button className="nav-button2" style={{ display: "none" }}>
                      <span className="button2text">Refer</span>
                    </Button>
                    <div
                      ref={wrapperRef}
                      className="nav-userbutton-expand"
                      onClick={(e) => setShowDropdownL(!showDropdownL)}
                    >
                      <div
                        onClick={(e) => {
                          localStorage.clear();
                          window.location.reload();
                        }}
                        className="logoutText"
                        style={{
                          width: "100%",
                          borderRadius: "0px 0px 25px 25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "39px",
                          cursor: "pointer",
                        }}
                      >
                        Logout
                      </div>
                    </div>
                    {/* <BellOutlined style={{ fontSize: "20px", color: "#999999" }} /> */}
                  </Space>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      );
    }
  };

  return (
    <>{window.location.href.split("/").pop() !== "" ? conditionalNav() : ""}</>
  );
};

export default Nav;

const NavRightElement = ({ userInfo }) => {
  const { showDropdown, setShowDropdown } = useContext(MyContext);
  // console.log(userInfo?.data, "userInfo.data");
  return (
    <div className={classNames.navRightElement}>
      <div>
        <img src={notificationIcon} alt="notificationIcon" />
      </div>
      <div onClick={() => setShowDropdown(!showDropdown)}>
        <img
          src={downArrowIcon}
          alt="downArrowIcon"
          style={{ transform: showDropdown ? "rotate(180deg)" : "" }}
        />
      </div>
      <div>
        <Link
          to={
            userInfo?.data?.get_user?.username
              ? "/" + userInfo?.data?.get_user?.username
              : "/#"
          }
        >
          <img
            src={
              userInfo.data
                ? userInfo?.data?.get_user?.profile_image
                  ? userInfo?.data?.get_user?.profile_image
                  : prof
                : prof
            }
            alt="profile"
          />
        </Link>
      </div>
    </div>
  );
};

const SearchNav = ({
  filteredUsersApi,
  navSearch,
  setNavSearch,
  allUsername,
  getAllUsersApi,
  setQuery,
  query,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [assetClass, setAssetClass] = useState(MARKET_ASSET_CLASSES[0]);

  return (
    <div
      className={classNames.searchNav}
      onClick={() => {
        if (!location?.pathname?.includes("/listings")) {
          setNavSearch(true);
        }
      }}
      style={{
        justifyContent: location?.pathname?.includes("/listings")
          ? "space-between"
          : "",
      }}
    >
      <img
        src={searchIcon}
        alt="searchIcon"
        className={classNames.searchIcon}
        onClick={() => {
          if (location?.pathname?.includes("/listings")) {
            setNavSearch(true);
          }
        }}
      />
      <input
        type="text"
        placeholder="Search...."
        className={classNames.searchInput}
        onChange={(e) => setQuery(e.target.value)}
        style={{
          width:
            location?.pathname?.includes("/listings") ||
            location?.pathname?.includes("/home")
              ? "60%"
              : "",
          maxWidth:
            location?.pathname?.includes("/listings") ||
            location?.pathname?.includes("/home")
              ? "60%"
              : "",
        }}
        onClick={() => {
          if (
            location?.pathname?.includes("/listings") ||
            location?.pathname?.includes("/home")
          ) {
            setNavSearch(true);
          }
        }}
      />
      {location?.pathname?.includes("/listings") ? (
        <div className={classNames.buttonsWrapper}>
          {MARKET_ASSET_CLASSES.sort(GetSortOrder("marketOrder")).map(
            (assetClassCard, i) => (
              <Link
                key={i}
                className={
                  assetClassCard === assetClass ? classNames.selectedType : ""
                }
                onClick={() => {
                  if (assetClassCard.marketEnable) {
                    setAssetClass(assetClassCard);
                  }
                }}
                to={
                  assetClassCard?.marketEnable
                    ? `/listings/${assetClassCard.name}`
                    : "/#"
                }
              >
                {assetClassCard?.insiderName}
              </Link>
            )
          )}
          {/* <Link to="#">Private Equities</Link>
          <Link to="#">Fixed Income</Link>
          <Link to="#">Real Estate</Link> */}
        </div>
      ) : location?.pathname?.includes("/vaults") ? (
        <div className={classNames.buttonsWrapper}>
          <div className={classNames.selectedType}>Private Equities</div>
          <div>Corporate Bonds</div>
          <div>Real Estate</div>
          <div>Mortgages</div>
        </div>
      ) : (
        <div className={classNames.buttonsWrapper}>
          <div>Companies</div>
          <div>People</div>
          <div>Stocks</div>
          <div>Crypto</div>
          <div>Properties</div>
        </div>
      )}
      <div
        className={classNames.dropDown}
        style={{ display: navSearch ? "" : "none" }}
      >
        <RxTriangleUp />
        {filteredUsersApi?.length > 0 && query.length > 0 ? (
          filteredUsersApi?.map((eachUser) => {
            return (
              <Link
                className={classNames.eachUser}
                to={`/${allUsername[getAllUsersApi.indexOf(eachUser)]}`}
              >
                <img
                  src="https://insider.indianinvestor.com/static/media/user.5cdd10ce.svg"
                  alt="profile pic"
                />
                <span>{eachUser}</span>
              </Link>
            );
          })
        ) : (
          <div>Search user here..</div>
        )}
      </div>
    </div>
  );
};
