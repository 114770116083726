export const homeRight = [
  "Trending Topics",
  "Winners & Flops",
  "Raindrops",
  "Calendar",
];

export const cardElements = [
  "Companies",
  "People",
  "Stocks",
  "Crypto",
  "Properties",
  "Offerings",
  "Signals",
  "Deals",
];
