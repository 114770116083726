import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Row, Col, Input, Button, Form, message, Icon, Modal } from "antd";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../config";
import { MyContext } from "../Context";
import isEmail from "validator/lib/isEmail";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { gql } from "apollo-boost";
import { BankContext } from "../context/Context";

const GET_USER = gql`
  query ($email: ID!) {
    get_user(data: { email: $email }) {
      id
      username
      name
      bio
      email
      profile_image
      cover_image
    }
  }
`;

const Login = () => {
  const { step, setStep, insiderReg, setInsiderReg } = useContext(MyContext);
  const { setEmail, setIdToken, setAccessToken } = useContext(BankContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [rUsername, setRusername] = useState("");
  const [rEmail, setRemail] = useState("");
  const [rPassword, setRpassword] = useState("");
  const [rPassword1, setRpassword1] = useState("");
  const [visibleVerify, setVisibleVerify] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  const [errorMsg, setErrorMsg] = useState("");

  //   {
  //     "status": true,
  //     "message": "Successfully Logged In",
  //     "user": {
  //         "_id": "5f16ac9503505723f54cd55a",
  //         "name": "ichiranjeeb4",
  //         "email": "chiranjeeb.business@gmail.com",
  //         "affiliate_id": "e2d1ea1b3115f59ed210d3fed51cb73593d8c2af",
  //         "ref_affiliate": "",
  //         "username": "ichiranjeeb4",
  //         "cognito_username": "ichiranjeeb4",
  //         "client_app": "GX",
  //         "signedup_app": "GX",
  //         "profile_img": ""
  //     },
  //     "device_key": "us-east-2_80d4658f-dc99-41a6-a32b-391e546eff47",
  //     "tokenExpiresIn": 3600,
  //     "accessToken": "eyJraWQiOiJpZFpEU25xXC9pamx0dmdWU25yQWZVWG1MSEMzcFlpRWdZXC8xZVFoenJLVXc9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwYzBmNzgyZi1lM2U1LTQzM2QtODkxNC04MjA4MzU3YjA0OWIiLCJkZXZpY2Vfa2V5IjoidXMtZWFzdC0yXzgwZDQ2NThmLWRjOTktNDFhNi1hMzJiLTM5MWU1NDZlZmY0NyIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0yX0Y0U1JtRTRSRyIsImNsaWVudF9pZCI6IjE4NWhxczcybGloZG01a2ZiYWZodDZoYnFwIiwiZXZlbnRfaWQiOiJkMzgyMjc2Mi1hMGU5LTQ0ODQtODE1NS0yZTE3ZDJjZTA0MmEiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIiwiYXV0aF90aW1lIjoxNjc4NzczOTI3LCJleHAiOjE2Nzg3Nzc1MjYsImlhdCI6MTY3ODc3MzkyNywianRpIjoiZjZlZjFkMTItMjhiNy00NGMzLTkzZDMtNjNhYjZlNzEzZTNhIiwidXNlcm5hbWUiOiJpY2hpcmFuamVlYjQifQ.Q4Legli203AmHxltVnb6Er9HBgc8QXcllwm8N1fMBjQ2s1M07SvItaQvUCA_RLdifzNtkQnzdT_ZQqqx1Rws_53SX6FmKaKx0UoKRStXHO86T64q5sGrwKnb61KjAO6edYXa5fgXdIkW4pFYhXNPpydLQifndLJTsEqfuyO9SLH6MxswojxjMH47u4K7gGRyXQJXIzQZmBwE7Uu_M03B-XNTQQ5VpJomWKd0JnLPly451MAFS0kdf6bOARX0gynxHTSC4RWWjbgCJdoX78vj0wXHE9_OImWDcUKGK5XW8kOxYz5tLJN1eVFMvK7U9K2GTNpdhCd0yrCB6WfWRYB9kg",
  //     "idToken": "eyJraWQiOiIrUm1TZjBCRzBYbjRvNStBSDVtOSs4RDVzRTdxQnFPbEphOW5lK1VoZkxrPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIwYzBmNzgyZi1lM2U1LTQzM2QtODkxNC04MjA4MzU3YjA0OWIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tXC91cy1lYXN0LTJfRjRTUm1FNFJHIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwiY29nbml0bzp1c2VybmFtZSI6ImljaGlyYW5qZWViNCIsImF1ZCI6IjE4NWhxczcybGloZG01a2ZiYWZodDZoYnFwIiwiZXZlbnRfaWQiOiJkMzgyMjc2Mi1hMGU5LTQ0ODQtODE1NS0yZTE3ZDJjZTA0MmEiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY3ODc3MzkyNywiZXhwIjoxNjc4Nzc3NTI2LCJpYXQiOjE2Nzg3NzM5MjcsImVtYWlsIjoiY2hpcmFuamVlYi5idXNpbmVzc0BnbWFpbC5jb20ifQ.LokUY4QKTtskxGMnQ4wZqGEI5WJtWFJhSkcZ3ZwJXd0TMQlIMvvOH3QscJWM0xzjiwv5F5UEDku1GVPNn3TjibmmVcinp5BT0l8CIAvj54nl-j8jZ5OPOVasaXUPyi_syckM9_NsqBAGPpkt09VTJujZt4xnVXls6HpLPLOPq-A0ZCXSTW6EaeaVaEnAaB_cjTLubw1Q4CsS0B2gJyaK-QnOGGNeNtOVHxV_9UccHhvFa6Tq8EMdb6F5HlWj9ACw-w9vtABe3t0BZScsODRYSg_Ds0u1U05GvvFFFKFpX8deos-LXtmtY8EDiFwY7mH1hqrnzeAJH1S4vQ_JG0XkJQ",
  //     "refreshToken": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.WJ1R5uW2089Y70kLahWUMm1sJt2xrmlKwcxaoTM_ZiZSYuh3zmZCM5zY0QNDMjpnytUtltkV1JezLo9aP8E_iEyqcKpfliGmp_QJaUFWTO8g0hbnXjNqVluC8QvNlQQ-bFxKrQPBt_nDfccfC18_RiUOSJvc7J02ifglQsTrbEyVQvs5oFap8rcalXoTjmm9Qbtf7T7l2k7AoEOacdKCu2C3Vr8W0IuWOn76TFqAe3MsFDJ1YK1Q6DFoL8QCllhZMzBzDzIYAumoAQqD1RcO8-SnFAUXDegwtK-Go2ydDcEO9jPmZxg3x3CvrMGXh-3KSr32J8Tp3OlFPPmIDiQmwg.vZoMFTVm6QTE5NX_.nH_8IBvWgOpS0mIo3bG2Lkuog6R8WFmaVRGDsmrOgqwaOstheIGyQD15HDLGvgePTSl0jcNF9U2ppg74crcEVjW-ag2xqRdjw3WVt85-ue9A5inCAHiuubkWfKe0upOCUR04UywQEWWMFGAfoGz8KfQb8zkBasRnmLzWYEQRFz5vqa55ALWUB87UUy965mW3oWJRZRMMDe8_gfZrXdzLKuJKV9uDA1vxJ4gf_whO61lqKZLfFlwCoiPfBBAF5nWSMINAQgAxFAnQfudNh28xFlYYg4wfItyBHj5x7VVRO9EWi_mWBCrurIQrwHr7U-y7J1Biqel53fF9r-QTz-btn2IXanYAq4A3uHAgIRFI478H5ne-RUtGPe2johO58gCg5FH4hQ7ij6R5tEtJpkS0ukCm0QWlL8ockLv0ibHyF1uNKFfqd5yCOJoHI-PsQ9ZeTgQraz4gnBqrE2JmArbhio-Es7QDAP8t7hVRF6bpx-EANZnLz1MRdlgc7SHMlidVozWxgkzA5e6Qo2ObcrpaZB2b4mCQd1ls-QVkHtmug2EnqJKXDweg9C-cdVjvEVdgtzcMB-eatkaSZOjZyec800q89jjS9Si9ZcgkU39vJedronH6kRsIdRLkSoLUMVdRePO23AMdXpXn6jwrEj4r6RhDP2LZjj6DNwLFi4Wk_ZFAN9WxjZoCg5DMbQ2INGDlJgIUYG2ddHSWvahbZroj8TqjF6jDUsluGc5iWhHKDbPMCTFEe3DeKYEe_mds_QK62winRepbXlRtngT7rRYGqOkC-eRKUveQw0keAQsal2GvWgv8lUrji0dFbLexrF59k0N5nuUgPhjQNwVyh6e_qqGJH9ieT-hBKdgj_RifKzsf847SWNSDHMwpF_SqQS8HnELOYvs7SE40bSb8YknDToPlO-kbfaDZ4GzuP4fSgXJP_ngPYG3K6UEAlgu_G6S2mtVsWNBnM4Hkz4TsuHjnoUltu3AZRb7E63TsMwbc4ZIyrqUa7UGdc22WXIqO5f4Nkv8eCgSSZrKFr4s8pUR51tGWLRwO1DQ5JcKIZCNdXCTvxSFEvtlhu8yCIM8wpyGG05hYWK1RRMHPQsG4C1pNy264JkT0zeMEsLlxVROnO0KN75_NVucklAKTDyvke3dsrPixfg7OJzjyHagM5ZTMYsvO9F_W-w3JgvDOLVSZvIS-g16O3c4VjYn8CpTy-jAQZAFKvRmbzhNCZIOg7kxwvbnyQbYw02amC_UilsXsj2JbisICYN_flNG8f8ZqQLiG3xff7k7jaHm_-d31XngE4f_fPhva6mkl8GyamlS-WeConh7OBLWOyDQRSV6EwwG__zZNw-NVSYwKqg63rwNT-JLFdxcvIQG1S6egtWyGG2Z993J5KDRpaF9qGcn2raQ.sjkcMyVrlSgiwA1B0s-1pw"
  // }'

  // useEffect(() => {
  //   if (errorMsg !== "") {
  //     setTimeout(() => {
  //       setErrorMsg("");
  //     }, 2000);
  //   }
  // }, [errorMsg]);

  const handleLogin = () => {
    setEmail(username);
    if (isEmail(username) && password !== "") {
      // message.loading("Authenticating...");
      setLoading(true);

      Axios.post(`https://gxauth.apimachine.com/gx/user/auth/login`, {
        email: username,
        password: password,
      })
        .then(({ data }) => {
          localStorage.setItem("userData", JSON.stringify(data.user));
          if (data.status) {
            // console.log("inside 1st api true");
            Axios.get(
              `https://connectionsapi.globalxchange.io/user_upline_appcode?email=${username}&upline_appcode=indianinvestor`
            ).then((res) => {
              if (res.data.status) {
                // console.log("inside 2nd api true");
                setLoading(false);
                message.destroy();
                message.success("Login Successfull");
                localStorage.setItem("access_token", data.accessToken);
                localStorage.setItem("user_account", username);
                localStorage.setItem("loginObj", JSON.stringify(data));
                localStorage.setItem("token", data.idToken);

                navigate("/");
                window.location.reload();
              } else {
                // localStorage.setItem("userId", data?.user?.username);
                setInsiderReg(true);
                setStep(8);
                navigate("/getstarted", {
                  state: { loginEmail: username, loginPassword: password },
                });
              }
            });
          } else {
            setErrorMsg(data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
          console.log(err);
          message.destroy();
          setErrorMsg(err.message);
          message.error(err.message, 2);
          setLoading(false);
          if (err.message === "User is not confirmed.") {
            setVisibleVerify(true);
          }
        });

      // let authenticationData = {
      //   Username: username,
      //   Password: password,
      // };

      // let authdet = new AuthenticationDetails(authenticationData);

      // let userData = {
      //   Username: username,
      //   Pool: userPool,
      // };

      // let user = new CognitoUser(userData);

      // user.authenticateUser(authdet, {
      //   onSuccess: function (result) {
      //     Axios.get(
      //       `https://connectionsapi.globalxchange.io/user_upline_appcode?email=${username}&upline_appcode=indianinvestor`
      //     ).then(({ data }) => {
      //       if (data.status) {
      //         setLoading(false);
      //         console.log(result, "jhvdjqddqwdqd");
      //         message.destroy();
      //         message.success("Login Successfull");
      //         let accessToken = result.accessToken.jwtToken;
      //         localStorage.setItem("access_token", accessToken);
      //         localStorage.setItem("user_account", username);
      //         localStorage.setItem("loginObj", JSON.stringify(result));

      //         //   setTimeout(() => {
      //         //     value.setLogin(true);
      //         //   }, 1000);
      //         var idtoken = result.getIdToken().getJwtToken();
      //         // setAccessToken(accessToken);
      //         // setIdToken(idtoken);
      //         //idtoken.client_id = client_id;
      //         localStorage.setItem("token", idtoken);
      //         navigate("/");
      //         window.location.reload();
      //       } else {
      //         setInsiderReg(true);
      //         setStep(5);
      //         navigate("/getstarted");
      //       }
      //     });
      //   },

      //   onFailure: function (err) {
      //     localStorage.removeItem("token");
      //     console.log(err);
      //     //   setTimeout(() => {
      //     //     value.setLogin(false);
      //     //   }, 2000);
      //     message.destroy();
      //     message.error(err.message, 2);
      //     if (err.message === "User is not confirmed.") {
      //       setVisibleVerify(true);
      //     }
      //   },
      // });
    } else {
      message.destroy();
      message.error("Please enter Email & password");
    }
  };

  const handleSignup = () => {
    if (
      rUsername !== "" &&
      rEmail !== "" &&
      rPassword !== "" &&
      rPassword1 !== ""
    ) {
      if (isEmail(rEmail)) {
        if (rPassword === rPassword1) {
          message.loading("Signing you up...");
          Axios.post(`https://gxauth.apimachine.com/gx/user/signup`, {
            username: rUsername,
            email: rEmail,
            password: rPassword,
            ref_affiliate: "1",
            account_type: "Personal",
            signedup_app: "Brain",
          }).then((res) => {
            if (res.data.status === true) {
              // message.destroy();
              // message.success("Successfully Registered, Verify to continue.");
              setVisibleVerify(true);
            } else {
              message.destroy();
              message.error(res.data.message);
            }
          });
        }
      } else {
        message.error("invalid email");
      }
    } else {
      message.error("fill all fields");
    }
  };

  const handleVerify = () => {
    if (verificationCode !== "" && isEmail(rEmail)) {
      Axios.post(`https://gxauth.apimachine.com/gx/user/confirm`, {
        email: rEmail,
        code: verificationCode,
      }).then((res) => {
        if (res.data.status) {
          setVisibleVerify(false);
          message.destroy();
          message.success(
            "Your Account gas been verified. Please login to continue."
          );
        }
      });
    }
  };

  return (
    <>
      <Row>
        <Col
          span={16}
          // className="side-bg"
          style={{
            height: "100vh",
            // background: "#009EFC",
            // background: "line#84E188ar-gradient(270deg, #4CAF50 0%, #84E188 100%)",
            background: "#84E188",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../img/insiderIcon.svg")}
            alt=""
            // width="0%"
            style={{ opacity: "1" }}
          />
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
            padding: "0px 50px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100%",
            }}
          >
            <img
              src={require("../img/insiderLogo.svg")}
              alt=""
              style={{ width: "100%" }}
            />

            <form
              onSubmit={handleLogin}
              style={{ width: "100%", marginTop: "50px" }}
            >
              <Input
                style={{ padding: "0px 30px" }}
                className="login-input"
                placeholder="Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <br /> <br />
              <Input
                style={{ padding: "0px 30px" }}
                className="login-input"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            </form>
            {!errorMsg === "" ? (
              <p
                style={{ color: "red", fontWeight: "500", paddingTop: "20px" }}
              >
                Invalid Credentials
              </p>
            ) : (
              ""
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "30px",
            }}
          >
            <Button
              loading={loading ? true : false}
              // type="submit"
              type="primary"
              className="login-button"
              onClick={handleLogin}
              style={{
                background: "#84E188",
                border: "none",
              }}
            >
              Login
            </Button>
            <br />
            <Link to="/getstarted" className="signup-button">
              <Button
                className="signup-button"
                // loading={loading ? true : false}
                // type="submit"
                type="ghost"
              >
                Get Started
              </Button>
            </Link>
            <br />
            <br />
            <Link to="/getstarted/preregistered">
              <div className="already-registered">
                Click Here If You Were Pre-Registered
              </div>
            </Link>
          </div>

          {/* <div
            style={{
              backgroundColor: "#005166",
              display: "flex",
              padding: "25px",
            }}
          >
           
            <Input
              valur={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              placeholder="E M A I L"
              className="underline-input"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Input
              valur={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="P A S S W O R D"
              className="underline-input"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            
            <Icon
              type="login"
              style={{
                cursor: "pointer",
                fontSize: "25px",
                fontWeight: "bold",
                color: "white",
              }}
              onClick={handleLogin}
            />
          </div>
          <div style={{ padding: "35px" }}>
            <h1
              style={{
                color: "#306AAE",
                fontWeight: "bold",
                letterSpacing: "6px",
                paddingLeft: "8px",
              }}
            >
              GET STARTED
            </h1>
            <br />
            <Input
              value={rUsername}
              onChange={(e) => setRusername(e.target.value)}
              type="text"
              placeholder="U S E R N A M E"
              className="underline-input1"
              style={{ marginBottom: "30px" }}
            />

            <Input
              value={rEmail}
              onChange={(e) => setRemail(e.target.value)}
              type="text"
              placeholder="E M A I L"
              className="underline-input1"
              style={{ marginBottom: "30px" }}
            />

            <Input
              value={rPassword}
              onChange={(e) => setRpassword(e.target.value)}
              type="password"
              placeholder="P A S S W O R D"
              className="underline-input1"
              style={{ marginBottom: rPassword === "" ? "10px" : "" }}
            />
            <div style={{ marginBottom: "20px", textAlign: "right" }}>
              {rPassword !== "" ? (
                strongRegex.test(rPassword) ? (
                  <span style={{ color: "green" }}>✔ Strong Password</span>
                ) : (
                  <span style={{ color: "red" }}>
                    ❌ Password is not strong, should contain a Capital Letter,
                    a special charecter &
                  </span>
                )
              ) : (
                ""
              )}
            </div>

            <Input
              value={rPassword1}
              onChange={(e) => setRpassword1(e.target.value)}
              type="password"
              placeholder="V E R I F Y  P A S S W O R D"
              className="underline-input1"
            />
          </div>
          <div
            onClick={handleSignup}
            style={{
              backgroundColor: "#005166",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              cursor: "pointer",
            }}
          >
            <h1
              style={{
                margin: "0px",
                color: "white",
                fontWeight: "bold",
                fontSize: "19px",
              }}
            >
              C O N N E C T
            </h1>
          </div> */}
        </Col>
      </Row>

      {/* Verify with OTP Modal */}

      <Modal
        centered
        closable={false}
        // title="Basic Modal"
        visible={visibleVerify}
        onCancel={(e) => setVisibleVerify(false)}
        footer={null}
        // bodyStyle={{ padding: "0px" }}
      >
        <h2>Signup Verification</h2>
        <p>
          You have received a verification code in your registered Email. Please
          type both Email and the code and click on verify to proceed
        </p>
        <Input
          placeholder="Enter Registered Email"
          style={{ marginBottom: "10px" }}
          type="text"
          value={rEmail}
          onChange={(e) => setRemail(e.target.value)}
        />
        <Input
          placeholder="Enter Verification Code"
          style={{ marginBottom: "10px" }}
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <Button block type="primary" size="large" onClick={handleVerify}>
          V E R I F Y
        </Button>
      </Modal>
    </>
  );
};

export default Login;
